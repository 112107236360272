// @flow
import React from 'react';
import styles from './BillingInfo.css';
import BilledNowPrice from './BilledNowPrice';
import BilledLaterPrice from './BilledLaterPrice';
import classNames from 'classnames';
import { A_MONTH_IN_DAYS } from '../../constants';

type OwnProps = {
  isTrialAvailable: boolean,
  price?: number,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  className?: string,
};

const BillingInfo = ({
  isTrialAvailable,
  relativeDiscount,
  price,
  reducedPriceDuration,
  className,
}: OwnProps) => {
  const BilledLaterInfo = () => {
    if (!reducedPriceDuration || isTrialAvailable) {
      return null;
    }

    const months = Math.floor(reducedPriceDuration / A_MONTH_IN_DAYS);
    return (
      <div
        className={classNames(styles.row, className)}
        data-test="payment-form.billed-later"
        data-test-months={months}
      >
        <BilledLaterPrice price={price} months={months} />
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.row, className)} data-test="payment-form.billed-now-price">
        <BilledNowPrice
          isTrialAvailable={isTrialAvailable}
          relativeDiscount={relativeDiscount}
          price={price}
        />
      </div>
      <BilledLaterInfo />
    </div>
  );
};

export default BillingInfo;
