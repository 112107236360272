// @flow

import React from 'react';
import { Image } from '../util/Image';
import classnames from 'classnames';

import styles from './ArtistAvatar.css';
import { IMAGES_BASE, IMGIX_PARAMS } from '../../constants';

type SharedProps = {
  className: string,
  size: number,
};

type ImageSrcProps = {
  src?: string, // can be idagio service images or contentful images
  isContentfulImage?: boolean,
};

type ProfileIdProps = {
  profileId?: number,
};

type OwnProps = SharedProps & ImageSrcProps & ProfileIdProps;

type Props = OwnProps;

const ArtistAvatar = ({ profileId, src, className, size = 160, isContentfulImage }: Props) => {
  const classNames = classnames(className, styles.avatar);
  const source = profileId ? `${IMAGES_BASE}/artists/${profileId}/main.jpg` : src;

  return (
    <React.Fragment>
      {isContentfulImage ? (
        <img src={source} role="img" className={classNames} />
      ) : (
        <Image
          src={source}
          auto={IMGIX_PARAMS}
          width={size}
          height={size}
          className={classNames}
          alt=""
          noBase
        />
      )}
    </React.Fragment>
  );
};

export default ArtistAvatar;
