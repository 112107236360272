import { defineMessages } from 'react-intl';

export const objectivesMessages = defineMessages({
  onDemandAdFree: {
    id: 'index.plans-overview.on-demand-ad-free',
    defaultMessage: 'On demand and ad-free listening',
  },
  unlimitedCollection: {
    id: 'index.plans-overview.unlimited-collection',
    defaultMessage: 'Unlimited personal collection',
  },
  losslessAccess: {
    id: 'index.plans-overview.lossless',
    defaultMessage: 'Lossless audio quality (FLAC 16bit 44.1kHz)',
  },
  offlineListening: {
    id: 'index.plans-overview.offline-listening',
    defaultMessage: 'Offline listening on mobile devices',
  },
  integration: {
    id: 'index.plans-overview.integration',
    defaultMessage: 'Direct integration with Sonos, Bluesound & many more',
  },
  premiumFeatures: {
    id: 'index.plans-overview.premium-features',
    defaultMessage: 'All Premium+ features',
  },
  concertAccess: {
    id: 'index.plans-overview.idagio-concerts-access',
    defaultMessage: 'Unlimited access to IDAGIO Concerts',
  },
  programsAccess: {
    id: 'index.plans-overview.programs-access',
    defaultMessage: 'High end concerts, operas, dance, and educational programs',
  },
  newsletter: {
    id: 'index.plans-overview.newsletter',
    defaultMessage: 'Exclusive hand-curated newsletter',
  },
  tvOS: {
    id: 'index.plans-overview.tvOS',
    defaultMessage: 'Dedicated tvOS app',
  },
  priceDifference: {
    id: 'index.plans-overview.price-difference',
    defaultMessage: 'Only {priceDiff} more per month with the annual plan',
  },
});
