import { selectUserIsPatron } from '../selectors/user';
import { selectSonosIsConnected } from '../selectors/sonos';
import { selectAudioQuality } from '../selectors/player';

import { pickSonosGroup } from '../actions/sonos';
import { setAudioQuality, loadFeatureFlags } from '../actions/client';

import { thisDevice } from '../reducers/sonos/connection';
import { addNotification, dismissNotification } from '../actions/notifications';
import { UP_SELL_EXTERNAL_DEVICES, UP_SELL_QUALITY } from '../lib/notifications';
import { getUpSellNotification } from '../client/playback-support';
import {
  selectExternalDevicesAllowed,
  selectSetQualityIsAllowed,
  selectShouldUpSellAudioQuality,
  selectShouldUpSellExternalDevices,
  selectFeatureFlag,
} from '../selectors/features';
import { showModal } from '../actions/ui';
import { track as analyticsTrack } from '../actions/analytics';

/**
  When downsell happens mid-session, the feature flags need to be reloaded.
  While this takes care of the ui, it doesn't prevent currently accessed
  features outside of the ui to fall back. This needs to be handled on a
  case by case basis here. Case in point for reactivity.
*/
export async function handleDownSell(store) {
  await store.dispatch(loadFeatureFlags());
  const state = store.getState();

  const sonosIsAllowed = selectFeatureFlag(state, 'streaming_to_all_external_players');
  if (selectSonosIsConnected(state) && !sonosIsAllowed) {
    store.dispatch(pickSonosGroup(thisDevice));
  }

  const allowedQualities = selectFeatureFlag(state, 'audio_quality');
  const currentQuality = selectAudioQuality(state);
  const currentQualityNotAllowed = !allowedQualities.includes(parseInt(currentQuality, 10));
  if (currentQualityNotAllowed) {
    const highestAllowedQuality = [...allowedQualities].sort()[allowedQualities.length - 1];
    store.dispatch(setAudioQuality(highestAllowedQuality));
  }
}

function actionIsAllowed(state, action) {
  const { type } = action;
  if (type === 'SET_AUDIO_QUALITY' && !selectSetQualityIsAllowed(state, action.quality)) {
    return false;
  }

  if (
    type === 'PICK_SONOS_GROUP' &&
    action.group.groupId !== 'this-device' &&
    !selectExternalDevicesAllowed(state)
  ) {
    return false;
  }

  return true;
}

function handleUpSellNotification(action, store) {
  const { dispatch, getState } = store;
  const { type } = action;
  const state = getState();

  if (type === 'OPEN_PLAYER_AUDIO_QUALITY_DIALOG' && selectShouldUpSellAudioQuality(state)) {
    dispatch(
      addNotification(getUpSellNotification(UP_SELL_QUALITY, { trigger: 'audioQuality' }, dispatch))
    );
    dispatch(analyticsTrack('Shown Upsell Message', { trigger: 'audioQuality' }));
  }

  if (type === 'OPEN_PLAYER_EXTERNAL_DEVICE_DIALOG' && selectShouldUpSellExternalDevices(state)) {
    dispatch(
      addNotification(
        getUpSellNotification(UP_SELL_EXTERNAL_DEVICES, { trigger: 'deviceSelector' }, dispatch)
      )
    );
    dispatch(analyticsTrack('Shown Upsell Message', { trigger: 'deviceSelector' }));
  }
}

export default store => next => action => {
  const state = store.getState();
  if (!actionIsAllowed(state, action)) {
    // eslint-disable-next-line no-console
    console.log('ACTION IS NOT ALLOWED', action);
    store.dispatch(showModal('SUBSCRIBE_MODAL', { trigger: 'actionNotAllowed' }));
    store.dispatch(dismissNotification('subscription'));
    // ^ clear notifications about subscriptions
    return;
  }

  // Next middleware(s), go!
  next(action);

  // Handle up-sell notifications
  handleUpSellNotification(action, store);

  // Downgrade
  const userWasPatron = selectUserIsPatron(state);
  const nextState = store.getState();
  const userIsPatron = selectUserIsPatron(nextState);

  // TODO does this also include log outs? Should it be prevented?
  // We have two definitions. One in normalizeUser that writes to isPatron to the user state.
  // That one only checks for the user plan. There's also a selector, selectUserIsPatron which
  // checks the plan, authenticated and me.loaded
  if (userWasPatron && !userIsPatron) {
    handleDownSell(store);
  }
};
