// @flow
import React from 'react';
import Price from '../common/Price';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  price?: number,
  months: number,
};

type Props = OwnProps;

const BilledLaterPrice = ({ months, price }: Props) => {
  return (
    <React.Fragment>
      <FormattedMessage
        id="subscribe-modal.payment-details.billed-later-2"
        defaultMessage="{star}Billed in {months} months:"
        values={{
          months,
          star: '*',
        }}
      />
      <Price price={price} />
    </React.Fragment>
  );
};

export default BilledLaterPrice;
