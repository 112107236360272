// @flow
import React from 'react';
import { getEntityUrl } from '../../lib/entities';
import { Link } from 'react-router';
import stylesSearchResult from './SearchResults.css';
import stylesSearchCollectionItem from './SearchCollectionItem.css';
import { ENTITY_TYPE_LIVESTREAM_EVENT, SEARCH_IMAGE_DIMS } from '../../constants';
import Separated from '../util/Separated';
import IconLabel from '../util/IconLabel';

type OwnProps = {
  title: string,
  slug: string,
  imageUrl: string,
  participants: Array<{ title: string }>,
  onClick: Function,
};

const SearchEventItem = ({ title, slug, imageUrl, participants = [], onClick }: OwnProps) => {
  const path = getEntityUrl(ENTITY_TYPE_LIVESTREAM_EVENT, { slug });
  const participantTitles = participants.map(({ title: participantTitle }) => participantTitle);
  const paddedImageUrl = `${imageUrl}?fm=png&fit=pad`;
  const defaultImageUrl = `${paddedImageUrl}&h=${SEARCH_IMAGE_DIMS.h}&w=${SEARCH_IMAGE_DIMS.w}`;

  return (
    <Link to={path} className={stylesSearchResult.result} onClick={onClick}>
      {imageUrl ? (
        <img
          src={defaultImageUrl}
          srcSet={`
            ${defaultImageUrl} 1x,
            ${paddedImageUrl}&h=${SEARCH_IMAGE_DIMS.h * 2}&w=${SEARCH_IMAGE_DIMS.w * 2} 2x,
            ${paddedImageUrl}&h=${SEARCH_IMAGE_DIMS.h * 3}&w=${SEARCH_IMAGE_DIMS.w * 3} 3x
          `}
          role="img"
          className={stylesSearchResult.thumb}
        />
      ) : (
        <div className={stylesSearchResult.thumb}>
          <IconLabel name="event" className={stylesSearchResult.icon} />
        </div>
      )}
      <div className={stylesSearchCollectionItem.wrapper}>
        <span className={stylesSearchCollectionItem.title}>{title}</span>
        {participantTitles.length > 0 && (
          <span className={stylesSearchCollectionItem.participants}>
            <Separated>{participantTitles}</Separated>
          </span>
        )}
      </div>
    </Link>
  );
};

export default SearchEventItem;
