import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import getSegmentWriteKey from '../../utils/getSegmentWriteKey';
import { ConsentManagerBuilder as Builder } from '@segment/consent-manager';
import { selectSegmentCdnHost, selectTopDomain } from '../../selectors/client';

const ADVERTISING_CATEGORIES = ['Advertising', 'Tag Managers'];

const FUNCTIONAL_CATEGORIES = [
  'CRM',
  'Customer Success',
  'Deep Linking',
  'Helpdesk',
  'Livechat',
  'Performance Monitoring',
  'Personalization',
  'SMS & Push Notifications',
  'Security & Fraud',
];

const mapCustomPreferences = (destinations, preferences) => {
  const destinationPreferences = {};
  const customPreferences = {
    necessary: true,
    advertising: Boolean(preferences.advertising),
    functional: Boolean(preferences.functional),
    marketingAndAnalytics: Boolean(preferences.marketingAndAnalytics),
  };

  Object.keys(destinations).forEach(key => {
    const destination = destinations[key];
    const category = destination.category;
    if (
      ADVERTISING_CATEGORIES.includes(category) &&
      destinationPreferences[destination.id] !== false
    ) {
      destinationPreferences[destination.id] = customPreferences.advertising;
    }

    if (
      FUNCTIONAL_CATEGORIES.includes(category) &&
      destinationPreferences[destination.id] !== false
    ) {
      destinationPreferences[destination.id] = customPreferences.functional;
    }

    // Fallback to marketing
    if (!(destination.id in destinationPreferences)) {
      destinationPreferences[destination.id] = customPreferences.marketingAndAnalytics;
    }
  });

  return { destinationPreferences, customPreferences };
};

class ConsentManagerBuilder extends Component {
  static propTypes = {
    topDomain: PropTypes.string.isRequired,
    segmentCdnHost: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
  };

  state = {
    error: false,
  };

  onAccept = () => {
    this.setState({ error: false });
  };

  onError = error => {
    this.setState({ error });
  };

  render() {
    return (
      <Builder
        writeKey={getSegmentWriteKey()}
        cookieDomain={this.props.topDomain}
        mapCustomPreferences={mapCustomPreferences}
        onError={this.onError}
        cdnHost={this.props.segmentCdnHost}
      >
        {({ preferences, setPreferences, saveConsent }) =>
          this.props.children({
            preferences,
            setPreferences,
            saveConsent,
            onAccept: this.onAccept,
            hasError: this.state.error,
          })
        }
      </Builder>
    );
  }
}

function mapStateToProps(state) {
  return {
    topDomain: selectTopDomain(state),
    segmentCdnHost: selectSegmentCdnHost(state, { noProtocol: true }),
  };
}

export default connect(mapStateToProps)(ConsentManagerBuilder);
