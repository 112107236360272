import React, { PureComponent } from 'react';
import ReactImgix from 'react-imgix';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Image.css';
import { IMAGES_BASE } from '../../constants';

export class Image extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
    noBase: PropTypes.bool,
    loading: PropTypes.string,
    alt: PropTypes.string,
    loadingMode: PropTypes.oneOf(['lazy', 'eager']),
  };

  state = {
    loaded: false,
    error: false,
  };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  onError = () => {
    this.setState({ error: true });
  };

  render() {
    const { loaded, error } = this.state;
    const { noBase, src, className, alt } = this.props;
    const classNames = classnames(styles.fallbackImage, className, {
      [styles.loaded]: loaded,
      [styles.error]: error,
    });

    const sourceWithBase = noBase ? src : IMAGES_BASE + src;

    return (
      <div className={classNames}>
        {!error && (
          <ReactImgix
            {...this.props}
            imgProps={{
              'onLoad': this.onLoad,
              'onError': this.onError,
              'alt': this.props.alt,
              'role': 'img',
              'aria-label': this.props.alt,
              // Disable HTML5 drag'n'drop in case this image is used in a
              // slider
              'draggable': false,
              'loading': this.props.loadingMode,
              // Lazy loaded images need to be displayed as block, because they
              // need the intrinsic dimensions passed as attributes to work
              // (which are applied by the user agent as width/height css
              // attributes, which only work for block elements
              'style': this.props.loadingMode === 'lazy' ? { display: 'block' } : {},
              'width': this.props.width,
              'height': this.props.height,
            }}
            className={styles.img}
            src={sourceWithBase}
            alt={alt}
          />
        )}
      </div>
    );
  }
}
