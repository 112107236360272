// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './PlansOverviewSubscribe.css';

import {
  PLAN_ID_PREMIUM,
  PLAN_ID_PPC_1M,
  PLAN_ID_PPC_12M,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PRICE_EURO,
} from '../../constants';
import PlanDetails from './PlanDetails';
import { objectivesMessages } from '../messages/PlansOverviewMessages';
import PlanOptionSubscribe from './PlanOptionSubscribe';

type OwnProps = {
  setSelectedPlan: Function,
  selectedPlan: string,
  relativeDiscount?: number,
  isTrialAvailable: boolean,
  trialDurationDays: number,
};

type Props = OwnProps;

const PlansOverviewSubscribe = ({
  setSelectedPlan,
  selectedPlan,
  relativeDiscount,
  isTrialAvailable,
  trialDurationDays,
}: Props) => {
  const ppc12m = SUBSCRIPTION_PLANS[PLAN_ID_PPC_12M];
  const ppc1m = SUBSCRIPTION_PLANS[PLAN_ID_PPC_1M];

  return (
    <div className={styles.wrapper}>
      <div className={styles.planOptions}>
        <div>
          <PlanDetails
            title={
              <FormattedMessage id="subscribe-modal.audio-only.title" defaultMessage="Premium+ " />
            }
            objectives={[
              {
                key: 'onDemandAdFree',
                value: <FormattedMessage {...objectivesMessages.onDemandAdFree} />,
              },
              {
                key: 'unlimitedCollection',
                value: <FormattedMessage {...objectivesMessages.unlimitedCollection} />,
              },
              {
                key: 'losslessAccess',
                value: <FormattedMessage {...objectivesMessages.losslessAccess} />,
              },
            ]}
          />
          <PlanOptionSubscribe
            id={PLAN_ID_PREMIUM}
            monthlyPrice={SUBSCRIPTION_PRICE_EURO}
            onSelect={setSelectedPlan}
            selectedPlan={selectedPlan}
            relativeDiscount={relativeDiscount}
            isTrialAvailable={isTrialAvailable}
            trialDurationDays={trialDurationDays}
          />
        </div>
        <div>
          <PlanDetails
            title={
              <FormattedMessage
                id="subscribe-modal.premium-concerts.title"
                defaultMessage="Premium + Concerts"
              />
            }
            objectives={[
              {
                key: 'premiumFeatures',
                value: <FormattedMessage {...objectivesMessages.premiumFeatures} />,
              },
              {
                key: 'concertAccess',
                value: <FormattedMessage {...objectivesMessages.concertAccess} />,
              },
            ]}
          />
          <PlanOptionSubscribe
            id={PLAN_ID_PPC_12M}
            monthlyPrice={ppc12m.monthlyPrice}
            totalPrice={ppc12m.totalPrice}
            onSelect={setSelectedPlan}
            selectedPlan={selectedPlan}
            relativeDiscount={relativeDiscount}
            badgeText="-44%"
            shouldShowPriceRemark
            isTrialAvailable={isTrialAvailable}
            trialDurationDays={trialDurationDays}
          />
          <PlanOptionSubscribe
            id={PLAN_ID_PPC_1M}
            monthlyPrice={ppc1m.monthlyPrice}
            totalPrice={ppc1m.totalPrice}
            onSelect={setSelectedPlan}
            selectedPlan={selectedPlan}
            relativeDiscount={relativeDiscount}
            isTrialAvailable={isTrialAvailable}
            trialDurationDays={trialDurationDays}
          />
        </div>
      </div>
    </div>
  );
};

export default PlansOverviewSubscribe;
