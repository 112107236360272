// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { compose } from 'redux';

import CapacitorRipple from '../capacitor/Ripple';
import * as playableItemPreviewActions from '../../actions/playableItemPreview';

import {
  selectPlayableItemPreviewIsLoadingToPlay,
  selectPlayableItemPreviewIsLoading,
  selectPlayableItemPreviewIsPlaying,
} from '../../selectors/playableItemPreview';

import { Image } from '../util/Image';
import { CircularPlayButton } from './CircularPlayButton';
import Curator from './Curator';

import styles from './PlayableItemPreview.css';

import { getQueueOrigin, getQueueOriginUrl } from '../../lib/queue';
import {
  IMGIX_PARAMS,
  ENTITY_TYPE_PLAYLIST_GROUP,
  PLAYABLE_ITEM_PREVIEW_DIMS,
} from '../../constants';

const messages = defineMessages({
  ariaLink: {
    id: 'aria-labels-playable-item-preview-link',
    defaultMessage: 'Click to get to the detail view',
  },
});

const preventDefault = e => e.preventDefault();

declare var __CAPACITOR__: boolean;

type OwnProps = {
  item: Object,
  beforeTogglePlay?: Function,
  onClick: Function,
  showPlayButton: boolean,
  imageLoadingMode: 'lazy' | 'eager',
  groupId?: string,
};

type MapStateToProps = {
  isPlaying: boolean,
  isLoading: boolean,
};

type DispatchProps = {
  togglePlay: Function,
  loadPlayableItemPreviewUrl: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const PlayableItemPreview = ({
  item,
  isPlaying,
  isLoading,
  beforeTogglePlay,
  togglePlay,
  onClick,
  loadPlayableItemPreviewUrl,
  intl,
  showPlayButton = true,
  imageLoadingMode = 'lazy',
  groupId,
}: Props) => {
  const onClickItem = () => {
    onClick(item, groupId);
  };

  const onMouseOver = () => {
    loadPlayableItemPreviewUrl(item);
  };

  const onTogglePlay = playing => {
    if (beforeTogglePlay) {
      beforeTogglePlay(item, playing, groupId);
    }

    return togglePlay(item, Date.now());
  };

  const { content, imageUrl, title, subtitle } = item;
  const { composerName, curator, type } = content;

  const imageWidth = PLAYABLE_ITEM_PREVIEW_DIMS.w;
  let imageHeight = PLAYABLE_ITEM_PREVIEW_DIMS.h;
  let imageStyle = styles.bg;
  let url;
  const isPlaylistGroup = type === ENTITY_TYPE_PLAYLIST_GROUP;
  const shouldShowPlayButton = !isPlaylistGroup && showPlayButton;

  if (isPlaylistGroup) {
    url = `/discover/playlist-group/${content.slug}`;
    imageHeight = 320;
    imageStyle = styles.playlistGroupBg;
  } else {
    const queueOrigin = getQueueOrigin(type, content);
    url = getQueueOriginUrl(queueOrigin);
  }

  const ariaLink = intl.formatMessage(messages.ariaLink);

  return (
    <div className={styles.component}>
      <div className={styles.cover}>
        <Link
          to={url}
          className={styles.link}
          onClick={onClickItem}
          onMouseOver={shouldShowPlayButton ? onMouseOver : null}
          aria-label={ariaLink}
          // Disable HTML5 drag in the slider
          draggable={false}
          onDragStart={preventDefault}
          data-test="playable-item-preview.link"
        >
          <Image
            noBase
            src={imageUrl}
            width={imageWidth}
            height={imageHeight}
            auto={IMGIX_PARAMS}
            className={imageStyle}
            loadingMode={imageLoadingMode}
            alt=""
          />
        </Link>
        {shouldShowPlayButton && (
          <div className={styles.play}>
            <CircularPlayButton
              onClick={onTogglePlay}
              playing={isPlaying}
              loading={isLoading}
              light
              className={styles.playBtn}
              data-test="playable-item-preview.play-btn"
            />
          </div>
        )}
      </div>
      <Link
        // Disable HTML5 drag in the slider
        draggable={false}
        onDragStart={preventDefault}
        to={url}
        className={styles.info}
        onClick={onClickItem}
      >
        {composerName && <p className={`${styles.composerName} fz--delta`}>{composerName}</p>}
        <span className={styles.title}>{title}</span>
        {subtitle && <span className={styles.performance}>{subtitle}</span>}
        {curator && (
          <div className={styles.curator}>
            <Curator curator={curator} />
          </div>
        )}
        {__CAPACITOR__ && (
          <div className={styles.ripple}>
            <CapacitorRipple />
          </div>
        )}
      </Link>
    </div>
  );
};

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const { item } = ownProps;
  return {
    isPlaying: selectPlayableItemPreviewIsPlaying(state, item),
    // In capacitor we want to check if the queue is loading, instead of if the audio is loading
    isLoading: __CAPACITOR__
      ? selectPlayableItemPreviewIsLoading(state, item)
      : selectPlayableItemPreviewIsLoadingToPlay(state, item),
  };
}

const dispatchProps: DispatchProps = {
  togglePlay: playableItemPreviewActions.togglePlayPlayableItemPreview,
  loadPlayableItemPreviewUrl: playableItemPreviewActions.loadPlayableItemPreviewUrl,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(PlayableItemPreview);
