// @flow
import React from 'react';
import classnames from 'classnames';

import PlanPrice from './PlanPrice';
import styles from './PlanOptionSubscribe.css';
import IconLabel from '../util/IconLabel';
import { FormattedMessage } from 'react-intl';
import { PLAN_ID_PPC_1M, PLAN_ID_PPC_12M, PLAN_ID_PREMIUM } from '../../constants';

type OwnProps = {
  id: string,
  monthlyPrice: number,
  totalPrice?: number,
  selectedPlan: PLAN_ID_PREMIUM | PLAN_ID_PPC_1M | PLAN_ID_PPC_12M,
  onSelect: Function,
  relativeDiscount?: number,
  shouldShowPriceRemark?: boolean,
  badgeText?: string,
  isTrialAvailable: boolean,
  trialDurationDays: number,
};

type Props = OwnProps;

const PlanOptionSubscribe = ({
  monthlyPrice,
  totalPrice,
  selectedPlan,
  onSelect,
  id,
  relativeDiscount,
  shouldShowPriceRemark,
  badgeText,
  isTrialAvailable,
  trialDurationDays,
}: Props) => {
  const isSelected = selectedPlan === id;
  const containerClassname = classnames(styles.container, {
    [styles.isSelected]: isSelected,
  });

  return (
    <div className={containerClassname}>
      <button
        onClick={() => onSelect(id)}
        className="dummy-btn"
        data-test="plan-option.subscribe.btn"
      />
      {badgeText && <span className={styles.badge}>{badgeText}</span>}
      {isSelected && <IconLabel className={styles.iconCheck} name="check" svgSize="36px" />}
      {isTrialAvailable && (
        <div
          data-test="plan-option.trial-days"
          data-test-duration={trialDurationDays}
          className={styles.trialDays}
        >
          <FormattedMessage
            id="subscribe-modal.plans-options.trial-days"
            defaultMessage="{trialDurationDays}-day free trial, then"
            values={{ trialDurationDays }}
          />
        </div>
      )}
      <PlanPrice
        monthlyPrice={monthlyPrice}
        totalPrice={totalPrice}
        relativeDiscount={relativeDiscount}
        shouldShowPriceRemark={shouldShowPriceRemark}
      />
    </div>
  );
};

export default PlanOptionSubscribe;
