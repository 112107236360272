// @flow
// $FlowFixMe
import React, { useState } from 'react';

import { SUBSCRIPTION_TRIAL_DURATION_DAYS } from '../../constants';

import StripePaymentForm from './StripePaymentForm';

import stylesPaymentStep from './PaymentStep.css';

type OwnProps = {
  isInModal: boolean,
  selectedPlan?: string,
  isTrialAvailable: boolean,
  trialDurationDays?: number,
  price?: number,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  onSuccess?: Function,
  defaultPaymentType?: string,
  userIsAllowedOptOutTrial: boolean,
};

type Props = OwnProps;

const PaymentStep = ({
  isInModal,
  selectedPlan,
  isTrialAvailable,
  trialDurationDays = SUBSCRIPTION_TRIAL_DURATION_DAYS,
  price,
  relativeDiscount,
  reducedPriceDuration,
  onSuccess,
  defaultPaymentType,
  userIsAllowedOptOutTrial,
}: Props) => {
  const [activePaymentType, setActivePaymentType] = useState(defaultPaymentType);

  return (
    <div className={stylesPaymentStep.container} data-test="conversion-form.payment-step-view">
      <StripePaymentForm
        isInModal={isInModal}
        onSuccess={onSuccess}
        isTrialAvailable={isTrialAvailable}
        trialDuration={trialDurationDays}
        price={price}
        relativeDiscount={relativeDiscount}
        reducedPriceDuration={reducedPriceDuration}
        onPaymentTypeChange={setActivePaymentType}
        selectedPlan={selectedPlan}
        initialPaymentType={activePaymentType}
        userIsAllowedOptOutTrial={userIsAllowedOptOutTrial}
      />
    </div>
  );
};

export default PaymentStep;
