// @flow
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ConversionStepHeader.css';

const UpgradeHeader = () => {
  return (
    <React.Fragment>
      <h1
        className={classNames(styles.title, styles.titleCenter)}
        data-test="conversion-step-header.title.upgrade"
      >
        <FormattedMessage
          id="subscribe-modal.payment-step.title-upgrade"
          defaultMessage="Watch without limits"
        />
      </h1>
      <p
        className={classNames(styles.subtitle, styles.subtitleCenter)}
        data-test="conversion-step-header.subtitle.upgrade"
      >
        <FormattedMessage
          id="subscribe-modal.payment-step.subtitle-upgrade"
          defaultMessage="Get full access to IDAGIO with Premium+ Concerts."
        />
      </p>
    </React.Fragment>
  );
};

export default UpgradeHeader;
