// @flow

import React from 'react';
import styles from './TooltipHandle.css';

type TooltipHandleProps = {
  tooltipId: string,
};

const TooltipHandle = (props: TooltipHandleProps) => (
  <a className={styles.main} data-tip data-for={props.tooltipId}>
    <span className={styles.inner}>?</span>
  </a>
);

export default TooltipHandle;
