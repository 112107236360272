// @flow
import React from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { selectCourseResource, selectOpenModalTypeIs } from '../../selectors/modals';
import * as uiActions from '../../actions/ui';
import styles from './CourseResourcePreviewModal.css';
import DownloadButton from '../common/DownloadButton';

type OwnProps = {};

type MapStateToProps = {
  isOpen: boolean,
  resource: {
    thumbnail: string,
    assetUrl: string,
    title: string,
    description?: string,
  },
};

type DispatchProps = {
  hideModal: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const CourseResourcePreviewModal = ({ isOpen, hideModal, resource }: Props) => {
  const { thumbnail, assetUrl, title, description } = resource;

  return (
    <Modal
      isOpen={isOpen}
      onClose={hideModal}
      onRequestClose={hideModal}
      contentLabel="course-resource-preview-modal"
    >
      <div className={styles.modal}>
        <img src={thumbnail} role="img" className={styles.image} />
        <div>
          <h1 className={styles.title}>{title}</h1>
          {description && <div className={styles.description}>{description}</div>}
          <DownloadButton url={assetUrl} />
        </div>
      </div>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isOpen: selectOpenModalTypeIs(state, 'COURSE_RESOURCE_PREVIEW_MODAL'),
    resource: selectCourseResource(state),
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
};

export default connect(mapStateToProps, dispatchProps)(CourseResourcePreviewModal);
