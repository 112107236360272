import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import { withRouter, routerShape } from 'react-router';
import React, { Component } from 'react';
import classnames from 'classnames';

import { MiscActionsPlugin } from '../../capacitor-connector';
import IconLabel from '../util/IconLabel';
import { selectBaseUrl } from '../../selectors/client';
import styles from './ShareButton.css';
import CapacitorRipple from '../capacitor/Ripple';

const messages = defineMessages({
  ariaButton: {
    id: 'aria-button-share',
    defaultMessage: 'Share',
  },
});

class ShareButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    hideText: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    intl: intlShape,
    router: routerShape,
    baseUrl: PropTypes.string,
    capacitorShareContentType: PropTypes.string,
  };

  static defaultProps = {
    hideText: true,
  };

  render() {
    const { hideText, className, intl, text } = this.props;

    const buttonClassNames = classnames(styles.shareButton, className);

    const ariaButton = intl.formatMessage(messages.ariaButton);

    return (
      <button
        className={buttonClassNames}
        onClick={this.handleClick}
        aria-label={ariaButton}
        title={text}
      >
        <CapacitorRipple version="center" />
        <IconLabel
          name={__CAPACITOR__ ? 'share-android' : 'share'}
          size={hideText ? 'default' : 'small'}
        />
        {!hideText && <span className={styles.text}>{text}</span>}
      </button>
    );
  }

  handleClick = () => {
    if (__CAPACITOR__) {
      const { pathname, search } = this.props.router.location;
      MiscActionsPlugin.androidShouldShareUrl({
        contentType: this.props.capacitorShareContentType,
        url: `${this.props.baseUrl}${pathname}${search}`,
      });
    } else {
      this.props.onClick();
    }
  };
}

function mapStateToProps(state) {
  return {
    baseUrl: selectBaseUrl(state),
  };
}

export default compose(injectIntl, connect(mapStateToProps), withRouter)(ShareButton);
