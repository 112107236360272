// @flow
// $FlowFixMe
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  SUBSCRIPTION_PRICE_EURO,
  SUBSCRIPTION_PLANS,
  PLAN_ID_PREMIUM,
  PLAN_ID_PPC_1M,
  PLAN_ID_PPC_12M,
  DEFAULT_PLAN_ID,
} from '../../constants';

import {
  selectUserIsAuthenticated,
  selectIsStudentStripeCoupon,
  selectUserIsAllowedOptOutTrial,
  selectUserCanSubscribe,
} from '../../selectors/user';
import { selectDefaultPaymentType, selectOpenModalTypeIs } from '../../selectors/modals';
import {
  selectSubscriptionFromId,
  selectSubscriptionRelativeDiscount,
  selectSubscriptionReducedPriceDuration,
  selectSubscriptionTrialDurationDays,
} from '../../selectors/subscriptionOffer';

import * as analyticsActions from '../../actions/analytics';

import AccountStep from './AccountStep';
import ConversionFormInfo from './ConversionFormInfo';

import styles from './ConversionFormVisual.css';
import { selectIsTrialAvailable } from '../../selectors/subscription';
import ConversionStep from './ConversionStep';

type OwnProps = {
  onComplete: Function,
  onClickLoginLink?: Function,
  location: Object,
};

type MapStateToProps = {
  userIsAuthenticated: boolean,
  userCanSubscribe: boolean,
  isInModal: boolean,
  preselectedPlan: PLAN_ID_PREMIUM | PLAN_ID_PPC_12M | PLAN_ID_PPC_1M,
  fromId: string,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  trialDurationDays: number,
  defaultPaymentType?: string,
  isStudentStripeCoupon: boolean,
  isTrialAvailable: boolean,
  userIsAllowedOptOutTrial: boolean,
};

type DispatchProps = {
  analyticsTrack: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const PLANS_OVERVIEW_STEP = 'plans-overview';
const ACCOUNT_STEP = 'account';

const SUPPORTED_PLANS = [PLAN_ID_PREMIUM, PLAN_ID_PPC_1M, PLAN_ID_PPC_12M];

const ConversionForm = ({
  userIsAuthenticated,
  userCanSubscribe,
  preselectedPlan,
  onComplete,
  onClickLoginLink,
  analyticsTrack,
  isInModal,
  isStudentStripeCoupon,
  isTrialAvailable,
  trialDurationDays,
  relativeDiscount,
  reducedPriceDuration,
  defaultPaymentType,
  fromId,
  userIsAllowedOptOutTrial,
  location,
}: Props) => {
  const [selectedPlan, setSelectedPlan] = useState(preselectedPlan);
  const [ppcPlan, setPpcPlan] = useState(
    preselectedPlan === PLAN_ID_PPC_12M || preselectedPlan === PLAN_ID_PPC_1M
      ? preselectedPlan
      : PLAN_ID_PPC_12M
  );
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    if (!userIsAuthenticated) {
      setCurrentStep(ACCOUNT_STEP);
    } else if (userCanSubscribe) {
      setCurrentStep(PLANS_OVERVIEW_STEP);
    } else {
      onComplete();
    }
  }, [userIsAuthenticated, userCanSubscribe, onComplete]);

  if (!currentStep) {
    return null;
  }

  const onPaymentSuccess = (plan, planId, currency) => {
    if (SUPPORTED_PLANS.includes(plan)) {
      const value = SUBSCRIPTION_PLANS[plan]
        ? SUBSCRIPTION_PLANS[plan].totalPrice
        : SUBSCRIPTION_PRICE_EURO;
      analyticsTrack('Upgraded To Premium', {
        value: value,
        currency: currency,
        subscriptionPrice: value,
        productId: planId,
      });
      onComplete();
    }
  };

  const AccountStepView = () => {
    return (
      <div className={styles.grid} data-test="conversion-form.account-step-view">
        <ConversionFormInfo />
        <AccountStep expanded fromId={fromId} onClickLoginLink={onClickLoginLink} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {currentStep === ACCOUNT_STEP ? (
        <AccountStepView />
      ) : (
        <ConversionStep
          selectedPlan={selectedPlan}
          price={
            SUBSCRIPTION_PLANS[selectedPlan]
              ? SUBSCRIPTION_PLANS[selectedPlan].totalPrice
              : SUBSCRIPTION_PRICE_EURO
          }
          isStudentStripeCoupon={isStudentStripeCoupon}
          isTrialAvailable={isTrialAvailable}
          relativeDiscount={relativeDiscount}
          reducedPriceDuration={reducedPriceDuration}
          setSelectedPlan={setSelectedPlan}
          ppcPlan={ppcPlan}
          setPpcPlan={setPpcPlan}
          isInModal={isInModal}
          onPaymentSuccess={onPaymentSuccess}
          trialDurationDays={trialDurationDays}
          defaultPaymentType={defaultPaymentType}
          userIsAllowedOptOutTrial={userIsAllowedOptOutTrial}
          location={location}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: Object, ownProps: OwnProps & { plan?: string }): MapStateToProps {
  const userIsAuthenticated = selectUserIsAuthenticated(state);

  const isInModal = selectOpenModalTypeIs(state, 'SUBSCRIBE_MODAL');

  const { location } = ownProps;

  let preselectedPlan = ownProps.plan || DEFAULT_PLAN_ID;
  if (location.query && location.query.plan) {
    const { plan } = location.query;
    if (SUPPORTED_PLANS.indexOf(plan) > -1) {
      preselectedPlan = plan;
    }
  }

  return {
    userIsAuthenticated,
    userCanSubscribe: selectUserCanSubscribe(state),
    isInModal,
    preselectedPlan,
    fromId: selectSubscriptionFromId(state),
    relativeDiscount: selectSubscriptionRelativeDiscount(state),
    reducedPriceDuration: selectSubscriptionReducedPriceDuration(state),
    trialDurationDays: selectSubscriptionTrialDurationDays(state),
    defaultPaymentType: selectDefaultPaymentType(state),
    isStudentStripeCoupon: selectIsStudentStripeCoupon(state),
    isTrialAvailable: selectIsTrialAvailable(state),
    userIsAllowedOptOutTrial: selectUserIsAllowedOptOutTrial(state),
  };
}

const dispatchProps: DispatchProps = {
  analyticsTrack: analyticsActions.track,
};

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(ConversionForm);
