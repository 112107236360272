export const CHROME_GLASS = 'glass'; // transparent ui
export const CHROME_LIGHT = 'light'; // default
export const CHROME_CLEAN_LINKS = 'clean-links'; // logo and right menu - right menu desktop
export const CHROME_CLEAN = 'clean'; // logo only - nothing desktop
export const CHROMES_HIDE_SIDEBAR = [CHROME_CLEAN, CHROME_CLEAN_LINKS];

export const CHROMES_HIDE_SEARCH = [CHROME_CLEAN, CHROME_CLEAN_LINKS];
export const CHROMES_HIDE_MENU = [CHROME_CLEAN, CHROME_CLEAN_LINKS];

export const UI_THEME_LIGHT = 'light';
export const UI_THEME_DARK = 'dark';
export const UI_THEME_AUTOMATIC = 'automatic';

export const DEFAULT_THEME = UI_THEME_AUTOMATIC;

export const FINISHED_TRACK = 'FINISHED_TRACK';
export const USER_CLICKED_NEXT = 'USER_CLICKED_NEXT';
export const SONOS_NEXT = 'SONOS_NEXT';

export const QUEUE_TYPE_MOOD = 'QUEUE_TYPE_MOOD';
export const QUEUE_TYPE_TRACK_COLLECTION = 'QUEUE_TYPE_TRACK_COLLECTION';
export const QUEUE_TYPE_FILTERED_RECORDING_LIST = 'QUEUE_TYPE_FILTERED_RECORDING_LIST';
export const QUEUE_TYPE_FILTERED_ALBUM_LIST = 'QUEUE_TYPE_FILTERED_ALBUM_LIST';

export const QUEUE_TYPE_RECORDING = 'QUEUE_TYPE_RECORDING';
export const QUEUE_TYPE_PLAYLIST = 'QUEUE_TYPE_PLAYLIST';
export const QUEUE_TYPE_PERSONAL_PLAYLIST = 'QUEUE_TYPE_PERSONAL_PLAYLIST';
export const QUEUE_TYPE_MIX = 'QUEUE_TYPE_MIX';
export const QUEUE_TYPE_ALBUM = 'QUEUE_TYPE_ALBUM';
export const QUEUE_TYPE_LIVE_COMPARE = 'QUEUE_TYPE_LIVE_COMPARE';
export const QUEUE_TYPE_RADIO = 'QUEUE_TYPE_RADIO';
export const QUEUE_TYPE_RECOMMENDATIONS = 'QUEUE_TYPE_RECOMMENDATIONS';

export const SUBSCRIPTION_PLAN_FREE = 'Free';
export const SUBSCRIPTION_PLAN_PREMIUM = 'Premium';
export const SUBSCRIPTION_PLAN_TRIAL_OPT_IN = 'Premium_trial_opt_in';
export const SUBSCRIPTION_PLAN_TRIAL_OPT_OUT = 'Premium_trial_opt_out';
export const SUBSCRIPTION_PRICE_EURO = 9.99;
export const SUBSCRIPTION_TRIAL_DURATION_DAYS = 14;
export const SUBSCRIPTION_SONOS_TRIAL_DURATION_DAYS = 60;

export const PLAN_ID_PREMIUM = 'premium';
export const PLAN_ID_PPC_1M = 'ppc-1m';
export const PLAN_ID_PPC_12M = 'ppc-12m';

export const DEFAULT_PLAN_ID = PLAN_ID_PREMIUM;

export const SUBSCRIPTION_PLANS = {
  [PLAN_ID_PPC_1M]: {
    months: 1,
    totalPrice: 29.99,
    monthlyPrice: 29.99,
  },
  [PLAN_ID_PPC_12M]: {
    months: 12,
    totalPrice: 199.99,
    monthlyPrice: 16.67,
  },
};

export const LOGIN_INTENT = 'LOGIN_INTENT';
export const SIGNUP_INTENT = 'SIGNUP_INTENT';

export const RECORDING_IS_POPULAR_THRESHOLD = 0.5;

export const DEFAULT_RECORDING_SORT_ORDER = 'popular';
export const DEFAULT_WORK_SORT_ORDER = 'popular';
export const DEFAULT_PLAYLIST_SORT_ORDER = 'chronological';
export const DEFAULT_ALBUM_SORT_ORDER = 'relevance';

export const MIN_SEARCH_QUERY_LENGTH = 3;

export const VALID_RECORDING_FACET_PARAMS = [
  'works',
  'composers',
  'ensembles',
  'conductors',
  'soloists',
  'recordingTypes',
  'instruments',
  'genres',
  'epochs',
  'artists',
  'isExclusive',
  'isCompleteWork',
];

export const VALID_RECORDING_FILTER_PARAMS = [...VALID_RECORDING_FACET_PARAMS, 'sort'];

export const BOOLEAN_FACET_PARAMS_VALUE = {
  isExclusive: {
    default: false,
    options: [true],
  },
  isCompleteWork: {
    default: 'unset',
    options: [true],
  },
  isOriginalWork: {
    default: 'unset',
    options: [true],
  },
};

export const BOOLEAN_FACET_PARAMS = Object.keys(BOOLEAN_FACET_PARAMS_VALUE);

export const NULLABLE_BOOLEAN_FACET_PARAMS = ['isCompleteWork', 'isOriginalWork'];

export const DEFAULT_FACET_FILTER_ORDER = [
  'composers',
  'works',
  'genres',
  'epochs',
  'instruments',
  'ensembles',
  'conductors',
  'soloists',
  'recordingTypes',
  'isExclusive',
  'isOriginalWork',
  'isCompleteWork',
];

export const WORKS_PAGE_FACET_FILTER_ORDER_A = [
  'conductors',
  'ensembles',
  'soloists',
  'instruments',
  'recordingTypes',
  'isExclusive',
  'isCompleteWork',
];

const WORKS_PAGE_FACET_FILTER_ORDER_B = [
  'soloists',
  'ensembles',
  'conductors',
  'instruments',
  'recordingTypes',
  'isExclusive',
  'isCompleteWork',
];

export const WORKS_PAGE_FACET_FILTER_ORDERS = {
  2524: WORKS_PAGE_FACET_FILTER_ORDER_A, // orchestral
  790: WORKS_PAGE_FACET_FILTER_ORDER_A, // opera
  2536: WORKS_PAGE_FACET_FILTER_ORDER_A, // sacred vocal
  2539: WORKS_PAGE_FACET_FILTER_ORDER_A, // secular vocal
  2527: WORKS_PAGE_FACET_FILTER_ORDER_B, // concertos
  2533: WORKS_PAGE_FACET_FILTER_ORDER_B, // keyboard
  2530: [
    // chamber
    'ensembles',
    'soloists',
    'conductors',
    'instruments',
    'recordingTypes',
    'isExclusive',
    'isCompleteWork',
  ],
};

export const VALID_WORK_FACET_PARAMS = [
  'composers',
  'epochs',
  'genres',
  'isOriginalWork',
  'isCompleteWork',
];

export const VALID_WORK_FILTER_PARAMS = [...VALID_WORK_FACET_PARAMS, 'sort', 'searchTerm'];

export const VALID_PLAYLIST_FILTER_PARAMS = ['artist_id'];

export const VALID_ALBUM_FACET_PARAMS = [
  'artists',
  'composers',
  'conductors',
  'ensembles',
  'soloists',
  'instruments',
];

export const VALID_ALBUM_FILTER_PARAMS = [...VALID_ALBUM_FACET_PARAMS, 'sort'];

export const VALID_FACETS_PARAMS = [
  ...VALID_WORK_FACET_PARAMS,
  ...VALID_RECORDING_FACET_PARAMS,
  ...VALID_ALBUM_FACET_PARAMS,
];

export const SEARCH_RESULT_SECTION_FILTER_MAP = {
  events: 'events',
  artists: 'artists',
  playlists: 'playlists',
  albums: 'albums',
  compositions: 'compositionsAndRecordings',
  recordings: 'compositionsAndRecordings',
};

export const SEARCH_RESULT_FILTER_ENTRY_KINDS = {
  artists: ['person', 'ensemble'],
  albums: ['album'],
  playlists: ['playlist'],
  events: ['event'],
  compositionsAndRecordings: ['work', 'piece', 'recording'],
};

export const DEFAULT_LOCALE = 'en-GB';
export const DEFAULT_DE_LOCALE = 'de-DE';
export const DEFAULT_FR_LOCALE = 'fr-FR';
export const DEFAULT_ES_LOCALE = 'es-ES';
export const DEFAULT_KO_LOCALE = 'ko-KR';
export const DE_LANG_COUNTRY_CODES = ['DE', 'AT', 'CH', 'LU', 'LI'];
export const EN_LANG_COUNTRY_CODES = ['GB', 'US', 'CA'];
export const FR_LANG_COUNTRY_CODES = ['FR', 'BE'];
export const ES_LANG_COUNTRY_CODES = [
  'ES',
  'MX',
  'CO',
  'AR',
  'PE',
  'VE',
  'CL',
  'EC',
  'GT',
  'CU',
  'BO',
  'DO',
  'HN',
  'PY',
  'SV',
  'NI',
  'CR',
  'PA',
  'UY',
  'GQ',
];

export const LANGUAGE_NAMES = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  ko: '한국어',
};

export const LANGUAGE_SUPPORT_LINKS = {
  en: 'https://support.idagio.com',
  de: 'https://support.idagio.com/de',
  fr: 'https://support.idagio.com/fr',
  es: 'https://support.idagio.com/es',
  ko: 'https://support.idagio.com',
};

export const LANGUAGE_SUPPORT_NO_DEVICES_LINKS = {
  en: 'https://support.idagio.com/articles/388939-connecting-idagio-to-your-sound-system',
  de: 'https://support.idagio.com/de/articles/388939-connecting-idagio-to-your-sound-system',
  es: 'https://support.idagio.com/es/articles/388939-connecting-idagio-to-your-sound-system',
};

export const AUDIO_QUALITY_LOSSLESS = 90;
export const AUDIO_QUALITY_HIGH = 70;
export const AUDIO_QUALITY_LOW = 50;
export const AUDIO_QUALITY_PREVIEW = 10;

export const DEFAULT_AUTOPLAY = true;

export const SEARCH_CHANGE_DEBOUNCE_INTERVAL = 200;

export const MAX_RECENT_SEARCH_COUNT = 10;

export const ITUNES_APP_LINK = 'https://itunes.apple.com/app/idagio/id1014917700?mt=8';
export const ITUNES_ACCOUNT_MANAGEMENT_LINK = 'https://apple.co/2Th4vqI';
export const ITUNES_ACCOUNT_SUPPORT_LINKS = {
  'en-GB': 'https://support.apple.com/en-us/HT202039',
  'de-DE': 'https://support.apple.com/de-de/HT202039',
  'fr-FR': 'https://support.apple.com/fr-fr/HT202039',
  'es-ES': 'https://support.apple.com/es-es/HT202039',
  'ko-KR': 'https://support.apple.com/kr-kr/HT202039',
};
export const ANDROID_ACCOUNT_MANAGEMENT_LINK =
  'https://play.google.com/store/account/subscriptions';
export const ANDROID_ACCOUNT_SUPPORT_LINK = 'https://support.google.com/googleplay/answer/7018481';

export const PLAY_STORE_APP_LINK = 'https://play.google.com/store/apps/details?id=com.idagio.app';

export const ENTITY_TYPE_TRACK = 'track';
export const ENTITY_TYPE_PLAYLIST = 'playlist';
export const ENTITY_TYPE_PLAYLISTS = 'playlists';
export const ENTITY_TYPE_PLAYLIST_GROUP = 'playlist-group';
export const ENTITY_TYPE_MIX = 'mix';
export const ENTITY_TYPE_MOOD = 'mood';
export const ENTITY_TYPE_ALBUM = 'album';
export const ENTITY_TYPE_ALBUMS = 'albums';
export const ENTITY_TYPE_WORK = 'work';
export const ENTITY_TYPE_PERSONAL_PLAYLIST = 'personalPlaylist';
export const ENTITY_TYPE_RECORDING = 'recording';
export const ENTITY_TYPE_ARTIST = 'artist';
export const ENTITY_TYPE_RECORDINGS = 'recordings';
export const ENTITY_TYPE_LIVESTREAM_EVENT = 'livestreamEvent';
export const ENTITY_TYPE_LIVE_COMPARE = 'live-compare';

// this magic ID comes from Contentful: https://app.contentful.com/spaces/5m0ydyy0x2bv/entries/6EmcSzB3f5RwPxdsjPybgi
export const DISCOVER_GROUP_ID_FEATURED_EVENTS = '6EmcSzB3f5RwPxdsjPybgi';

export const DISCOVER_GROUP_TYPE_BANNER = 'banner-group';
export const DISCOVER_GROUP_TYPE_PLAYLIST = 'playlist-group';
export const DISCOVER_GROUP_TYPE_MIX = 'mix-group';
export const DISCOVER_GROUP_TYPE_ALBUM = 'album-group';
export const DISCOVER_GROUP_TYPE_CURATED_ALBUM = 'curated-album-group';
export const DISCOVER_GROUP_TYPE_VIDEO = 'video-group';
export const DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP = 'gch-events';
export const DISCOVER_GROUP_TYPE_PLAYLIST_PORTAL = 'playlist-portal';
export const DISCOVER_GROUP_TYPE_LIVE_COMPARE = 'live-compare';

export const DISCOVER_PAGE_TYPE_HOME = 'home';
export const DISCOVER_PAGE_TYPE_EVENTS_OVERVIEW = 'events-overview';

export const LIVESTREAM_EVENT_DEFAULT_FILTER = 'allAvailable';

export const SUPPORTED_DISCOVER_GROUP_TYPES = [
  DISCOVER_GROUP_TYPE_BANNER,
  DISCOVER_GROUP_TYPE_PLAYLIST,
  DISCOVER_GROUP_TYPE_MIX,
  DISCOVER_GROUP_TYPE_ALBUM,
  DISCOVER_GROUP_TYPE_CURATED_ALBUM,
  DISCOVER_GROUP_TYPE_VIDEO,
  DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP,
  DISCOVER_GROUP_TYPE_PLAYLIST_PORTAL,
  DISCOVER_GROUP_TYPE_LIVE_COMPARE,
];

export const HIDDEN_DISCOVER_GROUP_TYPES_IN_CAPACITOR = [DISCOVER_GROUP_TYPE_LIVE_COMPARE];

export const LIVESTREAM_TYPE_STORY = 'story';
export const LIVESTREAM_TYPE_CONCERT = 'concert';
export const LIVESTREAM_TYPE_COURSE = 'course';

export const SUPPORTED_DISCOVER_GROUP_ITEM_TYPES = [
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_MIX,
  ENTITY_TYPE_ALBUM,
  LIVESTREAM_TYPE_CONCERT,
  LIVESTREAM_TYPE_COURSE,
  LIVESTREAM_TYPE_STORY,
  ENTITY_TYPE_PLAYLIST_GROUP,
  ENTITY_TYPE_LIVE_COMPARE,
];

export const COURSE_RESOURCE_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  DOCUMENT: 'document',
};

export const CONTENT_TYPE = {
  PDF: 'PDF',
  AUDIO: 'Audio',
  IMAGE: 'Image',
  VIDEO: 'Video',
  PRESENTATION: 'Presentation',
};

export const DEFAULT_SLIDER_TRANSITION = {
  duration: 0.5,
  ease: 'easeOut',
};

export const CLIENT_TYPE_WEB = 'web-test';
export const CLIENT_TYPE_WEB_DEFAULT = 'web-3';
export const CLIENT_TYPE_WEB_FIREFOX = 'web-4';
export const CLIENT_TYPE_HLS_DEFAULT = 'web-hls-3';
export const CLIENT_TYPE_HLS_FIREFOX = 'web-hls-4';

export const TRACK_URL_EXPIRY_MILLISECONDS = 86400000;

export const TRACK_COLLECTION_ORIGIN_ID = 'TRACK_COLLECTION';

export const ANALYTICS_DELAYED_COMPLETED_SIGNUP = 'Completed Signup Delayed';

export const ANALYTICS_FIRST_WEB_VISIT = 'Visited For The First Time';

export const TRACKING_TOKEN_HEADER_NAME = 'x-tracking-token';
export const TRACKING_TOKEN_COOKIE_NAME = '_idagio_tracking_token';

export const APP_CHROME_PORTAL_ID = 'app-chrome-portal';
export const APP_SCROLLER_CLASS = 'content';

export const FROM_ID_CAMPAIGNS = {
  default: {
    fromId: 'lp-idagio-subscribe-modal-1',
  },
  lpPlans: {
    fromId: 'lpPlans',
  },
  voucherRedemption: {
    fromId: 'lp-idagio-redeem-voucher',
  },
  optInTrial30days: {
    fromId: 'subscribe-modal-opt-in-30',
  },
  joinDefault: {
    fromId: 'lp-idagio-signup-1',
  },
  previewsModal: {
    fromId: 'previews-modal-1',
  },
  reducedPriceFunnel: {
    fromId: '99webfunnel',
    creditId: '99webfunnel',
    tag: '99webfunnel',
    trackedPromotion: '99webfunnel',
  },
  muvac: {
    // do not add name: 'Muvac', it will conflict with a contentful campaign
    // and break the ui
    fromId: 'muvac',
    cookieName: '_idagio_from_id__muvac-01-2019',
  },
  discount99: {
    url: '/point-99',
    fromId: 'point-99-08-2018',
  },
  kurier: {
    url: '/kurier',
    cookieName: '_idagio_from_id__kurier-05-2018',
    fromId: 'kurier-05-2018',
    redirectUrl: '/',
  },
  sonos: {
    fromId: 'lp-sonos',
  },
  pianonews: {
    name: 'PianoNews',
    fromId: 'piano-news-08-18',
    trialDuration: '60',
  },
  verbier: {
    name: 'Verbier',
    fromId: 'verbier-08-18',
    trialDuration: '90',
  },
  diezeit: {
    name: 'DieZeit',
    fromId: 'die-zeit-06-18',
    trialDuration: '90',
  },
  ref1: {
    name: 'ref1',
    fromId: 'ref1',
    trialDuration: '0',
  },
  diezeitbundle: {
    name: 'die-zeit-bundle',
    fromId: 'die-zeit-bundle',
    trialDuration: '60',
  },
  bf19: {
    name: 'black-friday-2019',
    fromId: 'black-friday-2019',
    trialDuration: '60',
  },
  referAFriendDec19: {
    name: 'refer-a-friend-dec-19',
    fromId: 'refer-a-friend-dec-19',
    trialDuration: '30',
  },
  referralWithReward: {
    fromId: 'rrr',
  },
  referralNoReward: {
    fromId: 'rrn',
  },
  tv: {
    fromId: 'tv-authentication-flow',
  },
};

export const PIANO_INSTRUMENT_ID = 3256;

// FACEBOOK_CLIENT_ID is also present in /src/electron/index
export const FACEBOOK_CLIENT_ID = '642978282525451';

export const MAX_PERSONAL_PLAYLISTS_TRACKS = 500;

export const IDAGIO_AUTHORISED_COOKIE = '_idagio_authorised';
export const IDAGIO_STUDENT_COOKIE = '_idagio-student';

export const VIDEO_TYPE_RECORDING = 'recording';

export const VIDEO_TYPE_ALBUM = 'album';

/*
  These credits do not contain any effects in  the backend and need to be hard coded.
  They also display a different message to the user wanting to redeem a credit as these
  reduce the price for a certain amount of time rather than giving a free period.
*/
export const CREDIT_STRIPE_COUPONS = {
  reducedPriceFunnel: {
    type: 'StripeCoupon',
    id: '99webfunnel',
    relativeDiscount: 0.9009,
    duration: 90,
  },
  retargetingApril19: {
    type: 'StripeCoupon',
    id: 'retargeting-04-19',
    relativeDiscount: 0.5,
    duration: 60,
  },
  studentDiscounts: {
    type: 'StripeCoupon',
    id: 'sheer12mo50pct',
    relativeDiscount: 0.5,
    duration: 360,
  },
};

export const A_MONTH_IN_DAYS = 30;

export const FACET_FILTER_TYPES = {
  ALBUM: 'albums',
  RECORDING: 'recordings',
  WORK: 'works',
  PLAYLIST: 'playlists',
};

export const DEFAULT_FEATURE_FLAGS = {
  full_catalogue: false,
  streaming_to_all_external_players: false,
  audio_quality: [AUDIO_QUALITY_PREVIEW],
  interval_between_intermission: 900,
  collections: {
    access_limit: 6,
  },
  user_playlists: false,
  gch: {
    allow_concert_playback: false,
  },
};

export const IMGIX_PARAMS = ['format', 'compress'];
export const PLAYABLE_ITEM_PREVIEW_DIMS = { w: 480, h: 480 };
export const LIVESTREAM_EVENT_IMAGE_DIMS = { w: 752, h: 376 };
export const SEARCH_IMAGE_DIMS = { w: 96, h: 96 };

export const API_ERROR_MESSAGE_SOCIAL_EXISTS = 'social_login_already_exists';
export const API_ERROR_MESSAGE_ACCOUNT_EXISTS = 'account.user.email.already_exists';
export const ERROR_MESSAGE_CREATE_ACCOUNT_FAILED = 'account.user.email.invalid';
export const ERROR_MESSAGE_LOGIN_INCORRECT = 'account.error.login.incorrect';
export const ERROR_MESSAGE_COUPON_NOT_AUTH = 'coupon.error.not-auth';
export const ERROR_MESSAGE_PASSWORD_TOO_SHORT = 'idagio.error.accounts.password_too_short';
export const ERROR_MESSAGE_PASSWORD_TOO_LONG = 'idagio.error.accounts.password_too_long';
export const ERROR_MESSAGE_FACEBOOK_LOGIN_NOT_ALLOWED = 'facebook login not allowed';

export const EXCLUSIVE_TAG_ID = '10451543';

export const ERROR_CAPTCHA_TOKEN = 'CLIENT_ERROR';
export const DESKTOP_CAPTCHA_TOKEN = 'DESKTOP_ERROR';

export const VOUCHER_STATUS_APPLIED = 'applied';
export const VOUCHER_STATUS_PENDING = 'pending';
export const VOUCHER_STATUS_INVALID = 'invalid';
export const VOUCHER_STATUS_NOT_ELIGIBLE = 'not-eligible';

export const STUDENT_STATUS_APPLIED = 'applied';
export const STUDENT_STATUS_USED_VERIFICATION_ID = 'used-verification-id';
export const STUDENT_STATUS_INVALID_VERIFICATION_ID = 'invalid-verification-id';
export const STUDENT_STATUS_GENERIC_ERROR = 'generic-error';
export const STUDENT_STATUS_ERROR_NON_STRIPE_SUBSCRIPTION = 'error-non-stripe-subscription';

export const DEFAULT_SIGNUP_SOURCE = 'Webtop';

export const SEPA_PAYMENT = 'SEPA_PAYMENT';
export const CARD_PAYMENT = 'CARD_PAYMENT';
export const VOUCHER_PAYMENT = 'VOUCHER_PAYMENT';

export const IMAGES_BASE = 'https://idagio-images.global.ssl.fastly.net';

export const SHOW_PREVIEWS_MODAL_PLAYBACK_DURATION_MS = 20000; // 20 seconds

export const WEBSITE_DOMAIN = 'https://idagio.com';

export const MEETING_EARLY_ACCESS_PERIOD = 30; // 30 mins

export const ZOOM_LOCALES = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  ko: 'ko-KO',
};

export const ANONYMOUS_ONLY_PATHS = ['login', 'join', 'forgot-password'];

export const CAPTCHA_ACTION_REQUEST_PW_RESET = 'resetpw';
export const CAPTCHA_ACTION_SET_NEW_PW = 'setnewpw';
export const CAPTCHA_ACTION_CHANGE_PW = 'changepw';
export const CAPTCHA_ACTION_SIGN_UP = 'signup';
export const CAPTCHA_ACTION_LOG_IN = 'login';
export const CAPTCHA_ACTION_PAYMENT = 'payment';

import { defineMessages } from 'react-intl';

export const CONSTANT_MESSAGES = defineMessages({
  externalLink: {
    id: 'ui.external-link',
    defaultMessage: '{linkText} (opens in new tab)',
  },
});

export const PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER =
  'PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER';
