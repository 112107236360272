// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Price from '../common/Price';

import styles from './PlanPrice.css';

type OwnProps = {
  monthlyPrice: number,
  relativeDiscount?: number,
};

type Props = OwnProps;

const PlanPriceMonthly = ({ monthlyPrice, relativeDiscount }: Props) => {
  return (
    <div className={styles.monthlyPrice}>
      {relativeDiscount ? (
        <span data-test="plan-option.monthly-price.reduced">
          <FormattedMessage
            id="subscribe-modal.plans-options.monthly-price-reduced-2"
            defaultMessage="{monthlyPrice} {reducedPrice}/month"
            values={{
              monthlyPrice: <Price price={monthlyPrice} className={styles.strikethrough} />,
              reducedPrice: <Price price={monthlyPrice} relativeDiscount={relativeDiscount} />,
            }}
          />
        </span>
      ) : (
        <span data-test="plan-option.monthly-price.default">
          <FormattedMessage
            id="subscribe-modal.plans-options.monthly-price"
            defaultMessage="{monthlyPrice}/month"
            values={{
              monthlyPrice: <Price price={monthlyPrice} />,
            }}
          />
        </span>
      )}
    </div>
  );
};

export default PlanPriceMonthly;
