import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import classnames from 'classnames';
import ConsentManagerSelection from './ConsentManagerSelection';
import ConsentManagerSelectionTable from './ConsentManagerSelectionTable';
import { selectLocale } from '../../selectors/client';
import { onPreferencesSaved } from '@segment/consent-manager';
import styles from './ConsentManagerInner.css';
import { CONSTANT_MESSAGES } from '../../constants';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';

const messages = defineMessages({
  privacyPolicyLink: {
    id: 'gdpr.opt-out-modal.privacy-policy-link',
    defaultMessage: 'privacy policy',
  },
  imprintLink: {
    id: 'gdpr.opt-out-modal.imprint-link',
    defaultMessage: 'imprint',
  },
});

class ConsentManagerInner extends Component {
  static propTypes = {
    hideModal: PropTypes.func,
    preferences: PropTypes.object.isRequired,
    setPreferences: PropTypes.func.isRequired,
    saveConsent: PropTypes.func.isRequired,
    intl: intlShape,
    currentLocale: PropTypes.string.isRequired,
  };

  state = {
    showDetails: false,
  };

  componentDidMount() {
    onPreferencesSaved(() => {
      this.hideModal();
    });
  }

  onAllowAllCookies = () => {
    this.props.setPreferences({
      marketingAndAnalytics: true,
      functional: true,
      advertising: true,
    });
    this.props.saveConsent();
  };

  onAllowSelection = () => {
    this.props.setPreferences(this.getNormalizedPreferences());
    this.props.saveConsent();
  };

  onSelectionChange = selection => {
    this.props.setPreferences(selection);
  };

  onToggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  getNormalizedPreferences = () => {
    const { preferences } = this.props;
    return {
      necessary: true,
      marketingAndAnalytics: Boolean(preferences.marketingAndAnalytics),
      functional: Boolean(preferences.functional),
      advertising: Boolean(preferences.advertising),
    };
  };

  render() {
    const { intl, currentLocale } = this.props;

    const selection = this.getNormalizedPreferences();

    const buttonClassName = classnames('c-btn');
    const purpleButtonClassName = classnames('c-btn', 'c-btn--is-purple');
    const linkButtonClassName = classnames('c-text-link--is-visible');

    const privacyPolicyText = intl.formatMessage(messages.privacyPolicyLink);
    const ariaPrivacyPolicyLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
      linkText: privacyPolicyText,
    });

    const imprintText = intl.formatMessage(messages.imprintLink);
    const ariaImprintLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
      linkText: imprintText,
    });

    return (
      <section className={styles.modal} data-test="cookie-banner.modal">
        <div className={styles.header}>
          <h2>
            <FormattedMessage
              id="gdpr.opt-out-modal.cookie-settings"
              defaultMessage="Cookie Settings"
            />
          </h2>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.content}>
            <p>
              <FormattedMessage
                defaultMessage="We use cookies to recognise your repeat visits, enable product functionality, and improve our marketing. This includes cookies that are necessary for the operation of the site, as well as cookies that are used only for anonymous statistical purposes, for storing your personal settings or to display personalized content. You can decide which categories you want to allow. Please note that based on your settings, not all functionalities of the site may be available."
                id="gdpr.opt-out-modal.info-1"
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Further information can be found in our {privacyPolicyLink} and {imprintLink}."
                id="gdpr.opt-out-modal.info-2"
                values={{
                  privacyPolicyLink: (
                    <a
                      href={getWebsiteUrlInLocale('/privacy#cookies', currentLocale)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-text-link--is-visible"
                      aria-label={ariaPrivacyPolicyLink}
                    >
                      {privacyPolicyText}
                    </a>
                  ),
                  imprintLink: (
                    <a
                      href={getWebsiteUrlInLocale('/imprint', currentLocale)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-text-link--is-visible"
                      aria-label={ariaImprintLink}
                    >
                      {imprintText}
                    </a>
                  ),
                }}
              />
            </p>
          </div>

          <div className={styles.selectionWrapper}>
            {!this.state.showDetails ? (
              <ConsentManagerSelection selection={selection} onChange={this.onSelectionChange} />
            ) : (
              <ConsentManagerSelectionTable
                selection={selection}
                onChange={this.onSelectionChange}
              />
            )}
          </div>

          <div className={styles.footer}>
            <button className={buttonClassName} onClick={this.onAllowSelection}>
              <FormattedMessage
                defaultMessage="Allow Selection"
                id="gdpr.opt-out-modal.allow-selection"
              />
            </button>
            <button
              className={purpleButtonClassName}
              onClick={this.onAllowAllCookies}
              data-test="cookie-banner.allow-all-btn"
            >
              <FormattedMessage
                defaultMessage="Allow All Cookies"
                id="gdpr.opt-out-modal.allow-all-cookies"
              />
            </button>
            <button className={linkButtonClassName} onClick={this.onToggleDetails}>
              {!this.state.showDetails ? (
                <FormattedMessage id="gdpr.opt-out-modal.details" defaultMessage="Details" />
              ) : (
                <FormattedMessage id="gdpr.opt-out-modal.cancel" defaultMessage="Cancel" />
              )}
            </button>
          </div>
        </div>
      </section>
    );
  }

  hideModal = () => {
    if (this.props.hideModal) {
      this.props.hideModal();
    } else {
      window.location = '/';
    }
  };
}

function mapStateToProps(state) {
  return {
    currentLocale: selectLocale(state),
  };
}

export default compose(connect(mapStateToProps, {}), injectIntl)(ConsentManagerInner);
