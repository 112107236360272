// @flow
import React from 'react';
import { PLAN_ID_PREMIUM, PLAN_ID_PPC_12M, PLAN_ID_PPC_1M } from '../../constants';
import PlansOverviewSubscribe from './PlansOverviewSubscribe';
import PaymentStep from './PaymentStep';
import ConversionStepHeader from './ConversionStepHeader';
import styles from './ConversionFormVisual.css';
import stylesPlansOverview from './PlansOverview.css';

type OwnProps = {
  selectedPlan: PLAN_ID_PREMIUM | PLAN_ID_PPC_12M | PLAN_ID_PPC_1M,
  price: number,
  isStudentStripeCoupon: boolean,
  isTrialAvailable: boolean,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  setSelectedPlan: Function,
  ppcPlan: PLAN_ID_PPC_12M | PLAN_ID_PPC_1M,
  setPpcPlan: Function,
  isInModal: boolean,
  onPaymentSuccess: Function,
  trialDurationDays: number,
  defaultPaymentType?: string,
  userIsAllowedOptOutTrial: boolean,
};

type Props = OwnProps;

const ConversionStep = ({
  selectedPlan,
  price,
  isStudentStripeCoupon,
  isTrialAvailable,
  relativeDiscount,
  reducedPriceDuration,
  setSelectedPlan,
  ppcPlan,
  setPpcPlan,
  isInModal,
  onPaymentSuccess,
  trialDurationDays,
  defaultPaymentType,
  userIsAllowedOptOutTrial,
}: Props) => {
  return (
    <React.Fragment>
      <ConversionStepHeader
        isStudentStripeCoupon={isStudentStripeCoupon}
        isTrialAvailable={isTrialAvailable}
        relativeDiscount={relativeDiscount}
        reducedPriceDuration={reducedPriceDuration}
        trialDurationDays={trialDurationDays}
      />
      <div className={styles.conversionGrid}>
        <div
          className={stylesPlansOverview.container}
          data-test="conversion-form.plans-overview-step-view"
        >
          <PlansOverviewSubscribe
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
            isTrialAvailable={isTrialAvailable}
            relativeDiscount={relativeDiscount}
            ppcPlan={ppcPlan}
            setPpcPlan={setPpcPlan}
            trialDurationDays={trialDurationDays}
          />
        </div>
        <PaymentStep
          isInModal={isInModal}
          onSuccess={onPaymentSuccess}
          price={price}
          trialDurationDays={trialDurationDays}
          isTrialAvailable={isTrialAvailable}
          relativeDiscount={relativeDiscount}
          reducedPriceDuration={reducedPriceDuration}
          defaultPaymentType={defaultPaymentType}
          selectedPlan={selectedPlan}
          userIsAllowedOptOutTrial={userIsAllowedOptOutTrial}
        />
      </div>
    </React.Fragment>
  );
};

export default ConversionStep;
