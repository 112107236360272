/* @flow */

import { defineMessages } from 'react-intl';

// Apparently you can't import this type from the react-intl libdef
type MessageDescriptor = {
  id: string,
  description?: string,
  defaultMessage?: string,
};

export type Notification = {|
  message: MessageDescriptor,
  severity: 'warning' | 'error' | 'info' | 'success' | 'promo',
  persistent: boolean,
  topic: string,
  learnMoreAction?: Function,
  onClick?: Function,
  id?: number,
  values?: Object,
|};

const messages = defineMessages({
  apiError: {
    id: 'notifications.api-error',
    defaultMessage: "Something went wrong. We're working on it.",
  },
  notFound: {
    id: 'notifications.not-found',
    defaultMessage: '404 - Not found',
  },
  pageNotFound: {
    id: 'notifications.page-not-found',
    defaultMessage: 'Much like Beethoven’s 10th, this page seems not to exist.',
  },
  unauthorized: {
    id: 'notifications.not-authorised',
    defaultMessage: 'Your login has been invalidated, please login again',
  },
  playbackError: {
    id: 'notifications.playback-error',
    defaultMessage: 'Sorry, an error occurred during playback. Please try again.',
  },
  playbackNotSupported: {
    id: 'notifications.no-playback-support',
    defaultMessage: 'Unfortunately playback is not supported in your current browser.',
  },
  playbackStartedFromOtherDevice: {
    id: 'notifications.playback.started-from-other-device',
    defaultMessage: 'Playback has been started from another browser window or device.',
  },
  invalidPassword: {
    id: 'account.password.error.invalid',
    defaultMessage: 'Your password must be at least 8 characters long',
  },
  passwordTooLong: {
    id: 'account.password.error.too-long',
    defaultMessage: 'Your password exceeds the maximum length of 512 characters',
  },
  couponError: {
    id: 'coupon.notifications.error',
    defaultMessage:
      "Sorry, the code didn't work. Please make sure you entered it correctly, and contact us if the problem persists.",
  },
  couponErrorHasAnActiveCoupon: {
    id: 'coupon.notifications.error-has-an-active-coupon',
    defaultMessage:
      "You can only apply one discount code at a time. Please try again after your current discount code ends - your new code won't expire.",
  },
  couponSuccess: {
    id: 'coupon.notifications.success',
    defaultMessage: 'Your code was successfully registered. Enjoy IDAGIO!',
  },
  applyingCoupon: {
    id: 'coupon.notifications.working',
    defaultMessage: 'Applying discount code',
  },
  couponNotEligible: {
    id: 'coupon.notifications.not-eligible-2',
    defaultMessage:
      'Vouchers cannot be applied to accounts with a running Apple or Android subscription without cancelling first. After the cancellation, please allow 7 days for Android and 10 for Apple subscriptions to run out, before you enter the code again.',
  },
  couponNotAuth: {
    id: 'coupon.notifications.not-auth',
    defaultMessage: 'Please register or login to apply the voucher',
  },
  creatingAccount: {
    id: 'notifications.account.creating',
    defaultMessage: 'Creating your account',
  },
  authSuccess: {
    id: 'notifications.auth.success-2',
    defaultMessage: 'Welcome back to IDAGIO!',
  },
  createdAccount: {
    id: 'notifications.account.created-2',
    defaultMessage: 'Welcome to your newly created IDAGIO account!',
  },
  premiumSubCompleted: {
    id: 'notifications.premium.complete',
    defaultMessage: 'Subscription complete.',
  },
  changeFirstNameSuccess: {
    id: 'account.firstname-change.success',
    defaultMessage: 'You successfully changed your username',
  },
  changePasswordSuccess: {
    id: 'account.password-change.success',
    defaultMessage: 'You successfully changed your password',
  },
  setPasswordSuccessAndAuthenticated: {
    id: 'account.set-password.success-and-authenticated',
    defaultMessage: 'Your password has been changed and you are now logged in',
  },
  setPasswordInvalidToken: {
    id: 'account.set-password.invalid-token',
    defaultMessage:
      'Your reset password link is not valid. Please try again or contact support@idagio.com.',
  },
  forgotPasswordSuccess: {
    id: 'account.password-reset.success',
    defaultMessage: 'Please check your email for the next steps',
  },
  passwordResetGenericError: {
    id: 'account.password-reset.generic',
    defaultMessage: "Sorry, that didn't work. Please try again.",
  },
  passwordErrorNoAuth: {
    id: 'account.password.error.no-auth',
    defaultMessage: 'You need to be logged in',
  },
  passwordGenericError: {
    id: 'account.password.error.generic',
    defaultMessage: 'Sorry, there was an error changing your password',
  },
  firstnameGenericError: {
    id: 'account.firstname.error.generic',
    defaultMessage: 'Sorry, there was an error changing your username',
  },
  loginRedirecting: {
    id: 'notifications.login.redirecting',
    defaultMessage: 'Redirecting ...',
  },
  offline: {
    id: 'notifications.offline',
    defaultMessage: "You're currently offline. Please reconnect to the internet and try again.",
  },
  createdAccountServer: {
    id: 'account.create.server-success',
    defaultMessage: 'Account created successfully! Please login to continue...',
  },
  createAccountInvalid: {
    id: 'account.error.create.invalid',
    defaultMessage: 'Please enter a valid email address and password.',
  },
  createAccountGenericError: {
    id: 'account.error.create.generic',
    defaultMessage: "Sorry, that didn't work. Please try again.",
  },
  createAccountDuplicate: {
    id: 'account.error.create.duplicate',
    defaultMessage: 'The email address you entered is already registered.',
  },
  loginProvideCreds: {
    id: 'account.error.login.provide-creds',
    defaultMessage: 'Please provide email and password',
  },
  accountBackendError: {
    id: 'account.error.backend',
    defaultMessage: 'Error when talking to backend API',
  },
  loginGenericError: {
    id: 'account.error.login.generic-2',
    defaultMessage: 'Sorry, there was an error logging you in',
  },
  loginIncorrectCreds: {
    id: 'account.error.login.incorrect-creds',
    defaultMessage: 'Sorry, the email or password you entered was incorrect',
  },
  userNotEligible: {
    id: 'reduced-price-funnel.error.user-not-eligible',
    defaultMessage: 'Unfortunately you are not eligible for this offer',
  },
  addToPlaylist: {
    id: 'playlist.notification.add',
    defaultMessage: 'Added to Playlist',
  },
  removeFromPlaylist: {
    id: 'playlist.notification.remove',
    defaultMessage: 'Removed from Playlist',
  },
  createPlaylist: {
    id: 'playlist.notification.create',
    defaultMessage: 'Playlist Created',
  },
  savePlaylist: {
    id: 'playlist.notification.save',
    defaultMessage: 'Playlist Saved',
  },
  deletePlaylist: {
    id: 'playlist.notification.delete',
    defaultMessage: 'Playlist Deleted',
  },
  maxTracksPlaylist: {
    id: 'playlist.notification.max-tracks',
    defaultMessage: 'Maximum number of tracks reached; unable to add more.',
  },
  emptyTitlePlaylist: {
    id: 'personal-playlists.errors.empty-title',
    defaultMessage: 'Please provide playlist title',
  },
  playlistSaveError: {
    id: 'personal-playlists.errors.generic',
    defaultMessage: 'An error occurred while saving your playlist',
  },
  sonosDisconnected: {
    id: 'notifications.sonos-disconnected',
    defaultMessage: 'Sonos disconnected',
  },
  connectedFacebookCancel: {
    id: 'account.facebook-connect.cancel',
    defaultMessage: 'Invalid Facebook permissions',
  },
  connectedFacebookSuccess: {
    id: 'account.facebook-connect.success',
    defaultMessage: 'You have successfully connected your Facebook account',
  },
  disconnectedFacebookSuccess: {
    id: 'account.facebook-disconnect.success',
    defaultMessage: 'You have successfully disconnected your Facebook account',
  },
  facebookLoginUnavailable: {
    id: 'account.facebook-login-unavailable',
    defaultMessage: 'Facebook cannot be connected right now',
  },
  cancelSubscriptionError: {
    id: 'account.error.cancel.subscription',
    defaultMessage: 'Could not cancel subscription - Please try again or contact support',
  },
  stripeRevalidationRequiredUrgent: {
    id: 'stripe.revalidation-required.urgent',
    defaultMessage:
      'Please verify your payment details {days, plural, =0 {today} other {within # days}} or your subscription will be cancelled. Click here to review now',
  },
  stripeRevalidationRequiredInitial: {
    id: 'stripe.revalidation-required.initial',
    defaultMessage:
      'Please verify your payment details by {renewalDate, date, medium}. Verification keeps your bank information safe. Click here to review now.',
  },
  stripeRevalidationSuccess: {
    id: 'stripe.revalidation.success',
    defaultMessage: 'Your payment details were successfully verified.',
  },
  stripeRevalidationError: {
    id: 'stripe.revalidation.error',
    defaultMessage:
      'An error occurred during verification. Try again later or email support at contact@idagio.com.',
  },
  upgradeAppleSubs: {
    id: 'upgrade.apple-subs',
    defaultMessage:
      'You have an active subscription through the Apple App Store. To upgrade to Premium+ Concerts, please go to your Apple Subscriptions. Click here to read more or get help.',
  },
  upgradeAndroidSubs: {
    id: 'upgrade.android-subs',
    defaultMessage:
      'You have an active subscription through the Google Play Store. To upgrade to Premium+ Concerts, please go to your Google Subscriptions. Click here to read more or get help.',
  },
  trackWillPlayAfterThisAd: {
    id: 'playback.notification.track-will-play-after-this-ad',
    defaultMessage: 'The selected track will be played after this ad',
  },
  studentVerificationUsedVerificationId: {
    id: 'student.verification.error.used-verification-id',
    defaultMessage:
      'Your student offer was already redeemed on IDAGIO. Please log in with your subscribed account or get in touch with support@idagio.com.',
  },
  studentVerificationInvalidVerificationId: {
    id: 'student.verification.error.invalid-verification-id',
    defaultMessage:
      "Something went wrong: We couldn't verify your status and your student discount cannot be applied. Please get in touch with support@idagio.com.",
  },
  studentVerificationGenericError: {
    id: 'student.verification.error.generic',
    defaultMessage:
      'An error occurred during verification. Please try again later or get in touch with support@idagio.com.',
  },
  studentVerificationErrorNonStripeSubscription: {
    id: 'student.verification.error.non-stripe-subscription',
    defaultMessage:
      'You are currently subscribed to IDAGIO. For now, the student discount can only be applied to (new) subscriptions through our website. Please get in touch with support@idagio.com.',
  },
  studentVerificationSuccessStripeSubscription: {
    id: 'student.verification.success.stripe-subscription',
    defaultMessage:
      'As you are already subscribed to IDAGIO, the student discount has been applied to your existing subscription and you will be billed the reduced amount for the next 12 months.',
  },
  upSellQuality: {
    id: 'up-sell-quality',
    defaultMessage: 'Become a subscriber to upgrade your audio quality.',
  },
  upSellExternalDevices: {
    id: 'up-sell-external-devices',
    defaultMessage: 'Become a subscriber to connect to Sonos and other third party devices.',
  },
  couponNoSubscribe: {
    id: 'coupon.notifications.error-only-applicable-to-active-subscription',
    defaultMessage:
      'To redeem this code, you first need to create an IDAGIO account and begin a subscription. Then, go to Settings and enter your code.',
  },
  haveTicket: {
    id: 'livestream-event.notification.has-ticket',
    defaultMessage: 'You already have a ticket for this event',
  },
  addToCollectionAlbum: {
    id: 'add-to-collection-album',
    defaultMessage: 'Album was added to collection',
  },
  addToCollectionRecording: {
    id: 'add-to-collection-recording',
    defaultMessage: 'Recording was added to collection',
  },
  addToCollectionArtist: {
    id: 'add-to-collection-artist',
    defaultMessage: 'Artist was added to collection',
  },
  addToCollectionPlaylist: {
    id: 'add-to-collection-playlist',
    defaultMessage: 'Playlist was added to collection',
  },
  addToCollectionTrack: {
    id: 'add-to-collection-track',
    defaultMessage: 'Track was added to collection',
  },
  removeFromCollectionAlbum: {
    id: 'remove-from-collection-album',
    defaultMessage: 'Album was removed from collection',
  },
  removeFromCollectionRecording: {
    id: 'remove-from-collection-recording',
    defaultMessage: 'Recording was removed from collection',
  },
  removeFromCollectionArtist: {
    id: 'remove-from-collection-artist',
    defaultMessage: 'Artist was removed from collection',
  },
  removeFromCollectionPlaylist: {
    id: 'remove-from-collection-playlist',
    defaultMessage: 'Playlist was removed from collection',
  },
  removeFromCollectionTrack: {
    id: 'remove-from-collection-track',
    defaultMessage: 'Track was removed from collection',
  },
  accessZoomError: {
    id: 'livestream-event.notification.access-zoom-error',
    defaultMessage: 'Sorry, an error occurred while accessing the meeting.',
  },
});

export const API_ERROR: Notification = {
  message: messages.apiError,
  severity: 'warning',
  persistent: true,
  topic: 'data-fetching',
};

export const NOT_FOUND: Notification = {
  message: messages.notFound,
  severity: 'error',
  persistent: false,
  topic: 'data-fetching',
};

// Used for capacitor instead of the 404 page
export const PAGE_NOT_FOUND: Notification = {
  message: messages.pageNotFound,
  severity: 'error',
  persistent: false,
  topic: 'data-fetching',
};

export const UNAUTHORISED: Notification = {
  message: messages.unauthorized,
  severity: 'info',
  persistent: false,
  topic: 'auth',
};

export const PLAYBACK_ERROR: Notification = {
  message: messages.playbackError,
  severity: 'warning',
  persistent: true,
  topic: 'playback',
};

export const PLAYBACK_NOT_SUPPORTED: Notification = {
  message: messages.playbackNotSupported,
  severity: 'error',
  persistent: true,
  topic: 'playback',
};

export const PLAYBACK_STARTED_FROM_OTHER_DEVICE: Notification = {
  message: messages.playbackStartedFromOtherDevice,
  severity: 'info',
  persistent: false,
  topic: 'pusher',
};

export const INVALID_PASSWORD: Notification = {
  message: messages.invalidPassword,
  persistent: false,
  severity: 'error',
  topic: 'auth',
};

export const PASSWORD_TOO_LONG: Notification = {
  message: messages.passwordTooLong,
  persistent: false,
  severity: 'error',
  topic: 'auth',
};

export const COUPON_ERROR: Notification = {
  message: messages.couponError,
  severity: 'error',
  persistent: true,
  topic: 'coupon',
};

export const COUPON_ERROR_HAS_AN_ACTIVE_COUPON: Notification = {
  message: messages.couponErrorHasAnActiveCoupon,
  severity: 'error',
  persistent: true,
  topic: 'coupon',
};

export const COUPON_SUCCESS: Notification = {
  message: messages.couponSuccess,
  severity: 'success',
  persistent: true,
  topic: 'coupon',
};

export const COUPON_NOT_ELIGIBLE: Notification = {
  message: messages.couponNotEligible,
  severity: 'error',
  persistent: true,
  topic: 'coupon',
};

export const APPLYING_COUPON: Notification = {
  message: messages.applyingCoupon,
  severity: 'info',
  persistent: true,
  topic: 'coupon',
};

export const COUPON_NOT_AUTH: Notification = {
  message: messages.couponNotAuth,
  severity: 'error',
  persistent: true,
  topic: 'coupon',
};

export const CREATING_ACCOUNT: Notification = {
  message: messages.creatingAccount,
  severity: 'info',
  persistent: true,
  topic: 'auth',
};

export const SIGN_UP_SUCCESS: Notification = {
  message: messages.createdAccount,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const AUTH_SUCCESS: Notification = {
  message: messages.authSuccess,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const SUBSCRIPTION_SUCCESS: Notification = {
  message: messages.premiumSubCompleted,
  severity: 'success',
  persistent: false,
  topic: 'subscription',
};

export const CANCEL_SUBSCRIPTION_ERROR: Notification = {
  message: messages.cancelSubscriptionError,
  severity: 'error',
  persistent: true,
  topic: 'subscription',
};

export const FIRSTNAME_CHANGE_SUCCESS: Notification = {
  message: messages.changeFirstNameSuccess,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const PASSWORD_CHANGE_SUCCESS: Notification = {
  message: messages.changePasswordSuccess,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const SET_PASSWORD_SUCCESS_AND_AUTHENTICATED: Notification = {
  message: messages.setPasswordSuccessAndAuthenticated,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const SET_PASSWORD_INVALID_TOKEN: Notification = {
  message: messages.setPasswordInvalidToken,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const REQUEST_PASSWORD_RESET_SUCCESS: Notification = {
  message: messages.forgotPasswordSuccess,
  severity: 'success',
  persistent: true,
  topic: 'auth',
};

export const PASSWORD_RESET_GENERIC_ERROR: Notification = {
  message: messages.passwordResetGenericError,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const NOT_LOGGED_IN: Notification = {
  message: messages.passwordErrorNoAuth,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const GENERIC_CHANGE_FIRSTNAME_ERROR: Notification = {
  message: messages.firstnameGenericError,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const GENERIC_CHANGE_PASSWORD_ERROR: Notification = {
  message: messages.passwordGenericError,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const PLEASE_WHILE_REDIRECTING: Notification = {
  message: messages.loginRedirecting,
  severity: 'success',
  persistent: true,
  topic: 'auth',
};

export const OFFLINE: Notification = {
  message: messages.offline,
  topic: 'connectivity',
  severity: 'warning',
  persistent: true,
};

export const CREATE_ACCOUNT_SERVER_SUCCESS: Notification = {
  message: messages.createdAccountServer,
  severity: 'success',
  persistent: true,
  topic: 'signup',
};

export const CREATE_ACCOUNT_INVALID_CREDENTIALS: Notification = {
  message: messages.createAccountInvalid,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const CREATE_ACCOUNT_GENERIC_ERROR: Notification = {
  message: messages.createAccountGenericError,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const CREATE_ACCOUNT_DUPLICATE: Notification = {
  message: messages.createAccountDuplicate,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const LOGIN_PROVIDE_CREDENTIALS: Notification = {
  message: messages.loginProvideCreds,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const ACCOUNT_BACKEND_ERROR: Notification = {
  message: messages.accountBackendError,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const LOGIN_GENERIC_ERROR: Notification = {
  message: messages.loginGenericError,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const LOGIN_INCORRECT_CREDENTIALS: Notification = {
  message: messages.loginIncorrectCreds,
  severity: 'error',
  persistent: true,
  topic: 'auth',
};

export const USER_NOT_ELIGIBLE: Notification = {
  message: messages.userNotEligible,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const SONOS_DISCONNECTED: Notification = {
  message: messages.sonosDisconnected,
  severity: 'info',
  persistent: false,
  topic: 'playback',
};

export const CONNECTED_FACEBOOK_CANCEL: Notification = {
  message: messages.connectedFacebookCancel,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const CONNECTED_FACEBOOK_SUCCESS: Notification = {
  message: messages.connectedFacebookSuccess,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const DISCONNECTED_FACEBOOK_SUCCESS: Notification = {
  message: messages.disconnectedFacebookSuccess,
  severity: 'success',
  persistent: false,
  topic: 'auth',
};

export const FACEBOOK_LOGIN_UNAVAILABLE: Notification = {
  message: messages.facebookLoginUnavailable,
  severity: 'error',
  persistent: false,
  topic: 'auth',
};

export const ADDED_TO_PLAYLIST: Notification = {
  message: messages.addToPlaylist,
  severity: 'info',
  persistent: false,
  topic: 'playlist',
};

export const REMOVED_FROM_PLAYLIST: Notification = {
  message: messages.removeFromPlaylist,
  severity: 'info',
  persistent: false,
  topic: 'playlist',
};

export const PLAYLIST_CREATED: Notification = {
  message: messages.createPlaylist,
  severity: 'info',
  persistent: false,
  topic: 'playlist',
};

export const PLAYLIST_SAVED: Notification = {
  message: messages.savePlaylist,
  severity: 'info',
  persistent: false,
  topic: 'playlist',
};

export const PLAYLIST_DELETED: Notification = {
  message: messages.deletePlaylist,
  severity: 'info',
  persistent: false,
  topic: 'playlist',
};

export const PLAYLIST_EXCEEDS_MAX_TRACKS: Notification = {
  message: messages.maxTracksPlaylist,
  severity: 'error',
  persistent: true,
  topic: 'playlist',
};

export const PLAYLIST_EMPTY_TITLE_ERROR: Notification = {
  message: messages.emptyTitlePlaylist,
  severity: 'error',
  persistent: true,
  topic: 'playlist',
};

export const PLAYLIST_SAVE_ERROR: Notification = {
  message: messages.playlistSaveError,
  severity: 'error',
  persistent: true,
  topic: 'playlist',
};

export const STRIPE_REVALIDATION_REQUIRED_URGENT: Notification = {
  message: messages.stripeRevalidationRequiredUrgent,
  severity: 'info',
  persistent: true,
  topic: 'stripe',
};

export const STRIPE_REVALIDATION_REQUIRED_INITIAL: Notification = {
  message: messages.stripeRevalidationRequiredInitial,
  severity: 'info',
  persistent: true,
  topic: 'stripe',
};

export const STRIPE_REVALIDATION_SUCCESS: Notification = {
  message: messages.stripeRevalidationSuccess,
  severity: 'success',
  persistent: false,
  topic: 'stripe',
};

export const STRIPE_REVALIDATION_ERROR: Notification = {
  message: messages.stripeRevalidationError,
  severity: 'error',
  persistent: true,
  topic: 'stripe',
};

export const UPGRADE_APPLE_SUBS: Notification = {
  message: messages.upgradeAppleSubs,
  severity: 'warning',
  persistent: true,
  topic: 'subscription',
};

export const UPGRADE_ANDROID_SUBS: Notification = {
  message: messages.upgradeAndroidSubs,
  severity: 'warning',
  persistent: true,
  topic: 'subscription',
};

export const STUDENT_VERIFICATION_USED_VERIFICATION_ID: Notification = {
  message: messages.studentVerificationUsedVerificationId,
  severity: 'error',
  persistent: true,
  topic: 'subscription',
};

export const STUDENT_VERIFICATION_INVALID_VERIFICATION_ID: Notification = {
  message: messages.studentVerificationInvalidVerificationId,
  severity: 'error',
  persistent: true,
  topic: 'subscription',
};

export const STUDENT_VERIFICATION_GENERIC_ERROR: Notification = {
  message: messages.studentVerificationGenericError,
  severity: 'error',
  persistent: true,
  topic: 'subscription',
};

export const STUDENT_VERIFICATION_ERROR_NON_STRIPE_SUBSCRIPTION: Notification = {
  message: messages.studentVerificationErrorNonStripeSubscription,
  severity: 'error',
  persistent: true,
  topic: 'subscription',
};

export const STUDENT_VERIFICATION_SUCCESS_STRIPE_SUBSCRIPTION: Notification = {
  message: messages.studentVerificationSuccessStripeSubscription,
  severity: 'success',
  persistent: true,
  topic: 'subscription',
};

export const UP_SELL_QUALITY: Notification = {
  message: messages.upSellQuality,
  severity: 'promo',
  persistent: true,
  topic: 'subscription',
};

export const UP_SELL_EXTERNAL_DEVICES: Notification = {
  message: messages.upSellExternalDevices,
  severity: 'promo',
  persistent: true,
  topic: 'subscription',
};

export const TRACK_WILL_PLAY_AFTER_THIS_AD: Notification = {
  message: messages.trackWillPlayAfterThisAd,
  severity: 'info',
  persistent: false,
  topic: 'playback',
};

export const COUPON_ERROR_NO_SUBSCRIBE: Notification = {
  message: messages.couponNoSubscribe,
  severity: 'error',
  persistent: true,
  topic: 'coupon',
};

export const HAVE_TICKET: Notification = {
  message: messages.haveTicket,
  severity: 'info',
  persistent: false,
  topic: 'tickets',
};

export const ADD_TO_COLLECTION_ALBUM: Notification = {
  message: messages.addToCollectionAlbum,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const ADD_TO_COLLECTION_RECORDING: Notification = {
  message: messages.addToCollectionRecording,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const ADD_TO_COLLECTION_ARTIST: Notification = {
  message: messages.addToCollectionArtist,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const ADD_TO_COLLECTION_PLAYLIST: Notification = {
  message: messages.addToCollectionPlaylist,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const ADD_TO_COLLECTION_TRACK: Notification = {
  message: messages.addToCollectionTrack,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const REMOVE_FROM_COLLECTION_ALBUM: Notification = {
  message: messages.removeFromCollectionAlbum,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const REMOVE_FROM_COLLECTION_RECORDING: Notification = {
  message: messages.removeFromCollectionRecording,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const REMOVE_FROM_COLLECTION_ARTIST: Notification = {
  message: messages.removeFromCollectionArtist,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const REMOVE_FROM_COLLECTION_PLAYLIST: Notification = {
  message: messages.removeFromCollectionPlaylist,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const REMOVE_FROM_COLLECTION_TRACK: Notification = {
  message: messages.removeFromCollectionTrack,
  severity: 'success',
  persistent: false,
  topic: 'collection',
};

export const ACCESS_ZOOM_ERROR: Notification = {
  message: messages.accessZoomError,
  severity: 'error',
  persistent: true,
  topic: 'events',
};

export const EASY_CONNECT_ERROR: Notification = {
  message: messages.apiError,
  severity: 'error',
  persistent: true,
  topic: 'events',
};
