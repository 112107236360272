import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import styles from '../common/PersonalPlaylistItem.css';
import { Image } from '../util/Image';
import { IMGIX_PARAMS } from '../../constants';

class PersonalPlaylistListItem extends Component {
  static propTypes = {
    playlist: PropTypes.object.isRequired,
    onItemClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { playlist, onItemClick } = this.props;
    onItemClick(playlist);
  };

  render() {
    const { playlist } = this.props;

    return (
      <div className={styles.component}>
        <button
          onClick={this.onClick}
          className={classnames('dummy-btn', styles.playlistButton)}
          aria-label={playlist.title}
        >
          <CapacitorRipple />
        </button>
        <div className={styles.cover}>
          <Image
            noBase
            src={playlist.imageUrl}
            auto={IMGIX_PARAMS}
            className={styles.bg}
            width={64}
            height={64}
            alt=""
          />
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <span>{playlist.title}</span>
          </div>
          <span className={styles.copyrightText}>{playlist.copyright}</span>
        </div>
      </div>
    );
  }
}

export default PersonalPlaylistListItem;
