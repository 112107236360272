import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import classnames from 'classnames';

import Modal from './Modal';
import * as uiActions from '../../actions/ui';

import { getEntityUrl } from '../../lib/entities';
import { selectOpenModalTypeIs } from '../../selectors/modals';
import { selectBaseUrl, selectPageTrackingContext } from '../../selectors/client';
import { selectUserIsAuthenticated } from '../../selectors/user';
import { selectAlbum } from '../../selectors/album';
import { selectPlaylist } from '../../selectors/playlist';

import { ENTITY_TYPE_PLAYLIST, CONSTANT_MESSAGES } from '../../constants';

import styles from './EmbedPreviewsModal.css';
import SignupOrLogin from '../messages/SignupOrLogin';

const messages = defineMessages({
  loginLink: {
    id: 'embed-previews-modal.login-here',
    defaultMessage: 'Log in here',
  },
  signUpLink: {
    id: 'embed-previews-modal.create-account',
    defaultMessage: 'Create account',
  },
});

const getOpenInWebAppLink = (collectionType, collectionId, collectionTitle, baseUrl) => {
  const path = getEntityUrl(collectionType, { slug: collectionId });
  const utmCampaign = encodeURIComponent(collectionTitle);
  const trackingParams =
    'utm_medium=embedded&utm_source=empl&utm_term=modal' + `&utm_campaign=${utmCampaign}`;
  return `${baseUrl}${path}?${trackingParams}`;
};

class EmbedPreviewsModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    baseUrl: PropTypes.string.isRequired,
    contextType: PropTypes.string.isRequired,
    contextId: PropTypes.string.isRequired,
    contextTitle: PropTypes.string.isRequired,
    intl: intlShape,
  };

  render() {
    const {
      hideModal,
      isOpen,
      isAuthenticated,
      baseUrl,
      contextType,
      contextId,
      contextTitle,
      intl,
    } = this.props;

    const linkToEmbedContent = getOpenInWebAppLink(contextType, contextId, contextTitle, baseUrl);

    let signupLink = linkToEmbedContent;
    let loginLink = linkToEmbedContent;

    if (!isAuthenticated) {
      signupLink += '&showPreviewsModal=true';
      loginLink += '&showLoginModal=true';
    }

    const loginText = intl.formatMessage(messages.loginLink);
    const ariaLoginLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
      linkText: loginText,
    });

    const signUpText = intl.formatMessage(messages.signUpLink);
    const ariaSignUpLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
      linkText: signUpText,
    });

    const buttonStyles = classnames('c-btn', 'c-btn--is-purple', styles.button);
    return (
      <Modal
        isOpen={isOpen}
        onRequesthideModal={hideModal}
        onClose={hideModal}
        contentLabel="lossless-support-modal"
      >
        <section className={styles.modal}>
          <h1 className={`${styles.title} fz--beta`}>
            <FormattedMessage
              id="embed-previews-modal.text-2"
              defaultMessage="Create an account for free to listen to the full playlist."
            />
          </h1>
          <a
            href={signupLink}
            className={buttonStyles}
            onClick={hideModal}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={ariaSignUpLink}
          >
            {signUpText}
          </a>
          <p className={styles.text}>
            <a
              href={loginLink}
              className="c-text-link--is-visible"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={ariaLoginLink}
            >
              <SignupOrLogin />
            </a>
          </p>
        </section>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { contextType, contextId, contextTitle } = selectPageTrackingContext(state);

  let content;

  if (!contextTitle.length) {
    content =
      contextType === ENTITY_TYPE_PLAYLIST
        ? selectPlaylist(state, contextId)
        : selectAlbum(state, contextId);
  }

  return {
    isOpen: selectOpenModalTypeIs(state, 'EMBED_PREVIEWS_MODAL'),
    isAuthenticated: selectUserIsAuthenticated(state),
    baseUrl: selectBaseUrl(state),
    contextType,
    contextId,
    contextTitle: (content && content.title) || contextTitle,
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
})(injectIntl(EmbedPreviewsModal));
