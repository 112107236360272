// @flow
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { PLAN_ID_PREMIUM, PLAN_ID_PPC_1M, PLAN_ID_PPC_12M } from '../../constants';

const messages = defineMessages({
  [PLAN_ID_PPC_12M]: {
    id: 'subscribe-modal.plans.premium-concerts-12m',
    defaultMessage: 'Premium + Concerts (12-month)',
  },
  [PLAN_ID_PPC_1M]: {
    id: 'subscribe-modal.plans.premium-concerts-1m',
    defaultMessage: 'Premium + Concerts (monthly)',
  },
  [PLAN_ID_PREMIUM]: {
    id: 'subscribe-modal.plans.premium',
    defaultMessage: 'Premium+ (monthly)',
  },
});

type OwnProps = {
  plan: string,
  className?: string,
};

const BilledNowPlan = ({ plan, className }: OwnProps) => {
  if (!messages[plan]) {
    return null;
  }

  return (
    <div className={className}>
      <FormattedMessage
        id="subscribe-modal.payment-details.your-plan"
        defaultMessage="Your plan:"
      />
      <FormattedMessage {...messages[plan]} />
    </div>
  );
};

export default BilledNowPlan;
