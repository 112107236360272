/* global Stripe */
// @flow
import React from 'react';
import { connect } from 'react-redux';

import Price from '../common/Price';
import TrialTooltip from './TrialTooltip';

import { selectIsDesktop } from '../../selectors/client';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  isTrialAvailable: boolean,
  price?: number,
  relativeDiscount?: number,
};

type MapStateToProps = {
  isDesktop: boolean,
};

type Props = OwnProps & MapStateToProps;

const BilledNowPrice = ({ isTrialAvailable, isDesktop, price, relativeDiscount }: Props) => {
  const BilledPrice = () => {
    if (isTrialAvailable) {
      return (
        <span>
          <Price price={0.0} />
          {isDesktop && <TrialTooltip />}
        </span>
      );
    }

    if (price !== undefined) {
      return <Price price={price} relativeDiscount={relativeDiscount} />;
    }

    return <Price />;
  };

  return (
    <React.Fragment>
      <FormattedMessage
        id="subscribe-modal.payment-details.billed-now"
        defaultMessage="Billed now:"
      />
      <BilledPrice />
    </React.Fragment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(BilledNowPrice);
