// @flow
import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import type { IntlShape } from 'react-intl';
import classnames from 'classnames';
import styles from './SearchFilters.css';
import IconLabel from '../util/IconLabel';
import CapacitorRipple from '../capacitor/Ripple';

type OwnProps = {
  filters: string[],
  onFilterSelect: Function,
  activeFilter: string | null,
  isValidQuery?: boolean,
};

type Props = OwnProps & { intl: IntlShape };

const messages = defineMessages({
  artists: {
    id: 'search.filter.artists',
    defaultMessage: 'Artists',
  },
  compositionsAndRecordings: {
    id: 'search.filter.compositions-and-recordings',
    defaultMessage: 'Works&Recordings',
  },
  playlists: {
    id: 'search.filter.playlists',
    defaultMessage: 'Playlists',
  },
  albums: {
    id: 'search.filter.albums',
    defaultMessage: 'Albums',
  },
  events: {
    id: 'search.filter.events',
    defaultMessage: 'Events',
  },
});

const SearchFilters = ({ isValidQuery, filters, onFilterSelect, intl, activeFilter }: Props) => {
  const showFilters = filters.length > 0 || activeFilter;

  if (!isValidQuery || !showFilters) {
    return null;
  }

  return (
    <ul className={styles.filters}>
      <button
        className={classnames(styles.close, {
          [styles.hide]: !activeFilter,
        })}
        onClick={() => onFilterSelect(null)}
      >
        <CapacitorRipple />
        <IconLabel name="close" title="Close" />
      </button>
      {(activeFilter ? [activeFilter] : filters).map(filter => (
        <button
          key={filter}
          className={classnames(styles.filter, {
            [styles.hide]: activeFilter && activeFilter !== filter,
            [styles.inactive]: !activeFilter,
            [styles.active]: activeFilter === filter,
          })}
          onClick={() => onFilterSelect(filter)}
        >
          <CapacitorRipple version="center" />
          {intl.formatMessage(messages[filter])}
        </button>
      ))}
    </ul>
  );
};

export default injectIntl(SearchFilters);
