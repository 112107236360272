import { defineMessages } from 'react-intl';

const messages = defineMessages({
  '_idagio_authorised': {
    id: 'consent-manager.cookie-purpose._idagio_authorised',
    defaultMessage:
      'This cookie helps us identify if you have an active user session in order to redirect you to the web application.',
  },
  '_idagio_session': {
    id: 'consent-manager.cookie-purpose._idagio_session',
    defaultMessage: 'We use this cookie to keep you logged in to your IDAGIO account.',
  },
  '_idagio_tracking_token': {
    id: 'consent-manager.cookie-purpose._idagio_tracking_token',
    defaultMessage: 'This cookie uniquely identifies you to our analytics services.',
  },
  'geo-redirected': {
    id: 'consent-manager.cookie-purpose.geo-redirected',
    defaultMessage:
      'This cookie is used to identify whether or not you have been redirected to a localised version of our app based on your location.',
  },
  'tracking-preferences': {
    id: 'consent-manager.cookie-purpose.tracking-preferences',
    defaultMessage: 'This cookie is used to store your cookie preferences.',
  },
  'device-id-v2': {
    id: 'consent-manager.cookie-purpose.device-id-v2',
    defaultMessage:
      'With this cookie, we check if you have already started playback on another device with your IDAGIO account.',
  },
  '_stripe_mid': {
    id: 'consent-manager.cookie-purpose._stripe_mid',
    defaultMessage:
      'WIth this cookie, our payment processing service Stripe is able to process all payments for orders via our website.',
  },
  '_stripe_sid': {
    id: 'consent-manager.cookie-purpose._stripe_sid',
    defaultMessage:
      'WIth this cookie, our payment processing service Stripe is able to process all payments for orders via our website.',
  },
  'ajs_anonymous_id': {
    id: 'consent-manager.cookie-purpose.ajs_anonymous_id',
    defaultMessage:
      'These cookies are used for Analytics and help us count how many people visit a certain site by tracking if you have visited before.',
  },
  'ajs_user_id': {
    id: 'consent-manager.cookie-purpose.ajs_user_id',
    defaultMessage:
      'This cookie helps track your usage, events, target marketing, and also measures website performance and stability.',
  },
  '_ga': {
    id: 'consent-manager.cookie-purpose._ga',
    defaultMessage:
      'This stores the anonymous userid used by Google Analytics, which we use to anonymously identify visitors in order to measure the update of information and potential conversion to registered users.',
  },
  '_gid': {
    id: 'consent-manager.cookie-purpose._gid',
    defaultMessage:
      'With this cookie, we can count and track the amount of page views on our sites.',
  },
  '_gat': {
    id: 'consent-manager.cookie-purpose._gat',
    defaultMessage: 'Used to manage the rate at which page view requests are made.',
  },
  'amplitude_id_{token}': {
    id: 'consent-manager.cookie-purpose.amplitude_id_{token}',
    defaultMessage: 'This cookie helps us to identify unique users between session and devices.',
  },
  'amplitude_id{token}idagio.com': {
    id: 'consent-manager.cookie-purpose.amplitude_id{token}idagio.com',
    defaultMessage: 'This cookie helps us to identify unique users between session and devices.',
  },
  'ab.storage.deviceId.{deviceId}': {
    id: 'consent-manager.cookie-purpose.ab.storage.deviceId.{deviceId}',
    defaultMessage:
      'Used by our use of Braze to manage your account, personalize your experience on our website, and for marketing purposes',
  },
  'ab.storage.sessionId.{sessionId}': {
    id: 'consent-manager.cookie-purpose.ab.storage.sessionId.{sessionId}',
    defaultMessage:
      'Used by our use of Braze to manage your account, personalize your experience on our website, and for marketing purposes',
  },
  'ab.storage.userId.{userId}': {
    id: 'consent-manager.cookie-purpose.ab.storage.userId.{userId}',
    defaultMessage:
      'Used by our use of Braze to manage your account, personalize your experience on our website, and for marketing purposes',
  },
  '_fbp': {
    id: 'consent-manager.cookie-purpose._fbp',
    defaultMessage:
      "This cookie may or may not be set by the pixel depending on your uses. It is used by Facebook to register and report your actions after viewing or clicking one of Facebook's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to you.",
  },
  '_uetsid': {
    id: 'consent-manager.cookie-purpose._uetsid',
    defaultMessage:
      'This cookie helps Bing to deliver adverts more relevant to you and your interests and is also used to limit the number of time you see an advertisement',
  },
  '_uetvid': {
    id: 'consent-manager.cookie-purpose._uetvid',
    defaultMessage:
      'This cookie helps Bing to deliver adverts more relevant to you and your interests and is also used to limit the number of time you see an advertisement',
  },
});

export const cookies = [
  {
    name: '_idagio_authorised',
    message: messages._idagio_authorised,
    owner: 'IDAGIO',
    duration: 'N/A, session cookie',
    type: 'Session',
    domain: '.idagio.com',
    category: 'necessary',
  },
  {
    name: '_idagio_session',
    message: messages._idagio_session,
    owner: 'IDAGIO',
    duration: '6 months',
    type: 'HTTP',
    domain: 'app.idagio.com',
    category: 'necessary',
  },
  {
    name: '_idagio_tracking_token',
    message: messages._idagio_tracking_token,
    owner: 'IDAGIO',
    duration: '6 months',
    type: 'HTTP',
    domain: 'app.idagio.com',
    category: 'necessary',
  },
  {
    name: 'geo-redirected',
    message: messages['geo-redirected'],
    owner: 'IDAGIO',
    duration: 'N/A, session cookie',
    type: 'Sesion',
    domain: 'app.idagio.com',
    category: 'necessary',
  },
  {
    name: 'tracking-preferences',
    message: messages['tracking-preferences'],
    owner: 'IDAGIO',
    duration: '1 year',
    type: 'HTTP',
    domain: 'app.idagio.com',
    category: 'necessary',
  },
  {
    name: 'device-id-v2',
    message: messages['device-id-v2'],
    owner: 'IDAGIO',
    duration: '100 years',
    type: 'HTTP',
    domain: 'app.idagio.com',
    category: 'necessary',
  },
  {
    name: '_stripe_mid',
    message: messages._stripe_mid,
    owner: 'Stripe',
    duration: '1 year',
    type: 'HTTP',
    domain: '.app.idagio.com',
    category: 'necessary',
  },
  {
    name: '_stripe_sid',
    message: messages._stripe_sid,
    owner: 'Stripe',
    duration: '1 day',
    type: 'HTTP',
    domain: '.app.idagio.com',
    category: 'necessary',
  },
  {
    name: 'ajs_anonymous_id',
    message: messages.ajs_anonymous_id,
    owner: 'Segment',
    duration: '1 year',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: 'ajs_user_id',
    message: messages.ajs_user_id,
    owner: 'Segment',
    duration: '1 year',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: '_ga',
    message: messages._ga,
    owner: 'Google',
    duration: '2 years',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: '_gid',
    message: messages._gid,
    owner: 'Google',
    duration: '24 hours',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: '_gat',
    message: messages._gat,
    owner: 'Google',
    duration: '1 minute',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: 'amplitude_id_{token}',
    message: messages['amplitude_id_{token}'],
    owner: 'Amplitude',
    duration: '10 years',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: 'amplitude_id{token}idagio.com',
    message: messages['amplitude_id{token}idagio.com'],
    owner: 'Amplitude',
    duration: 'N/A, session cookie',
    type: 'Sesion',
    domain: '.idagio.com',
    category: 'marketingAndAnalytics',
  },
  {
    name: 'ab.storage.deviceId.{deviceId}',
    message: messages['ab.storage.deviceId.{deviceId}'],
    owner: 'Braze',
    duration: '1 year',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'functional',
  },
  {
    name: 'ab.storage.sessionId.{sessionId}',
    message: messages['ab.storage.sessionId.{sessionId}'],
    owner: 'Braze',
    duration: '1 year',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'functional',
  },
  {
    name: 'ab.storage.userId.{userId}',
    message: messages['ab.storage.userId.{userId}'],
    owner: 'Braze',
    duration: '1 year',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'functional',
  },
  {
    name: '_fbp',
    message: messages._fbp,
    owner: 'Facebook',
    duration: '3 months',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'advertising',
  },
  {
    name: '_uetsid',
    message: messages._uetsid,
    owner: 'Bing',
    duration: '1 day',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'advertising',
  },
  {
    name: '_uetvid',
    message: messages._uetvid,
    owner: 'Bing',
    duration: '15 days',
    type: 'HTTP',
    domain: '.idagio.com',
    category: 'advertising',
  },
];
