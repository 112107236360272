import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import TooltipHandle from '../common/TooltipHandle';

import styles from './PaymentForm.css';

export default class SEPANotice extends PureComponent {
  render() {
    return (
      <span>
        <TooltipHandle tooltipId="trial-charge" />
        <ReactTooltip className={styles.tooltip} id="trial-charge" effect="solid">
          <FormattedMessage
            id="account.payment.card-number-tooltip"
            defaultMessage="Although there is no initial charge, we use your payment information to determine your country and ensure you can seamlessly remain subscribed to IDAGIO when your trial ends. If you cancel before your free trial ends, you won’t be charged."
          />
        </ReactTooltip>
      </span>
    );
  }
}
