import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import ConsentManagerBuilder from '../consent-manager/ConsentManagerBuilder';
import ConsentManager from '../consent-manager/ConsentManager';

import * as uiActions from '../../actions/ui';
import { selectOpenModalTypeIs } from '../../selectors/modals';

class ConsentManagerModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, hideModal } = this.props;

    // The ConsentManagerBuilder needs to be a parent of the modal component
    // because it has an internal loading state, which returns null. That means
    // we would show an ugly empty modal during that time if the modal wrapper
    // the ConsentManagerBuilder.
    return (
      <ConsentManagerBuilder>
        {({ preferences, setPreferences, saveConsent, onAccept, hasError }) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={() => false}
            onClose={hideModal}
            contentLabel="gdpr-opt-out-modal"
            shouldCloseOnOverlayClick={false}
            hideCloseButton
          >
            <ConsentManager
              hasError={hasError}
              onAccept={onAccept}
              hideModal={hideModal}
              setPreferences={setPreferences}
              saveConsent={saveConsent}
              preferences={preferences}
            />
          </Modal>
        )}
      </ConsentManagerBuilder>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectOpenModalTypeIs(state, 'CONSENT_MANAGER_MODAL'),
  };
}

export default connect(mapStateToProps, {
  hideModal: uiActions.hideModal,
})(ConsentManagerModal);
