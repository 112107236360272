import React from 'react';
import PropTypes from 'prop-types';
import ConsentManagerInner from './ConsentManagerInner';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import styles from './ConsentManagerInner.css';
import { CONSTANT_MESSAGES } from '../../constants';

const messages = defineMessages({
  errorDetails: {
    id: 'consent-manager.error.details',
    defaultMessage: 'Learn more',
  },
});

class ConsentManager extends React.Component {
  static propTypes = {
    hideModal: PropTypes.func,
    setPreferences: PropTypes.func.isRequired,
    saveConsent: PropTypes.func.isRequired,
    preferences: PropTypes.object.isRequired,
    onAccept: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    intl: intlShape,
  };

  renderError() {
    const text = this.props.intl.formatMessage(messages.errorDetails);
    const ariaLink = this.props.intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
      linkText: text,
    });

    return (
      <div className={styles.narrow}>
        <div className={styles.header}>
          <h1 className="fz--beta">
            <FormattedMessage
              id="consent-manager.error.title"
              defaultMessage="It looks like some of our content is blocked by your browser"
            />
          </h1>
        </div>
        <div>
          <p>
            <FormattedMessage
              id="consent-manager.error.description"
              defaultMessage="To ensure the functionality of our website and continue using IDAGIO, we kindly ask you to make some settings changes."
            />
          </p>
        </div>
        <div className={styles.footer}>
          <button className="c-btn c-btn--is-purple" onClick={this.props.onAccept}>
            <FormattedMessage id="consent-manager.error.accept" defaultMessage="Got it" />
          </button>
          <a
            href="http://support.idagio.com/articles/4854959-how-content-blocking-affects-idagio-com"
            className="c-text-link--is-visible"
            rel="noopener noreferrer"
            target="_blank"
            aria-label={ariaLink}
          >
            {text}
          </a>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.hasError) {
      return this.renderError();
    }

    return (
      <ConsentManagerInner
        hideModal={this.props.hideModal}
        setPreferences={this.props.setPreferences}
        saveConsent={this.props.saveConsent}
        preferences={this.props.preferences}
      />
    );
  }
}

export default injectIntl(ConsentManager);
