// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './AccountStep.css';
import Signup from '../common/Signup';

type OwnProps = {
  expanded: boolean,
  onSignupSuccess?: Function,
  fromId: string,
  subheader?: React$Element<typeof FormattedMessage>,
  onClickLoginLink?: Function,
};

type Props = OwnProps;

const AccountStep = ({ expanded, fromId, subheader, onSignupSuccess, onClickLoginLink }: Props) => {
  if (!expanded) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Signup
        onAuthComplete={onSignupSuccess}
        fromId={fromId}
        subheader={subheader}
        onClickLoginLink={onClickLoginLink}
      />
    </div>
  );
};

export default AccountStep;
