import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectUserHasAccessToEvent,
  selectUserHasValidTicketForEvent,
  selectUserCanPlaybackEvent,
  selectDisableTicketPurchase,
  selectShouldShowPrice,
  selectShouldShowVideo,
  selectUserCanPurchaseEvent,
} from '../selectors/livestreamEvents';
import { selectUserCanSubscribe, selectUserIsAuthenticated } from '../selectors/user';
import * as meActions from '../actions/me';
import * as uiActions from '../actions/ui';
import * as analyticsActions from '../actions/analytics';
import * as collectionActions from '../actions/collection';
import { ENTITY_TYPE_LIVESTREAM_EVENT, PLAN_ID_PPC_12M } from '../constants';
import { selectUserCanAccessSubscribeView } from '../selectors/subscription';
import { selectIsInApp } from '../selectors/client';
import { NavigationPlugin } from '../capacitor-connector';

export const livestreamEventComponentPropTypes = {
  userCanPlaybackEvent: PropTypes.bool.isRequired,
  userHasTicket: PropTypes.bool.isRequired,
  userHasAccess: PropTypes.bool.isRequired,
  ticketPurchaseIsDisabled: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  shouldShowPrice: PropTypes.bool.isRequired,
  shouldShowVideo: PropTypes.bool.isRequired,
  userCanPurchaseEvent: PropTypes.bool.isRequired,
  userCanAccessSubscribeView: PropTypes.bool.isRequired,
  track: PropTypes.func.isRequired,
  isInApp: PropTypes.bool.isRequired,
  userCanSubscribe: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { event } = ownProps;

  return {
    userCanPlaybackEvent: selectUserCanPlaybackEvent(state, event),
    userHasTicket: selectUserHasValidTicketForEvent(state, event),
    userHasAccess: selectUserHasAccessToEvent(state, event),
    ticketPurchaseIsDisabled: selectDisableTicketPurchase(state, event),
    isAuth: selectUserIsAuthenticated(state),
    shouldShowPrice: selectShouldShowPrice(state, event),
    shouldShowVideo: selectShouldShowVideo(state, event),
    userCanPurchaseEvent: selectUserCanPurchaseEvent(state, event),
    userCanAccessSubscribeView: selectUserCanAccessSubscribeView(state),
    isInApp: selectIsInApp(state),
    userCanSubscribe: selectUserCanSubscribe(state),
  };
}

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function livestreamEventComponent(options = {}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class LivestreamEventComponent extends Component {
      static propTypes = livestreamEventComponentPropTypes;

      onOrderComplete = order => {
        const { onOrderComplete, event } = this.props;
        if (onOrderComplete) {
          // Track it
          onOrderComplete(order, event);
        }

        // Reload the user object
        this.props.loadMe();
        this.props.toggleInCollection(ENTITY_TYPE_LIVESTREAM_EVENT, event.id, 'Livestream Event');
      };

      onPurchaseClick = e => {
        const { contextType, track, isAuth, isInApp } = this.props;
        const { setFromIdIfNoOffer, showModal } = this.props;

        if (!isInApp && !isAuth) {
          e.preventDefault();
          setFromIdIfNoOffer(event.isCourse ? 'course-ticket-purchase' : 'gch-ticket-purchase');
          showModal('LOGIN_MODAL', { trigger: 'livestreamEvent' });
        }
        track('Clicked CTA Button: Ticket Purchase', {
          contextType: contextType,
          contextId: event.slug,
          liveEvent: event.slug,
          liveStreamEventType: event.type,
        });
      };

      onSignupClick = e => {
        const { isInApp, isAuth, setFromIdIfNoOffer, showModal, track } = this.props;
        if (!isInApp && !isAuth) {
          e.preventDefault();
          setFromIdIfNoOffer('event-free-access');
          showModal('SIGNUP_MODAL', { trigger: 'livestreamEvent' });
        }

        if (__CAPACITOR__) {
          e.preventDefault();
          NavigationPlugin.androidShouldOpenSettings();
        }

        track('Clicked CTA Button: Create Account', {
          contextType: 'livestreamEvent',
          contextId: event.slug,
          liveEvent: event.slug,
          liveStreamEventType: event.type,
        });
      };

      onSubscribeClick = e => {
        const { track, event, userCanSubscribe, showModal, isInApp } = this.props;

        if (!isInApp && userCanSubscribe) {
          e.preventDefault();
          showModal('SUBSCRIBE_MODAL', { trigger: 'livestreamEvent' }, { plan: PLAN_ID_PPC_12M });
        }

        // On Capacitor, we ignore the deeplinks and open the native settings
        if (__CAPACITOR__) {
          e.preventDefault();
          NavigationPlugin.androidShouldOpenSettings();
        }

        track('Clicked CTA Button: Subscribe', {
          contextType: 'livestreamEvent',
          contextId: event.slug,
          liveEvent: event.slug,
          liveStreamEventType: event.type,
        });
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            onOrderComplete={this.onOrderComplete}
            onPurchaseClick={this.onPurchaseClick}
            onSubscribeClick={this.onSubscribeClick}
            onSignupClick={this.onSignupClick}
            ref={withRef ? 'wrappedInstance' : null}
          />
        );
      }
    }

    LivestreamEventComponent.displayName = `LivestreamEventComponent(${getDisplayName(
      WrappedComponent
    )}`;

    LivestreamEventComponent.WrappedComponent = WrappedComponent;

    return connect(mapStateToProps, {
      showModal: uiActions.showModal,
      track: analyticsActions.trackIdagioLive,
      loadMe: meActions.loadMe,
      toggleInCollection: collectionActions.toggleInCollection,
      setFromIdIfNoOffer: meActions.setFromIdIfNoOffer,
    })(LivestreamEventComponent);
  };
}
