import { createSelector } from 'reselect';
import { partition } from 'lodash';

import { selectEntityIsLoaded } from '../lib/entities';
import {
  DISCOVER_GROUP_TYPE_BANNER,
  DISCOVER_GROUP_ID_FEATURED_EVENTS,
  DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP,
} from '../constants';
import {
  magazine,
  recentlyPlayed,
  spotlight,
  weeklyMixes,
  idagioExclusives,
  playlistsForYou,
} from '../lib/hotlinks';
import { selectLocale } from './client';
import { selectUserIsAuthenticated } from './user';

export const getItemContent = ({ content }) => content;
export const getItemType = ({ content }) => content.type;

export const selectDiscoverPage = (state, page) => {
  return state.maps.discoverPages[page].entities;
};

const isBannerGroup = (group, isHomePage, hasBanner) => {
  const isLivestreamEventGroup = group.type === DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP;
  const isInvertedGroup = isLivestreamEventGroup && isHomePage;
  return (
    hasBanner &&
    !isInvertedGroup &&
    (group.type === DISCOVER_GROUP_TYPE_BANNER || group.id === DISCOVER_GROUP_ID_FEATURED_EVENTS)
  );
};

export const selectDiscoverPageResult = createSelector(
  [
    selectDiscoverPage,
    (state, page, isHomePage) => isHomePage,
    (state, page, isHomePage, hasBanner) => hasBanner,
  ],
  (discoverPage, isHomePage, hasBanner) => {
    const [heroGroups, nonHeroGroups] = partition(discoverPage.groups, group =>
      isBannerGroup(group, isHomePage, hasBanner)
    );
    return {
      ...discoverPage,
      heroGroup: heroGroups[0],
      nonHeroGroups,
    };
  }
);

export function selectDiscoverGroupItemIsLoaded(state, discoverGroupItem) {
  const item = getItemContent(discoverGroupItem);
  const type = getItemType(discoverGroupItem);

  return selectEntityIsLoaded(state, type, item);
}

export function selectDiscoverGroupItems(state, type, slug, filter) {
  const compoundKey = [type, slug, filter].filter(i => i).join('--');
  return state.maps.discoverGroups[compoundKey].entities;
}

const isGroupFound = (state, page, groupId) => {
  const { groups } = selectDiscoverPage(state, page);
  if (!groups) {
    return false;
  }
  return groups.some(group => group.id === groupId);
};

// This hardcoding should be temporary. In the long term, we are supposed to get this from backend.
// The background: because of the backend consolidation project, product wanted to add this new feature
// without involving backend as we do not know yet how useful the hotlinks are.
export const selectHotlinkItems = state => {
  const locale = selectLocale(state);
  return [
    magazine(locale),
    selectUserIsAuthenticated(state) ? recentlyPlayed : spotlight,
    isGroupFound(state, 'home', 'mixes-main') ? weeklyMixes : idagioExclusives,
    playlistsForYou,
  ];
};
