// @flow
import * as React from 'react';
import styles from './LivestreamEventImage.css';
import { IMAGES_BASE, LIVESTREAM_EVENT_IMAGE_DIMS } from '../../constants';
import BookedTag from '../livestream-event-preview/BookedTag';
import UpcomingTag from '../livestream-event-preview/UpcomingTag';
import livestreamEventComponent from '../../hoc/livestreamEventComponent';
import classNames from 'classnames';

type OwnProps = {
  event: Object,
  userHasTicket: boolean,
  children?: React.ChildrenArray<React.Element<any>>,
  isHeroItem?: boolean,
  isSliderItem?: boolean,
};

const LivestreamEventImage = ({
  event,
  userHasTicket,
  children,
  isHeroItem,
  isSliderItem,
}: OwnProps) => {
  const { imageUrl, isUpcoming } = event;

  const src = imageUrl
    ? `${imageUrl}?fm=jpg&fl=progressive&fit=thumb&f=top_left&h=${LIVESTREAM_EVENT_IMAGE_DIMS.h}&w=${LIVESTREAM_EVENT_IMAGE_DIMS.w}`
    : `${IMAGES_BASE}/assets/web/live-fallback.jpg`;

  return (
    <figure role="none" className={styles.figure}>
      <img
        src={src}
        role="img"
        className={classNames(styles.image, {
          [styles.heroImage]: isHeroItem,
          [styles.sliderImage]: isSliderItem,
        })}
      />
      {userHasTicket && <BookedTag />}
      {isUpcoming && <UpcomingTag />}
      {children}
    </figure>
  );
};

export default livestreamEventComponent()(LivestreamEventImage);
