// @flow
// $FlowFixMe
import React, { useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import LoadingIndicator from '../common/LoadingIndicator';

import styles from './PaymentFormSubmitButton.css';

type OwnProps = {
  isLoading: boolean,
  formIsReadyForSubmission: boolean,
  userIsAllowedOptOutTrial?: boolean,
  trialDuration: number,
};

type Props = OwnProps;

const PaymentFormSubmitButton = ({
  isLoading,
  formIsReadyForSubmission,
  userIsAllowedOptOutTrial,
  trialDuration,
}: Props) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (formIsReadyForSubmission) {
      buttonRef.current.focus();
    }
  }, [formIsReadyForSubmission]);

  const ButtonLabel = () => {
    if (!userIsAllowedOptOutTrial) {
      return (
        <FormattedMessage
          id="subscribe-modal.payment-details.renew"
          defaultMessage="Renew your subscription"
          data-test="payment-form.submit-btn.renew"
        />
      );
    }

    if (trialDuration > 0) {
      return (
        <span data-test="payment-form.submit-btn.start-trial">
          <FormattedMessage
            id="global.cta.trial-free-days"
            defaultMessage="Start my {trialDuration}-day free trial"
            values={{ trialDuration }}
          />
        </span>
      );
    }

    return <FormattedMessage id="global.cta.trial-no" defaultMessage="Subscribe" />;
  };

  const buttonClassName = classnames('c-btn c-btn--is-purple', styles.button, {
    [styles.isLoading]: isLoading,
  });

  return (
    <React.Fragment>
      <button
        className={buttonClassName}
        disabled={!formIsReadyForSubmission || isLoading}
        ref={buttonRef}
        data-test="payment-form.submit-btn"
        type="submit"
      >
        <LoadingIndicator isLoading={isLoading} className={styles.loader} />

        <span className="payment-form__button-text">
          <ButtonLabel />
        </span>
      </button>
    </React.Fragment>
  );
};

export default PaymentFormSubmitButton;
