import { capitalize, forEach, keyBy } from 'lodash';
import {
  ANALYTICS_DELAYED_COMPLETED_SIGNUP,
  FINISHED_TRACK,
  QUEUE_TYPE_ALBUM,
  QUEUE_TYPE_MIX,
  QUEUE_TYPE_MOOD,
  QUEUE_TYPE_PLAYLIST,
  QUEUE_TYPE_RECORDING,
} from '../constants';
import { REQUEST, SUCCESS } from './api';
import assembleEntity from '../schema/assembleEntity';
import { Track } from '../schema';

import {
  selectPlayerCurrentQueueItem,
  selectPlayerState,
  selectRepeatAll,
  selectRepeatOne,
  selectShuffleRecordings,
  selectShuffleTracks,
  selectTrackingData,
  selectAudioQuality,
  selectProgress,
} from '../selectors/player';

import {
  selectQuickSearchActiveFilter,
  selectQuickSearchIsLoaded,
  selectQuickSearchQuery,
  selectQuickSearchResults,
  selectQuickSearchResultsLength,
  selectSearchActiveFilter,
  selectSearchIsLoaded,
  selectSearchQuery,
  selectSearchResults,
  selectSearchResultsLength,
} from '../selectors/search';
import {
  selectUserAgent,
  selectCurrentRoutePath,
  selectLocale,
  selectPageTrackingContext,
  selectIsIOS,
  selectIsAndroid,
  selectIsInApp,
  selectElectronClientVersion,
  selectWebappClientVersion,
} from '../selectors/client';

import { selectSonosCloudQueueId } from '../selectors/sonos';

import { selectUserIPCountry } from '../selectors/user';

import { getEntityTypeFromQueueType } from '../lib/queue';
import { emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import { selectOfferTrackingName } from '../selectors/subscriptionOffer';
import { DELIMITER } from '../reducers/analyticsProgressBuffer';

import { setPageTrackingContext } from '../actions/ui';
import { selectURNForRecentlyPlayedServiceFromQueueOrigin } from '../selectors/recentlyPlayed';
import { LIVE_COMPARE_PHASE_BEGIN } from '../actions/liveCompare/tracking';
import { flushAnalyticsProgressBuffer } from '../actions/analytics';

let globalTrackOptions;

function track(event, props) {
  analytics.track(event, props, globalTrackOptions);
}

function recordStopOfTrack(properties) {
  track('Stopped Playback', properties);
}

/*
  While the following part is not directly related to the Redux middleware
  implementation, we thought that this would be the best place to register
  these global event handlers for `react-ab-test`
 */
if (__CLIENT__) {
  emitter.addPlayListener((experimentName, variantName) => {
    track('Experiment Viewed', {
      experimentName,
      variantName,
    });
  });

  emitter.addWinListener((experimentName, variantName) => {
    track('Experiment Win', {
      experimentName,
      variantName,
    });
  });

  if (process.env.ENV === 'development') {
    experimentDebugger.enable();
  }
}

function trackProgressUpdate(analyticsProgressBuffer, trackingData, state) {
  const isIos = selectIsIOS(state);
  const isAndroid = selectIsAndroid(state);
  const isInApp = selectIsInApp(state);
  forEach(analyticsProgressBuffer, (duration, key) => {
    const [source, ...rest] = key.split(DELIMITER);
    if (source === 'ad') {
      const [adId] = rest;
      track('Updated Ad Playback Duration', {
        adId,
        duration,
        ...trackingData,
      });
      return;
    }

    if (source === 'livestream') {
      const [eventSlug, eventType, isLive] = rest;
      track('Updated Video Playback Duration', {
        liveEvent: eventSlug,
        liveEventType: `cf.livestreamEvent.${eventType}`,
        duration: Math.round(duration.accumulatedDuration),
        isLive,
        currentPlaybackTime: duration.timestamp,
        ...trackingData,
        isIos,
        isAndroid,
        isInApp,
        context: 'livestreamEvent',
        contextId: eventSlug,
      });
      return;
    }

    const [audioQuality, trackId, trackPosition, repeat, shuffle] = rest;

    track('Updated Playback Duration', {
      duration,
      source,
      audioQuality,
      trackId,
      trackPosition: parseInt(trackPosition, 10) + 1,
      repeat,
      shuffle,
      ...trackingData,
    });
  });
}

function getSearchSelectionType(item) {
  const type = item.type;
  if (type === 'ensemble') {
    return 'Performer';
  }
  if (type === 'person') {
    if (item.composer) {
      return 'Composer';
    }
    return 'Performer';
  }
  return capitalize(item.type);
}

function getSearchSection(section) {
  if (section === 'top') {
    return 'tophits';
  }

  return section;
}

function trackSearch(selected = false, searchResults, query, activeFilter, section) {
  if (searchResults.some(result => result.id === 'music')) {
    return;
  }

  const resultsCounts = keyBy(
    searchResults
      .filter(({ id, results }) => results && id)
      .map(({ id, results }) => ({ id, length: results.length })),
    'id'
  );

  const { compositions, recordings, artists, albums, playlists, events } = resultsCounts;
  const flattenedResults = searchResults.reduce((accumulator, value) => {
    if (value.id === 'top') {
      return accumulator; // We ignore tophits results in the total count
    }
    return [...accumulator, ...value.results];
  }, []);

  const trackingData = {
    searchedTerm: query,
    searchType: 'text_search',
    resultsCount: flattenedResults.length,
    resultsCountArtists: artists.length,
    resultsCountRecordings: recordings.length,
    resultsCountCompositions: compositions.length,
    resultsCountAlbums: albums.length,
    resultsCountPlaylists: playlists.length,
    resultsCountEvents: events.length,
    filter: activeFilter || 'none',
  };

  if (selected) {
    const position = flattenedResults.indexOf(selected) + 1; // Because the tracking spec is not zero indexed
    track('Selected Search Result', {
      ...trackingData,
      selectedId: selected.id,
      selectedType: getSearchSelectionType(selected),
      selectedSection: getSearchSection(section),
      selectedPosition: position,
    });
  }

  track('Closed Search', {
    ...trackingData,
    successful: !!selected,
  });
}

function getDurationOfTrack(trackId, entities) {
  const trackInfo = assembleEntity(Track, entities, trackId);
  return trackInfo.duration;
}

function getAnalyticsRepeatValue(state) {
  const one = selectRepeatOne(state);
  const all = selectRepeatAll(state);

  if (one) {
    return 'one';
  }

  if (all) {
    return 'all';
  }

  return 'none';
}

function getAnalyticsShuffleValue(state) {
  const shuffleRecordings = selectShuffleRecordings(state);
  const shuffleTracks = selectShuffleTracks(state);

  if (shuffleRecordings) {
    return 'recording';
  }

  if (shuffleTracks) {
    return 'track';
  }

  return 'off';
}

function doDelayedSignupTracking(trackingName) {
  let actionProperties = {};

  if (trackingName) {
    actionProperties = {
      promotion: trackingName,
    };
  }

  setTimeout(() => track(ANALYTICS_DELAYED_COMPLETED_SIGNUP, actionProperties), 2000);
}

let lastTimeToPlayTimeStart;

export default store => {
  if (!__SERVER__) {
    setInterval(() => store.dispatch(flushAnalyticsProgressBuffer()), 10000);
  }
  return next => action => {
    const prevState = store.getState();
    next(action);
    const state = store.getState();

    const phase = action.phase;

    const prevPlayerState = selectPlayerState(prevState);
    const currentPlayerState = selectPlayerState(state);

    const locale = selectLocale(state);

    const previousTrack =
      prevPlayerState.currentQueueItem && prevPlayerState.currentQueueItem.track;
    const currentTrack =
      currentPlayerState.currentQueueItem && currentPlayerState.currentQueueItem.track;

    const audioQuality = state.client.preferences.audioQuality;
    const queueOrigin = currentPlayerState.queueOrigin;

    const trackingData = selectTrackingData(state);
    const userTrackingData = { playbackCountry: selectUserIPCountry(state) };

    const queueItem = selectPlayerCurrentQueueItem(state);
    const trackPosition = selectPlayerState(state).queueItems.indexOf(queueItem) + 1;

    const name = __ELECTRON__ ? 'Desktop' : 'Web';
    const version = (__ELECTRON__ ? selectElectronClientVersion : selectWebappClientVersion)(state);
    globalTrackOptions = {
      context: {
        app: { version, name },
        ip: '0.0.0.0',
      },
    };

    switch (action.type) {
      case '@@analytics/track':
        track(action.event, action.properties);
        break;
      case '@@analytics/identify':
        if (analytics.user) {
          if (action.userId !== analytics.user().id()) {
            analytics.alias(action.userId, analytics.user().id(), globalTrackOptions);
          }
        }

        analytics.identify(action.userId, action.traits, globalTrackOptions);
        break;
      case '@@analytics/transition': {
        const { location, components } = action;
        const lastComponent = components[components.length - 1];

        const eventProps = components.reduce((acc, component) => {
          const currentProps = component.trackingProps;
          return {
            ...acc,
            ...currentProps,
          };
        }, {});

        if (__ELECTRON__) {
          eventProps.path = location.pathname;
          eventProps.url = '';
        }

        store.dispatch(setPageTrackingContext(eventProps));

        analytics.page(lastComponent.displayName, eventProps, globalTrackOptions);
        break;
      }
      case 'SET_PAGE_VISIBILITY':
        if (state.client.pageVisible) {
          track('Became Foreground');
        } else {
          track('Became Background');
        }
        break;
      case 'PLAYER_QUEUE_SET':
        switch (queueOrigin && queueOrigin.type) {
          case QUEUE_TYPE_MOOD:
            track('Mood Selected', {
              moodId: queueOrigin.id,
              mood: queueOrigin.name,
            });
            break;
          case QUEUE_TYPE_RECORDING: {
            const { workId, recordingId } = queueOrigin;
            track('Work Selected', {
              workId,
              recordingId,
            });
            break;
          }
          case QUEUE_TYPE_ALBUM:
          case QUEUE_TYPE_PLAYLIST:
          case QUEUE_TYPE_MIX:
            track(`${capitalize(getEntityTypeFromQueueType(queueOrigin.type))} Selected`, {
              slug: queueOrigin.id,
            });
            break;
          default:
            break;
        }
        break;
      case 'PLAYER_QUEUE_RESET':
        recordStopOfTrack({
          trackId: previousTrack,
          currentPlaybackTime: prevPlayerState.progress,
          duration: getDurationOfTrack(previousTrack, prevState.entities),
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          reason: 'Finished',
          audioQuality,
          ...trackingData,
          ...userTrackingData,
        });
        break;
      case 'FETCH_QUICK_SEARCH_RESULTS':
      case 'SET_QUICK_SEARCH_ACTIVE_FILTER':
        if (selectQuickSearchIsLoaded(state) && selectQuickSearchResultsLength(state) < 1) {
          track('No results', {
            searchedTerm: selectQuickSearchQuery(state),
            filter: selectQuickSearchActiveFilter(state) || 'none',
          });
        }
        break;
      case 'FETCH_SEARCH_RESULTS':
      case 'SET_SEARCH_ACTIVE_FILTER':
        if (selectSearchIsLoaded(state) && selectSearchResultsLength(state) < 1) {
          track('No results', {
            searchedTerm: selectSearchQuery(state),
            filter: selectSearchActiveFilter(state) || 'none',
          });
        }
        break;
      case 'TRACK_RESUMED_PLAYING':
        track('Resumed Playback', {
          trackId: currentTrack,
          currentPlaybackTime: currentPlayerState.progress,
          duration: getDurationOfTrack(currentTrack, state.entities),
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          audioQuality,
          ...trackingData,
          ...userTrackingData,
        });
        break;

      case 'TRACK_RESTARTED_TRACK':
        recordStopOfTrack({
          trackId: previousTrack,
          currentPlaybackTime: prevPlayerState.progress,
          duration: getDurationOfTrack(previousTrack, prevState.entities),
          reason: 'Finished',
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          audioQuality,
          ...trackingData,
          ...userTrackingData,
        });

        track('Started Playback', {
          trackId: previousTrack,
          audioQuality,
          timeToPlay: 0,
          trackPosition,
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          ...trackingData,
          ...userTrackingData,
        });
        break;
      case 'PLAYER_PLAY': {
        const { previousQueueItem } = action;
        const prevTrackId = previousQueueItem && previousQueueItem.track;
        const playingStateChanged = prevPlayerState.playing !== currentPlayerState.playing;

        if (prevTrackId && !playingStateChanged) {
          recordStopOfTrack({
            trackId: prevTrackId,
            currentPlaybackTime: prevPlayerState.progress,
            duration: getDurationOfTrack(prevTrackId, prevState.entities),
            reason: 'Changed Track',
            repeat: getAnalyticsRepeatValue(state),
            shuffle: getAnalyticsShuffleValue(state),
            audioQuality,
            ...trackingData,
            ...userTrackingData,
          });
        }
        break;
      }
      case 'PLAYER_PAUSE': {
        track('Paused Playback', {
          trackId: currentTrack,
          currentPlaybackTime: currentPlayerState.progress,
          duration: getDurationOfTrack(currentTrack, state.entities),
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          audioQuality,
          ...trackingData,
          ...userTrackingData,
        });
        break;
      }
      case 'PLAYER_NEXT':
      case 'PLAYER_PREVIOUS':
        recordStopOfTrack({
          trackId: previousTrack,
          currentPlaybackTime: prevPlayerState.progress,
          duration: getDurationOfTrack(previousTrack, state.entities),
          reason: action.reason === FINISHED_TRACK ? 'Finished' : 'Changed Track',
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          audioQuality,
          ...trackingData,
          ...userTrackingData,
        });
        break;
      case 'SEARCH_OVERLAY_SHOW':
        track('Opened Search');
        break;
      case 'SEARCH_OVERLAY_HIDE':
        trackSearch(
          false,
          selectQuickSearchResults(state),
          selectQuickSearchQuery(state),
          selectQuickSearchActiveFilter(state)
        );
        break;
      case 'COMPLETE_QUICK_SEARCH':
        trackSearch(
          action.selected,
          selectQuickSearchResults(state),
          selectQuickSearchQuery(state),
          selectQuickSearchActiveFilter(state),
          action.section
        );
        break;
      case 'COMPLETE_SEARCH':
        trackSearch(
          action.selected,
          selectSearchResults(state),
          selectSearchQuery(state),
          selectSearchActiveFilter(state),
          action.section
        );
        break;
      case 'ADD_ALBUM_TO_COLLECTION':
      case 'ADD_TRACK_TO_COLLECTION':
      case 'ADD_RECORDING_TO_COLLECTION':
      case 'ADD_PLAYLIST_TO_COLLECTION':
      case 'ADD_ARTIST_TO_COLLECTION':
      case 'ADD_LIVESTREAM_EVENT_TO_COLLECTION':
        if (phase === REQUEST) {
          track(`Added ${action.collectionType} To Collection`, {
            source: action.trackingSource,
            id: action.ids.join(','),
          });
        }
        break;
      case 'REMOVE_ALBUM_FROM_COLLECTION':
      case 'REMOVE_TRACK_FROM_COLLECTION':
      case 'REMOVE_RECORDING_FROM_COLLECTION':
      case 'REMOVE_PLAYLIST_FROM_COLLECTION':
      case 'REMOVE_ARTIST_FROM_COLLECTION':
      case 'REMOVE_LIVESTREAM_EVENT_FROM_COLLECTION':
        if (phase === REQUEST) {
          track(`Removed ${action.collectionType} From Collection`, {
            source: action.trackingSource,
            id: action.ids.join(','),
          });
        }
        break;
      case 'SEND_SUBSCRIPTION_CANCEL_FEEDBACK': {
        const { data } = action;
        track('Answered Cancellation Survey', {
          response: data.reason,
          freeUserFeedback: data.comment,
        });
        break;
      }
      case 'SHOW_MODAL': {
        const trackingContext = { ...action.trackingContext, ...selectPageTrackingContext(state) };
        switch (action.modalType) {
          case 'SUBSCRIBE_MODAL':
          case 'UPGRADE_MODAL':
            track('Get Premium Modal Triggered', trackingContext);
            break;

          case 'LOSSLESS_SUPPORT_MODAL':
            track('Selected Unsupported Audio Quality');
            break;

          case 'PREVIEWS_MODAL':
            track('Viewed Previews Modal', trackingContext);
            break;

          case 'EMBED_PREVIEWS_MODAL':
            track('Viewed EP Previews Modal', trackingContext);
            break;

          case 'FREE_COLLECTION_STATUS_MODAL':
            track('Shown Upsell Message', trackingContext);
            break;

          default:
            track('Viewed Modal', {
              ...trackingContext,
              modalType: action.modalType,
            });
            break;
        }
        break;
      }
      case 'FLUSH_ANALYTICS_PROGRESS_BUFFER': {
        const analyticsProgressBuffer = prevState.analyticsProgressBuffer;
        trackProgressUpdate(
          analyticsProgressBuffer,
          {
            ...trackingData,
            ...userTrackingData,
          },
          state
        );
        break;
      }
      case 'START_TIME_TO_PLAY_TRACKING':
        lastTimeToPlayTimeStart = Date.now();
        break;
      case 'END_TIME_TO_PLAY_TRACKING': {
        const timeToPlay = (Date.now() - lastTimeToPlayTimeStart) / 1000;
        const historyItem = selectURNForRecentlyPlayedServiceFromQueueOrigin(state, queueOrigin);

        track('Started Playback', {
          trackId: currentPlayerState.currentQueueItem.track,
          audioQuality,
          timeToPlay,
          trackPosition,
          repeat: getAnalyticsRepeatValue(state),
          shuffle: getAnalyticsShuffleValue(state),
          historyItem,
          ...trackingData,
          ...userTrackingData,
        });
        break;
      }
      case 'TRACK_APP_OPENED':
        track('Application Opened', {
          version: (__ELECTRON__ ? selectElectronClientVersion : selectWebappClientVersion)(state),
        });
        break;
      case 'TRACK_DESKTOP_INSTALLED':
        track('Application Installed', {
          version: selectElectronClientVersion(state),
        });
        break;
      case 'CANCEL_SUBSCRIPTION':
        if (phase === SUCCESS) {
          track('Canceled Subscription');
        }
        break;
      case 'TRACK_CANCEL_BUTTON_PRESSED':
        track('Pressed Cancel Subscription Button');
        break;
      case 'TRACK_PLAY_PAUSE_PRESSED': {
        const eventName = action.playing ? 'Pause Pressed' : 'Play Pressed';
        track(eventName, action.context);
        break;
      }
      case 'CPU_THRESHOLD_EXCEEDED':
        track('CPU Threshold Exceeded', {
          userAgent: selectUserAgent(state),
          appVersion: selectElectronClientVersion(state),
          playerState: selectPlayerState(state),
          audioQuality: selectAudioQuality(state),
          CPUUsage: action.value,
          lastActions: state.lastActions,
          currentPath: selectCurrentRoutePath(state),
        });
        break;
      case 'SET_SONOS_QUEUE':
        if (phase === SUCCESS) {
          track('Created Sonos Cloud Queue', {
            id: selectSonosCloudQueueId(state, action.cacheId),
          });
        }
        break;
      case 'LOGIN_SOCIAL':
        if (phase === SUCCESS && action.response.normalized.isNewUser) {
          doDelayedSignupTracking(selectOfferTrackingName(state));
        }
        break;
      case 'SIGN_UP':
        if (phase === SUCCESS && action.response.normalized.isNewUser) {
          doDelayedSignupTracking(selectOfferTrackingName(state));
        }
        break;
      case 'APPLY_COUPON':
        if (phase === SUCCESS) {
          track('Redeemed voucher code');
        }
        break;
      case 'PLAYBACK_AD_CLICKED':
        track('Selected Ad', {
          adId: action.ad.id,
          progress: selectProgress(state),
          ...trackingData,
          ...userTrackingData,
        });
        break;
      case 'STARTED_AD_PLAYBACK':
        track('Served Ad Id', {
          adId: action.adId,
          adLocale: locale,
          timestamp: Math.floor(Date.now() / 1000),
          ...trackingData,
          ...userTrackingData,
        });
        break;
      case 'TRACK_LIVE_COMPARE_SWITCH':
        if (action.phase === LIVE_COMPARE_PHASE_BEGIN) {
          recordStopOfTrack({
            trackId: action.fromTrackId,
            currentPlaybackTime: prevPlayerState.progress,
            duration: getDurationOfTrack(previousTrack, prevState.entities),
            repeat: getAnalyticsRepeatValue(state),
            shuffle: getAnalyticsShuffleValue(state),
            reason: 'Changed Track',
            audioQuality,
            ...trackingData,
            ...userTrackingData,
          });
          track('Live Compare Switch', {
            fromTrackId: action.fromTrackId,
            toTrackId: action.toTrackId,
            // crossfade or plain
            method: action.method,
            audioQuality,
            ...trackingData,
            ...userTrackingData,
          });
        } else {
          track('Started Playback', {
            trackId: action.toTrackId,
            audioQuality,
            timeToPlay: 0,
            trackPosition,
            repeat: getAnalyticsRepeatValue(state),
            shuffle: getAnalyticsShuffleValue(state),
            ...trackingData,
            ...userTrackingData,
          });
        }
        break;
      case 'LIVE_COMPARE_ERROR':
        track('Live Compare Error', {
          reason: action.reason,
          message: action.message,
          // these might not be available
          fromTrackId: action.fromTrackId,
          toTrackId: action.toTrackId,
          audioQuality,
          ...trackingData,
          ...userTrackingData,
        });
        break;
      default:
        break;
    }
  };
};
