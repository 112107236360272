import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ConversionFormVisual.css';

class ConversionFormInfo extends PureComponent {
  render() {
    return (
      <section className={styles.vizual}>
        <h1>
          <FormattedMessage
            id="welcome-modal.headline"
            defaultMessage="Classical music without limitations"
          />
        </h1>
        <p>
          <FormattedMessage
            id="welcome-modal.subheadline"
            defaultMessage="Subscribe now to get the Premium+ benefits for the Premium price"
          />
        </p>
        <ul>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12.3 20.6L6 15.8H3.2V8.2H6l6.3-4.8v17.2zm-7.5-6.4h1.8l4.1 3.2V6.6L6.6 9.8H4.8v4.4zm10.9 2.6l-1-1.3c1.1-.9 1.7-2.1 1.7-3.5s-.6-2.7-1.7-3.5l1-1.3C17.2 8.4 18 10.1 18 12s-.8 3.6-2.3 4.8zm1.3 1.7l1 1.3c2.4-1.9 3.8-4.7 3.8-7.8S20.4 6.1 18 4.2l-1 1.3c2 1.6 3.2 3.9 3.2 6.5S19 16.9 17 18.5z" />
            </svg>
            <div>
              <FormattedMessage
                id="welcome-modal.listen"
                defaultMessage="Listen in high-quality audio"
              />
              <div>
                <FormattedMessage
                  id="welcome-modal.lossless"
                  defaultMessage="Including lossless audio, exclusive to Premium+"
                />
              </div>
            </div>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M20.5.6h-17v22.7h17V.6zm-1.4 1.5H4.9V22h14.2V2.1zm-5.7 4.2c0 .8-.6 1.4-1.4 1.4-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4zm2.9 8.5c0 2.4-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3 1.9-4.3 4.3-4.3c2.4.1 4.3 2 4.3 4.3zm-1.5 0c0 1.6-1.3 2.8-2.8 2.8-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8z"
                clipRule="evenodd"
              />
            </svg>
            <FormattedMessage
              id="welcome-modal.connect"
              defaultMessage="Connect to Sonos, Burmester, Bluesound and more"
            />
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12.8 14.2V7h-1.6v7.2l-2.7-2.3-1.1 1.2 4.5 4 4.5-4-1.1-1.2-2.5 2.3zm-.8 6.2c-4.6 0-8.4-3.8-8.4-8.4 0-4.6 3.8-8.4 8.4-8.4s8.4 3.8 8.4 8.4c0 4.6-3.8 8.4-8.4 8.4zM12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z" />
            </svg>
            <FormattedMessage
              id="welcome-modal.save"
              defaultMessage="Save music for offline listening on iOS and Android devices"
            />
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M18.4 11.1l-6.4 6-6.4-5.9c-.5-.6-.8-1.4-.8-2.2 0-1.8 1.4-3.2 3.2-3.2.9 0 1.7.4 2.3.9L12 8.5l1.7-1.7c.6-.6 1.4-.9 2.3-.9 1.8 0 3.2 1.4 3.2 3.2 0 .7-.3 1.5-.8 2zM16 4.2c-1.7 0-3.1.8-4 2.2-.9-1.3-2.3-2.2-4-2.2-2.7 0-4.8 2.2-4.8 4.8 0 1.2.4 2.3 1.3 3.3l7.5 7 7.6-7c.8-.9 1.2-2 1.2-3.2 0-2.7-2.1-4.9-4.8-4.9z" />
            </svg>
            <FormattedMessage
              id="welcome-modal.build"
              defaultMessage="Build your classical music collection"
            />
          </li>
        </ul>
        <div className={styles.arrowAnim}>
          <div className={styles.arrow} />
        </div>
      </section>
    );
  }
}

export default ConversionFormInfo;
