// @flow
import React from 'react';
import PlanPriceRemark from './PlanPriceRemark';
import PlanPriceMonthly from './PlanPriceMonthly';
import styles from './PlanPrice.css';

type OwnProps = {
  monthlyPrice: number,
  totalPrice?: number,
  relativeDiscount?: number,
  shouldShowPriceRemark?: boolean,
  className?: string,
};

type Props = OwnProps;

const PlanPrice = ({
  monthlyPrice,
  totalPrice,
  relativeDiscount,
  shouldShowPriceRemark,
  className,
}: Props) => {
  return (
    <div className={className}>
      <PlanPriceMonthly monthlyPrice={monthlyPrice} relativeDiscount={relativeDiscount} />
      <div className={styles.remark}>
        <PlanPriceRemark
          totalPrice={totalPrice}
          relativeDiscount={relativeDiscount}
          shouldShowPriceRemark={shouldShowPriceRemark}
        />
      </div>
    </div>
  );
};

export default PlanPrice;
