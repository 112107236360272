// @flow
import React from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { selectBookletUrl, selectOpenModalTypeIs } from '../../selectors/modals';
import * as uiActions from '../../actions/ui';
import styles from './BookletModal.css';
import BookletViewer from '../common/BookletViewer';

type OwnProps = {};

type MapStateToProps = {
  isOpen: boolean,
  bookletUrl: string,
};

type DispatchProps = {
  hideModal: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const CourseResourcePreviewModal = ({ isOpen, hideModal, bookletUrl }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={hideModal}
      onRequestClose={hideModal}
      contentLabel="booklet-modal"
    >
      <div className={styles.modal}>
        <BookletViewer bookletUrl={bookletUrl} />
      </div>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isOpen: selectOpenModalTypeIs(state, 'BOOKLET_MODAL'),
    bookletUrl: selectBookletUrl(state),
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
};

export default connect(mapStateToProps, dispatchProps)(CourseResourcePreviewModal);
