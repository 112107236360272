// @flow
import React from 'react';
import { connect } from 'react-redux';

import { LANGUAGE_SUPPORT_LINKS } from '../../constants';

import { selectUserPlan, selectUserPlanSubscriptionType } from '../../selectors/user';

import * as analyticsActions from '../../actions/analytics';
import * as subscriptionActions from '../../actions/subscription';
import * as notificationActions from '../../actions/notifications';

import styles from './ConversionFormVisual.css';
import {
  selectSubscriptionTypeIsAndroid,
  selectSubscriptionTypeIsApple,
  selectUserCanManageSubscription,
  selectUserCanUpgrade,
} from '../../selectors/subscription';
import { selectBaseUrl, selectLocale } from '../../selectors/client';
import {
  PLEASE_WHILE_REDIRECTING,
  UPGRADE_ANDROID_SUBS,
  UPGRADE_APPLE_SUBS,
} from '../../lib/notifications';
import { splitLocale } from '../../lib/locale-utils';
import PlansOverviewUpgrade from './PlansOverviewUpgrade';
import stylesPlansOverview from './PlansOverview.css';
import UpgradeHeader from './UpgradeHeader';

type OwnProps = {
  onComplete: Function,
};

type MapStateToProps = {
  userCanUpgrade: boolean,
  userCanManageSubscription: boolean,
  isAppleSubscription: boolean,
  isAndroidSubscription: boolean,
  baseUrl: string,
  locale: string,
  currentUserPlan?: string,
  currentUserPlanSubscriptionType?: string,
};

type DispatchProps = {
  analyticsTrack: Function,
  openStripeCustomerChangePlan: Function,
  addNotification: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const UpgradeForm = ({
  userCanUpgrade,
  onComplete,
  analyticsTrack,
  userCanManageSubscription,
  isAppleSubscription,
  isAndroidSubscription,
  openStripeCustomerChangePlan,
  baseUrl,
  addNotification,
  locale,
  currentUserPlan,
  currentUserPlanSubscriptionType,
}: Props) => {
  if (!userCanUpgrade) {
    onComplete();
    return null;
  }

  const proceedToUpgrade = async targetPlan => {
    analyticsTrack('Clicked Subscription Upgrade', {
      upgradingFrom: currentUserPlan,
      upgradingTo: targetPlan,
      paymentGateway: currentUserPlanSubscriptionType,
    });

    if (userCanManageSubscription) {
      addNotification(PLEASE_WHILE_REDIRECTING);
      const returnUrl = `${baseUrl}/account`;
      const { normalized: url } = await openStripeCustomerChangePlan(returnUrl, targetPlan);
      document.location = url;
      return;
    }

    if (isAppleSubscription || isAndroidSubscription) {
      const UPGRADE_DISCLAIMER = isAppleSubscription ? UPGRADE_APPLE_SUBS : UPGRADE_ANDROID_SUBS;
      addNotification({
        ...UPGRADE_DISCLAIMER,
        onClick: () => {
          window.open(
            `${
              LANGUAGE_SUPPORT_LINKS[splitLocale(locale).language]
            }/articles/2804834-how-do-i-upgrade-my-idagio-subscription`,
            '_blank'
          );
        },
      });

      return;
    }

    return;
  };

  return (
    <div className={styles.container}>
      <UpgradeHeader />
      <div className={stylesPlansOverview.container}>
        <PlansOverviewUpgrade proceedToUpgrade={proceedToUpgrade} />
      </div>
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    userCanUpgrade: selectUserCanUpgrade(state),
    baseUrl: selectBaseUrl(state),
    userCanManageSubscription: selectUserCanManageSubscription(state),
    isAppleSubscription: selectSubscriptionTypeIsApple(state),
    isAndroidSubscription: selectSubscriptionTypeIsAndroid(state),
    locale: selectLocale(state),
    currentUserPlan: selectUserPlan(state),
    currentUserPlanSubscriptionType: selectUserPlanSubscriptionType(state),
  };
}

const dispatchProps: DispatchProps = {
  analyticsTrack: analyticsActions.track,
  openStripeCustomerChangePlan: subscriptionActions.openStripeCustomerChangePlan,
  addNotification: notificationActions.addNotification,
};

export default connect(mapStateToProps, dispatchProps)(UpgradeForm);
