// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './PlanDetails.css';

type OwnProps = {
  title?: React$Element<typeof FormattedMessage> | string,
  planPrice?: React$Element<any>,
  objectives: Array<{
    key: string,
    value: React$Element<typeof FormattedMessage> | string,
  }>,
};

type Props = OwnProps;

const PlanDetails = ({ title, planPrice, objectives }: Props) => {
  return (
    <div className={styles.container}>
      {title && (
        <h2 className="fz--gamma">
          {title}
        </h2>
      )}
      {planPrice}
      <ul className={styles.objectives}>
        {objectives.map(({ key, value }) => (
          <li key={key}>{value}</li>
        ))}
      </ul>
    </div>
  );
};

export default PlanDetails;
