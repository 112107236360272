import invariant from 'invariant';
import * as Sentry from '@sentry/react';
import { normalize } from 'normalizr';
import { flow } from 'lodash';
import makeError, { BaseError } from 'make-error';
import Qs from 'qs';
import { isSentryInstalled } from '../client/Sentry';
import { networkError } from '../actions/connection';
import { DEFAULT_LOCALE, TRACKING_TOKEN_HEADER_NAME } from '../constants';
import RedirectInstruction from '../lib/routing/RedirectInstruction';

import { trustedHeader } from '../lib/trusted-headers';
import { selectAccessToken, selectSessionToken, selectTrackingToken } from '../selectors/auth';
import {
  selectAuthApiBase,
  selectClientIP,
  selectDeviceId,
  selectWebappClientVersion,
} from '../selectors/client';

const MATCH_PARAM = /\{(.+?)\}/g;

let API_BASE;
if (__CLIENT__ && typeof window !== 'undefined') {
  API_BASE = window.__api_base__;
} else {
  API_BASE = process.env.API_BASE;
}

export class ApiError extends BaseError {
  constructor(status, message, body, req) {
    super('ApiError: ' + message);

    this.status = status;
    this.body = body;
    this.request = req;
  }
}

const NetworkError = makeError('NetworkError');

const cachedRequestPromises = {};

function formatEndpoint(endpoint, params) {
  const skip = {};

  const path = endpoint.replace(MATCH_PARAM, (_, param) => {
    skip[param] = true;
    return params[param];
  });

  const query = {};
  for (const param in params) {
    if (!skip[param]) {
      query[param] = params[param];
    }
  }

  return { path, query };
}

async function apiRequest(
  request,
  req,
  meta,
  accessToken,
  sessionToken,
  requestType,
  locale,
  clientIp,
  clientUseragent,
  clientVersion,
  deviceId,
  trackingToken,
  base
) {
  const { endpoint, method } = req;
  const { schema } = meta;
  const preNormalize = meta.normalize;
  const jsonTransforms = [];

  const { params, body } = req;

  if (typeof preNormalize === 'function') {
    jsonTransforms.push(preNormalize);
  }

  if (schema) {
    jsonTransforms.push(jsonData => normalize(jsonData, schema));
  }

  const { path, query } = formatEndpoint(endpoint, params);

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Client': __ELECTRON__ ? 'desktop' : 'web',
    'X-Client-Version': clientVersion,
    'X-Device-Class': 'computer',
    'Accept-Language': locale,
  };

  if (__SERVER__) {
    // Forwards the actual client ip and useragent if the request is made on the server
    headers['I-Client-IP'] = trustedHeader('I-Client-IP', clientIp);
    headers['User-Agent'] = clientUseragent;
  }

  if (__ELECTRON__ && requestType === 'AUTH_REQUEST') {
    headers[TRACKING_TOKEN_HEADER_NAME] = trackingToken;
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  headers['X-Session-Token'] = sessionToken;

  if (deviceId) {
    headers['X-Device-ID'] = deviceId;
  } else {
    headers['X-Device-ID'] = 'UNKNOWN';
  }

  const url = `${base}${path}`;

  try {
    const res = await request({
      method,
      url,
      query,
      credentials: 'same-origin',
      headers,
      body,
    });

    const json = await res.json();

    if (!res.ok) {
      // Geoblocking: server-side is handled by middleware, but if the
      // user loads the app, and then goes to the US, then playback won't work;
      //
      // Note: This logic is duplicated in player.js

      if (res.status === 451 || (res.status === 403 && json.errorCode === 45100)) {
        throw new RedirectInstruction('/geoblocked');
      }

      const requestObject = {
        url,
        method,
        queryString: Qs.stringify(query),
        body,
      };
      throw new ApiError(res.status, json.message, json, requestObject);
    }

    if (!json) {
      return { meta: {}, normalized: {} };
    }

    if (json.status === 404) {
      const requestObject = {
        url,
        method,
        queryString: Qs.stringify(query),
        body,
      };
      throw new ApiError(json.status, json.errorCode, json, requestObject);
    }

    return {
      meta: json.meta || {},
      normalized: flow(...jsonTransforms)(json),
    };
  } catch (err) {
    if (err instanceof ApiError || err instanceof RedirectInstruction) {
      throw err;
    }

    throw new NetworkError(err);
  }
}

/**
 * Action key that carries API call info interpreted by this Redux middleware.
 */

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export default requestLib => store => next => async action => {
  if (!action.IDAGIO_REQUEST) {
    return next(action);
  }

  const { IDAGIO_REQUEST: req, meta = {}, cache, type, ...rest } = action;
  if (!req.params) {
    req.params = {};
  }

  if (!req.body) {
    req.body = {};
  }

  const requestId = JSON.stringify(req);

  const { params } = req;
  const state = store.getState();
  const sessionToken = selectSessionToken(state);
  const accessToken = selectAccessToken(state);
  const locale = state.client ? state.client.locale || DEFAULT_LOCALE : DEFAULT_LOCALE;
  const ip = selectClientIP(state);
  const useragent = state.client ? state.client.useragent : '';
  const clientVersion = selectWebappClientVersion(state);
  const deviceId = selectDeviceId(state);
  const trackingToken = selectTrackingToken(state);
  if (meta.restricted && !state.auth.isAuthenticated) {
    return Promise.reject(new RedirectInstruction('/login'));
  }

  if (cache) {
    invariant(typeof cache.fetch === 'function', 'No fetch method given');
    invariant(typeof cache.validate === 'function', 'Cache validator has to be a function');

    const cached = cache.fetch(state);
    const cacheIsValid = !!cache.validate(cached);

    if (cacheIsValid) {
      return Promise.resolve(cached);
    }
  }

  next({ ...rest, type, phase: REQUEST, params });

  try {
    const requestType = req.type;
    const base = requestType === 'API_REQUEST' ? API_BASE : selectAuthApiBase(state);

    cachedRequestPromises[requestId] = apiRequest(
      requestLib,
      req,
      meta,
      accessToken,
      sessionToken,
      req.type,
      locale,
      ip,
      useragent,
      clientVersion,
      deviceId,
      trackingToken,
      base
    );

    const response = await cachedRequestPromises[requestId];

    delete cachedRequestPromises[requestId];

    const actionArgs = {
      ...rest,
      type,
      phase: SUCCESS,
      response,
      params,
    };

    if (meta.requestedAt) {
      actionArgs.requestedAt = meta.requestedAt;
    }

    if (cache && cache.expiryPeriodInMs) {
      actionArgs.expireAt = Date.now() + cache.expiryPeriodInMs;
    }

    next(actionArgs);
    // This is useful for chaining promises and receiving correct arguments
    return response;
  } catch (error) {
    // Make sure the Reducers handle the error state:
    const handled = next({ ...rest, type, phase: FAILURE, error, params });

    delete cachedRequestPromises[requestId];

    if (error instanceof NetworkError) {
      store.dispatch(networkError('api'));
    }

    if (error instanceof ApiError && !handled) {
      if (isSentryInstalled()) {
        Sentry.captureException(error, {
          extra: {
            req,
          },
        });
      }
    }

    throw error;
  }
};
