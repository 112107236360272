// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ConversionStepHeader.css';
import { A_MONTH_IN_DAYS } from '../../constants';
import classNames from 'classnames';

type OwnProps = {
  isStudentStripeCoupon: boolean,
  isTrialAvailable: boolean,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  trialDurationDays: number,
};

const ConversionStepHeader = ({
  isStudentStripeCoupon,
  isTrialAvailable,
  relativeDiscount,
  reducedPriceDuration,
  trialDurationDays,
}: OwnProps) => {
  const Title = () => {
    if (isStudentStripeCoupon) {
      return (
        <span data-test="conversion-step-header.title.student">
          <FormattedMessage
            id="subscribe-modal.payment-step.title-student"
            defaultMessage="IDAGIO for students"
          />
        </span>
      );
    }

    return (
      <span data-test="conversion-step-header.title.default">
        <FormattedMessage
          id="subscribe-modal.payment-step.title-2"
          defaultMessage="Listen without limits"
        />
      </span>
    );
  };

  const Subtitle = () => {
    if (isStudentStripeCoupon && relativeDiscount && reducedPriceDuration) {
      const discountInPercentage = relativeDiscount * 100;
      const months = Math.floor(reducedPriceDuration / A_MONTH_IN_DAYS);
      return (
        <span
          data-test="conversion-step-header.subtitle.student"
          data-test-discount={discountInPercentage}
          data-test-months={months}
        >
          <FormattedMessage
            id="subscribe-modal.payment-step.paragraph-student"
            defaultMessage="Subscribe to IDAGIO for students and save {discountInPercentage}% for {months} months"
            values={{ discountInPercentage, months }}
          />
        </span>
      );
    }

    if (isTrialAvailable) {
      return (
        <span data-test="conversion-step-header.subtitle.trial">
          <FormattedMessage
            id="subscribe-modal.payment-step.paragraph-trial-days"
            defaultMessage="Start your {trialDurationDays}-day free trial to experience unlimited access to everything IDAGIO has to offer"
            values={{ trialDurationDays }}
          />
        </span>
      );
    }

    return (
      <span data-test="conversion-step-header.subtitle.default">
        <FormattedMessage
          id="subscribe-modal.payment-step.paragraph-no-trial"
          defaultMessage="Experience unlimited access to everything IDAGIO has to offer"
        />
      </span>
    );
  };

  return (
    <React.Fragment>
      <h1 className={classNames(styles.title, styles.titleLeft)}>
        <Title />
      </h1>
      <p className={classNames(styles.subtitle, styles.subtitleLeft)}>
        <Subtitle />
      </p>
    </React.Fragment>
  );
};

export default ConversionStepHeader;
