// @flow

import { combineReducers } from 'redux';

import connected from './connected';
import locale from './locale';
import supportedLocales from './supportedLocales';
import notifications from './notifications';
import ui from './ui';
import annotations from './annotations';
import preferences from './preferences';
import recentSearches from './recentSearches';
import ip from './ip';
import fonts from './fonts';
import userAgentInfo from './userAgentInfo';
import urls from './urls';
import features from './features';
import httpRequest from './httpRequest';
import captchas from './captchas';

import type {
  ClientAction,
  SetPageVisibilityAction,
  SetPlaybackIsSupportedAction,
  SetFLACPlaybackIsSupportedAction,
  SetPlaybackUsingAAC,
  SetIsLoadingAudioAction,
  IdentifyUserAgentAction,
  SetEncryptionEnabledAction,
  SetClientVersionsAction,
  SetDeviceIdAction,
  AcceptCookiesAction,
  DisableRecentlyPlayed,
  SetShouldBlockAction,
  SetCountryAction,
  SetCurrencyAction,
  Currency,
  SetIsEmbedAction,
  SetIsInAppAction,
  SetDisableTicketPurchase,
  SetEasyConnectPartnerIdAction,
  SetAdobeApiKeyAction,
  InteractWithSliderAction,
} from '../../actions/client';

import type { State as ConnectedState } from './connected';
import type { State as LocaleState } from './locale';
import type { State as SupportedLocalesState } from './supportedLocales';
import type { State as NotificationsState } from './notifications';
import type { State as UiState } from './ui';
import type { State as AnnotationsState } from './annotations';
import type { State as PreferencesState } from './preferences';
import type { State as RecentSearchesState } from './recentSearches';
import type { State as IpState } from './ip';
import type { State as FontsState } from './fonts';
import type { State as UserAgentInfoState } from './userAgentInfo';
import type { State as UrlsState } from './urls';
import type { State as FeaturesState } from './features';
import type { State as HttpRequestState } from './httpRequest';
import type { State as CaptchasState } from './captchas';

type PageVisibleState = boolean;
type SupportsPlaybackState = boolean;
type SupportsLosslessState = boolean;
type PlaybackUsingAACState = boolean;
type IsLoadingAudioState = boolean;
type UseragentState = string;
type EncryptedPlaybackState = boolean;
type VersionsState = {
  +electron: ?string,
  +web: ?string,
};
type DeviceIdState = ?string;
type AcceptedCookiesState = boolean;
type RecentlyPlayedEnabledState = boolean;
type CampaignFromIdState = string;
type PromotionNameState = string;
type ShouldBlockState = boolean;
type CountryState = string;
type CurrencyState = Currency;

// TODO it should be possible to combine the declaration of the State type and the object passed to combineReducers using $ObjMap
export type State = {|
  +pageVisible: PageVisibleState,
  +notifications: NotificationsState,
  +connected: ConnectedState,
  +locale: LocaleState,
  +supportedLocales: SupportedLocalesState,
  +ui: UiState,
  +annotations: AnnotationsState,
  +preferences: PreferencesState,
  +supportsPlayback: SupportsPlaybackState,
  +supportsLossless: SupportsLosslessState,
  +playbackUsingAAC: PlaybackUsingAACState,
  +encryptedPlayback: EncryptedPlaybackState,
  +isLoadingAudio: IsLoadingAudioState,
  +recentSearches: RecentSearchesState,
  +ip: IpState,
  +useragent: UseragentState,
  +versions: VersionsState,
  +deviceId: DeviceIdState,
  +acceptedCookies: AcceptedCookiesState,
  +recentlyPlayedEnabled: RecentlyPlayedEnabledState,
  +campaignFromId: CampaignFromIdState,
  +promotionName: PromotionNameState,
  +userAgentInfo: UserAgentInfoState,
  +shouldBlock: ShouldBlockState,
  +country: CountryState,
  +currency: CurrencyState,
  +urls: UrlsState,
  +fonts: FontsState,
  +features: FeaturesState,
  +isEmbed: Boolean,
  +httpRequest: HttpRequestState,
  +isInApp: Boolean,
  +easyConnectPartnerId: number,
  +adobeApiKey: string,
  +hasInteractedWithSlider: Boolean,
  +captchas: CaptchasState,
|};

function pageVisible(state: PageVisibleState = true, action: SetPageVisibilityAction) {
  if (action.type === 'SET_PAGE_VISIBILITY') {
    return action.visible;
  }

  return state;
}

function supportsPlayback(
  state: SupportsPlaybackState = false,
  action: SetPlaybackIsSupportedAction
) {
  if (action.type === 'SET_PLAYBACK_IS_SUPPORTED') {
    return true;
  }

  return state;
}

function supportsLossless(
  state: SupportsLosslessState = false,
  action: SetFLACPlaybackIsSupportedAction
) {
  if (action.type === 'SET_FLAC_PLAYBACK_IS_SUPPORTED') {
    return true;
  }

  return state;
}

function playbackUsingAAC(state: PlaybackUsingAACState = false, action: SetPlaybackUsingAAC) {
  if (action.type === 'SET_PLAYBACK_USING_AAC') {
    return true;
  }

  return state;
}

function isLoadingAudio(state: IsLoadingAudioState = false, action: SetIsLoadingAudioAction) {
  if (action.type === 'SET_IS_LOADING_AUDIO') {
    return action.flag;
  }

  return state;
}

function useragent(state: UseragentState = '', action: IdentifyUserAgentAction) {
  if (action.type === 'IDENTIFY_USER_AGENT') {
    return action.userAgentInfo.source;
  }
  return state;
}

function encryptedPlayback(
  state: EncryptedPlaybackState = false,
  action: SetEncryptionEnabledAction
) {
  if (action.type === 'SET_ENCRYPTION_ENABLED') {
    return true;
  }

  return state;
}

function versions(
  state: VersionsState = { electron: null, web: null },
  action: SetClientVersionsAction
) {
  if (action.type === 'SET_CLIENT_VERSIONS') {
    return {
      electron: action.electronVersion,
      web: action.webVersion,
    };
  }

  return state;
}

function deviceId(state: DeviceIdState = null, action: SetDeviceIdAction) {
  if (action.type === 'SET_DEVICE_ID') {
    return action.deviceId;
  }

  return state;
}

function acceptedCookies(state: AcceptedCookiesState = false, action: AcceptCookiesAction) {
  if (action.type === 'ACCEPT_COOKIES') {
    return true;
  }
  return state;
}

function recentlyPlayedEnabled(
  state: RecentlyPlayedEnabledState = true,
  action: DisableRecentlyPlayed
) {
  if (action.type === 'DISABLE_RECENTLY_PLAYED') {
    return false;
  }

  return state;
}

function shouldBlock(state: ShouldBlockState = false, action: SetShouldBlockAction) {
  if (action.type === 'SET_SHOULD_BLOCK') {
    return action.shouldBlock || state;
  }

  return state;
}

function country(state: CountryState = '', action: SetCountryAction) {
  if (action.type === 'SET_COUNTRY') {
    return action.country;
  }

  return state;
}

function currency(state: CurrencyState = 'EUR', action: SetCurrencyAction) {
  if (action.type === 'SET_CURRENCY') {
    return action.currency;
  }

  return state;
}

function isEmbed(state = false, action: SetIsEmbedAction) {
  if (action.type === 'SET_IS_EMBED') {
    return true;
  }
  return state;
}

function isInApp(state: boolean = false, action: SetIsInAppAction) {
  if (action.type === 'SET_IS_IN_APP') {
    return true;
  }
  return state;
}

function ticketPurchaseIsDisabled(state: boolean = false, action: SetDisableTicketPurchase) {
  if (action.type === 'SET_DISABLE_TICKET_PURCHASE') {
    return true;
  }
  return state;
}

function hasInteractedWithSlider(state: boolean = false, action: InteractWithSliderAction) {
  if (action.type === 'INTERACT_WITH_SLIDER') {
    return true;
  }
  return state;
}

export function easyConnectPartnerId(state: number = 5, action: SetEasyConnectPartnerIdAction) {
  if (action.type === 'SET_EASY_CONNECT_PARTNER_ID') {
    return action.partnerId;
  }
  return state;
}

export function adobeApiKey(state: string = '', action: SetAdobeApiKeyAction) {
  if (action.type === 'SET_ADOBE_API_KEY') {
    return action.key;
  }
  return state;
}

const combinedReducer: (state: State, action: ClientAction) => State = combineReducers({
  pageVisible,
  notifications,
  connected,
  locale,
  supportedLocales,
  ui,
  annotations,
  preferences,
  supportsPlayback,
  supportsLossless,
  playbackUsingAAC,
  encryptedPlayback,
  isLoadingAudio,
  recentSearches,
  ip,
  useragent,
  versions,
  deviceId,
  acceptedCookies,
  recentlyPlayedEnabled,
  userAgentInfo,
  shouldBlock,
  country,
  currency,
  urls,
  fonts,
  features,
  isEmbed,
  httpRequest,
  isInApp,
  ticketPurchaseIsDisabled,
  easyConnectPartnerId,
  adobeApiKey,
  hasInteractedWithSlider,
  captchas,
});

export default combinedReducer;
