import Cookies from 'js-cookie';

export default () => next => action => {
  next(action);
  if (action.type === 'ACCEPT_COOKIES') {
    Cookies.set('accepted-cookies', true, {
      secure: process.env.ENV === 'production' && !process.env.NO_HTTPS,
      expires: 365 * 100,
    });
  }
};
