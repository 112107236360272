// @flow
import React from 'react';
import CapacitorHeaderBar from '../capacitor/HeaderBar';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import CollectionLivestreamEvents from '../messages/CollectionLivestreamEvents';

import styles from '../../views/Collection.css';
import {
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_ARTIST,
  ENTITY_TYPE_LIVESTREAM_EVENT,
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_RECORDING,
  ENTITY_TYPE_TRACK,
} from '../../constants';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  entityType: string,
};

type Props = OwnProps & { intl: IntlShape };
const heartIcon = (
  <svg className={styles.collectionEmptyIcon}>
    <use xlinkHref="#icon-heart" />
  </svg>
);

const messages = defineMessages({
  [ENTITY_TYPE_ALBUM]: {
    id: 'empty-collection.album',
    defaultMessage: 'an album',
  },
  [ENTITY_TYPE_TRACK]: {
    id: 'empty-collection.track',
    defaultMessage: 'a track',
  },
  [ENTITY_TYPE_RECORDING]: {
    id: 'empty-collection.recording',
    defaultMessage: 'a recording',
  },
  [ENTITY_TYPE_PLAYLIST]: {
    id: 'empty-collection.playlist',
    defaultMessage: 'a playlist',
  },
  [ENTITY_TYPE_ARTIST]: {
    id: 'empty-collection.artist',
    defaultMessage: 'an artist',
  },
  [ENTITY_TYPE_LIVESTREAM_EVENT]: {
    id: 'empty-collection.livestream-event',
    defaultMessage: 'an event',
  },
});

const capacitorHeaderMessages = {
  livestreamEvent: <CollectionLivestreamEvents />,
};

const EmptyCollection = ({ entityType, intl }: Props) => {
  return (
    <div data-test="empty-collection">
      {__CAPACITOR__ && (
        <CapacitorHeaderBar withBackButton title={capacitorHeaderMessages[entityType]} />
      )}
      <div className="fz--beta">
        <FormattedMessage
          id="collection.empty.heading"
          defaultMessage="Curate your own classical collection"
        />
      </div>
      <p>
        <FormattedMessage
          id="collection.empty.intro"
          defaultMessage="When you find {resourceName} you like, just tap the {heartIcon} to save it here."
          values={{ heartIcon, resourceName: intl.formatMessage(messages[entityType]) }}
        />
      </p>
    </div>
  );
};

export default injectIntl(EmptyCollection);
