// @flow
import React from 'react';
import classnames from 'classnames';

import PlanPrice from './PlanPrice';
import styles from './PlanOptionUpgrade.css';

type OwnProps = {
  id: string,
  monthlyPrice: number,
  totalPrice?: number,
  onSelect: Function,
  shouldShowPriceRemark?: boolean,
  badgeText?: string,
  className?: string,
};

type Props = OwnProps;

const PlanOptionUpgrade = ({
  monthlyPrice,
  totalPrice,
  onSelect,
  id,
  shouldShowPriceRemark,
  badgeText,
  className,
}: Props) => {
  const containerClassname = classnames(styles.container, className);

  return (
    <div className={containerClassname}>
      <button
        onClick={() => onSelect(id)}
        className="dummy-btn"
        data-test="plan-option.upgrade.btn"
      />
      {badgeText && <span className={styles.badge}>{badgeText}</span>}
      <PlanPrice
        monthlyPrice={monthlyPrice}
        totalPrice={totalPrice}
        shouldShowPriceRemark={shouldShowPriceRemark}
      />
    </div>
  );
};

export default PlanOptionUpgrade;
