// @flow
// $FlowFixMe
import React, { useRef, useEffect, useState, forwardRef } from 'react';
import classnames from 'classnames';
import IconLabel from './../util/IconLabel';

import styles from './SearchBox.css';
import { MIN_SEARCH_QUERY_LENGTH } from '../../constants';
import * as KEYS from '../../lib/key-codes';

type OwnProps = {
  query: string,

  onChange: Function,
  onFocus?: Function,
  onSubmit?: Function,
  onBlur?: Function,

  isFullscreen?: boolean,
  hasShadow?: boolean,
  titleSearch: string,
  id: string,
  placeholder?: string,
};

const SearchBox = forwardRef(
  (
    {
      query,
      onChange,
      onFocus,
      onSubmit,
      onBlur,
      titleSearch,
      id,
      placeholder,
      isFullscreen,
      hasShadow,
    }: OwnProps,
    passedInputRef
  ) => {
    let inputRef = useRef(null);
    if (passedInputRef) {
      inputRef = passedInputRef;
    }
    const isMounted = useRef(false);
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(query);
    }, [query]);

    useEffect(() => {
      isMounted.current = true;
      if (document.activeElement === inputRef.current && onFocus) {
        onFocus();
      }

      return () => {
        isMounted.current = false;
      };
    }, [inputRef, onFocus]);

    const onSubmitSearch = event => {
      event.preventDefault();
      if (query.length >= MIN_SEARCH_QUERY_LENGTH) {
        inputRef.current.blur();

        if (onSubmit) {
          onSubmit(query);
        }
      }
    };

    const onChangeSearch = event => {
      const newValue = event.target.value;
      setValue(newValue);
      onChange(newValue);
    };

    const onBlurSearch = () => {
      if (onBlur) {
        onBlur();
      }
    };

    const onKeyDown = event => {
      if (event.keyCode === KEYS.ESC) {
        setValue('');
        onChange('');
      }
    };

    const name = isMounted.current ? null : id; // to avoid pesky autocomplete

    return (
      <form
        className={classnames(styles.form, {
          [styles.hasShadow]: hasShadow,
        })}
        onSubmit={onSubmitSearch}
        role="search"
      >
        <label htmlFor={id}>
          <IconLabel name="search" title={titleSearch} className={styles.icon} />
        </label>
        <input
          className={classnames(styles.input, {
            [styles.fullscreen]: isFullscreen,
          })}
          type="text"
          ref={inputRef}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChangeSearch}
          onKeyDown={onKeyDown}
          onBlur={onBlurSearch}
          onFocus={onFocus}
          autoComplete="off"
        />
        <button type="submit" className={styles.submitButton} />
      </form>
    );
  }
);

export default SearchBox;
