// @flow
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

import { selectCurrency } from '../../selectors/client';

import { SUBSCRIPTION_PRICE_EURO } from '../../constants';

type OwnProps = {
  price?: number,
  relativeDiscount?: number,
  className?: string,
};

type MapStateToProps = {
  currency: string,
};

type Props = OwnProps & MapStateToProps;

const Price = ({
  currency,
  price = SUBSCRIPTION_PRICE_EURO,
  relativeDiscount = 0,
  className,
}: Props) => {
  const value = Math.floor((1 - relativeDiscount) * price * 100) / 100; // truncate to two decimal places without rounding
  return (
    <span className={className} data-test="price" data-test-value={value}>
      <FormattedNumber currency={currency} style="currency" value={value} />
    </span>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    currency: selectCurrency(state),
  };
}

export default connect(mapStateToProps)(Price);
