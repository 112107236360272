import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as uiActions from '../actions/ui';
import { selectBaseUrl } from '../selectors/client';

import { snakeCase } from 'lodash';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export const shareableEntityPropTypes = {
  showModal: PropTypes.func,
  baseUrl: PropTypes.string,
  entityTypeString: PropTypes.string,
};

export default function shareableEntity(
  getSharedUrl,
  getShareTrackingContext,
  entityType,
  options = {}
) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class ShareableEntity extends Component {
      static propTypes = shareableEntityPropTypes;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            ref={withRef ? 'wrappedInstance' : null}
            showShareModal={this.showShareModal}
          />
        );
      }

      filterUtmParams = params => {
        const utmParams = {};
        if (params) {
          Object.keys(params).map(key => {
            if (key.startsWith('utm')) {
              utmParams[key] = params[key];
            }
          });
        }
        return utmParams;
      };

      showShareModal = () => {
        const { entityTypeString } = this.props;
        const sharedUrlParams = this.filterUtmParams(options.sharedUrlParams);
        const sharedPath = getSharedUrl(this.props);
        const sharedUrl = sharedPath ? this.props.baseUrl + sharedPath : null;
        let embedCode;

        const entity = this.props.album || this.props.playlist || this.props.collection;

        if (entity) {
          const embedId = `${snakeCase(entityTypeString)}_id=${entity.id}`;
          const title = this.props.title || entity.title;
          const embedTitle = `${snakeCase(entityTypeString)}_title=${encodeURIComponent(title)}`;

          embedCode = `<iframe
             src='${this.props.baseUrl}/player?${embedId}&${embedTitle}'
             width="500"
             height="400"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>`
            .replace(/(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/gm, '$1$3')
            .split('\n')
            .join(' ');
        }

        this.props.showModal('SHARE_MODAL', getShareTrackingContext(this.props), {
          sharedUrl,
          sharedUrlParams,
          embedCode,
          entityType: this.props.entityTypeString,
        });
      };
    }

    ShareableEntity.displayName = `ShareableEntity(${getDisplayName(WrappedComponent)}`;

    ShareableEntity.WrappedComponent = WrappedComponent;

    function mapStateToProps(state, ownProps) {
      const entityTypeIsFunction = typeof entityType === 'function';
      const entityTypeString = entityTypeIsFunction ? entityType(ownProps) : entityType;
      return {
        baseUrl: selectBaseUrl(state),
        entityTypeString,
      };
    }

    return connect(mapStateToProps, {
      showModal: uiActions.showModal,
    })(ShareableEntity);
  };
}
