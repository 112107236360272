export default function getSegmentWriteKey() {
  if (__CLIENT__ && typeof window !== 'undefined') {
    // && window because this runs in mocha also
    return window.__segment_key__;
  }

  if (__ELECTRON__) {
    return process.env.DESKTOP_SEGMENT_KEY;
  }

  return process.env.WEB_SEGMENT_KEY;
}
