import { replace, push, goBack } from 'react-router-redux';
import {
  NavigationPlugin,
  StatePlugin,
  PlayerPlugin,
  EventChannelPlugin,
  MiscActionsPlugin,
  TrackingPlugin,
  DownloadPlugin,
} from '../capacitor-connector';
import {
  selectPlayerIsPlaying,
  selectPlayerLoadingQueueOrigin,
  selectPlayerQueueOrigin,
} from '../selectors/player';
import { selectModalOpen, selectOpenModalTypeIs } from '../selectors/modals';
import { selectEntityDownloadInfo } from '../selectors/download';
import { selectAlbumById } from '../selectors/album';
import { selectUserIsPatron } from '../selectors/user';
import { selectMix } from '../selectors/mix';

import { connected, disconnected } from '../actions/connection';
import { loadCollectionIds } from '../actions/collection';
import { reloadPersonalPlaylists } from '../actions/personalPlaylist';
import { selectPersonalPlaylistToAddState } from '../selectors/client';
import { hideModal, showAddToPlaylistModal } from '../actions/ui';
import {
  pause,
  play,
  setQueue,
  setShuffledQueue,
  createItem,
  setCurrentQueueItem,
} from '../actions/player';
import { queueIsLoaded } from '../actions/queue';
import getRouteFromCapacitorLocation from '../utils/getRouteFromCapacitorLocation';
import { addNotification } from '../actions/notifications';
import {
  ADD_TO_COLLECTION_ALBUM,
  ADD_TO_COLLECTION_RECORDING,
  ADD_TO_COLLECTION_ARTIST,
  ADD_TO_COLLECTION_PLAYLIST,
  ADD_TO_COLLECTION_TRACK,
  REMOVE_FROM_COLLECTION_ALBUM,
  REMOVE_FROM_COLLECTION_RECORDING,
  REMOVE_FROM_COLLECTION_ARTIST,
  REMOVE_FROM_COLLECTION_PLAYLIST,
  REMOVE_FROM_COLLECTION_TRACK,
  OFFLINE,
} from '../lib/notifications';
import {
  QUEUE_TYPE_PLAYLIST,
  QUEUE_TYPE_PERSONAL_PLAYLIST,
  QUEUE_TYPE_MIX,
  QUEUE_TYPE_RECORDING,
  QUEUE_TYPE_ALBUM,
  QUEUE_TYPE_MOOD,
  QUEUE_TYPE_RADIO,
  PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER,
} from '../constants';

if (__CLIENT__) {
  window.NavigationPlugin = NavigationPlugin;
  window.StatePlugin = StatePlugin;
  window.PlayerPlugin = PlayerPlugin;
  window.EventChannelPlugin = EventChannelPlugin;
  window.MiscActionsPlugin = MiscActionsPlugin;
  window.TrackingPlugin = TrackingPlugin;
}

const PLAYBACK_SOURCE_CAPACITOR = 'PLAYBACK_SOURCE_CAPACITOR';
const queueTypeToCapacitorEntityType = {
  [QUEUE_TYPE_RECORDING]: 'recording',
  [QUEUE_TYPE_ALBUM]: 'album',
  [QUEUE_TYPE_PLAYLIST]: 'playlist',
  [QUEUE_TYPE_PERSONAL_PLAYLIST]: 'personal_playlist',
  [QUEUE_TYPE_MOOD]: 'mood',
  [QUEUE_TYPE_MIX]: 'mix',
  [QUEUE_TYPE_RADIO]: 'radio',
};

const TRACK_EVENT_BLOCK_LIST = ['Application Opened', 'Became Background', 'Became Foreground'];
const TRACK_SYNTHETIC_EVENT_WHITE_LIST = [
  'Discover',
  'BrowseComposers',
  'DiscoverGroupList',
  'Moods',
  'Collection',
  'Profile',
];

const TAB_MAIN_PAGE_PATHS = ['/discover', '/browse/composers', '/live', '/moods', '/collection'];

// When android navigates away from capacitor (e.g. to the collection screen),
// after coming back to capacitor we want to 'wipe' the history stack. Since
// this is not possible with the history library, we can instead keep track of
// the history stack manually, and block going back to the /blank screen, which
// android always navigates to when opening a native screen.
const internalHistoryStack = [];

const removeDownloadIfDownloaded = (action, store) => {
  const state = store.getState();
  for (const id of action.ids) {
    const downloadInfo = selectEntityDownloadInfo(state, id);
    if (downloadInfo && ['downloaded', 'download_running'].includes(downloadInfo.downloadStatus)) {
      DownloadPlugin.removeDownloadForSubscribedEntity();
    }
  }
};

export default store => {
  const listeners = [];

  // Overwrite tracking
  window.analytics = {
    track: (event, properties) => {
      if (TRACK_EVENT_BLOCK_LIST.includes(event)) {
        // do not track
        return;
      }
      const payload = { event, properties };
      TrackingPlugin.androidShouldTrackEvent(payload);
    },
    page: (screen, properties) => {
      const screenPayload = { screen, properties };
      TrackingPlugin.androidShouldTrackScreen(screenPayload);

      // Certain screen events need to reach Amplitude, so track them as synthetic event
      if (TRACK_SYNTHETIC_EVENT_WHITE_LIST.includes(screen)) {
        const syntheticEventPayload = { event: `Tracked ${screen} Screen`, properties };
        TrackingPlugin.androidShouldTrackEvent(syntheticEventPayload);
      }
    },
    identify: (...args) => {
      // eslint-disable-next-line no-console
      console.warn('analytics.identify is not implemented in TrackingPlugin', args);
    },
    alias: (...args) => {
      // eslint-disable-next-line no-console
      console.warn('analytics.alias is not implemented in TrackingPlugin', args);
    },
  };

  // Register navigation
  listeners.push(
    NavigationPlugin.addListener('webAppShouldNavigateToLocation', location => {
      const route = getRouteFromCapacitorLocation(location);
      if (route) {
        if (route !== '/blank' && selectModalOpen(store.getState())) {
          store.dispatch(hideModal());
        }
        if (route.indexOf('#') !== -1) {
          // Obscure problem: when the URL contains a hash, pushing it doesn't do
          // anything, no matter what the current location is. In this case we
          // need to replace it.
          store.dispatch(replace(route));
        } else {
          store.dispatch(push(route));
        }
      }
    })
  );

  listeners.push(
    NavigationPlugin.addListener('webAppShouldOpenAddToPlaylistModal', ({ trackIds, origin }) => {
      store.dispatch(
        showAddToPlaylistModal(
          trackIds,
          undefined,
          origin === 'maxiPlayer' ? PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER : null
        )
      );
    })
  );

  listeners.push(
    PlayerPlugin.addListener('androidSendsPlaybackInfo', data => {
      const loadingOrigin = selectPlayerLoadingQueueOrigin(store.getState());
      if (loadingOrigin) {
        // while loading don't affect the queue
        return;
      }
      if (data.isBuffering || data.isConnecting) {
        // Ignore these events because they may trigger the below code unintentionally
        return;
      }

      const isPlaying = selectPlayerIsPlaying(store.getState());
      if (!data.isPlaying && isPlaying) {
        store.dispatch(pause(PLAYBACK_SOURCE_CAPACITOR));
      }
      if (data.isPlaying && !isPlaying) {
        store.dispatch(play(PLAYBACK_SOURCE_CAPACITOR));
      }
    })
  );

  listeners.push(
    PlayerPlugin.addListener('androidSendsCurrentPlayedTrack', trackMetadata => {
      const { trackId, recordingId, playlistId, playbackStartedFrom, playbackIndex } =
        trackMetadata;
      let origin;
      let index = 0;
      if (['RECORDING', 'COLLECTION_RECORDINGS'].includes(playbackStartedFrom)) {
        if (playbackStartedFrom === 'RECORDING') {
          const recording = store.getState().entities.recordings[playlistId];
          if (!recording) {
            return;
          }
          index = playbackIndex;
        }
        // For COLLECTION_RECORDINGS, we don't have the recording in the cache
        // yet, so we can't find an index. At least we can display the whole
        // recording as playing.
        origin = { type: QUEUE_TYPE_RECORDING, id: recordingId };
      } else if (['ALBUM', 'ALBUM_RADIO'].includes(playbackStartedFrom)) {
        // When starting an ALBUM_RADIO, we treat it the same as starting an
        // ALBUM: We want the play button to show up as playing (but change the
        // text to say 'Radio' in the UI)
        const album = selectAlbumById(store.getState(), playlistId);
        if (!album) {
          return;
        }
        index = playbackIndex;
        origin = { type: QUEUE_TYPE_ALBUM, id: album.slug };
      } else if (playbackStartedFrom === 'PERSONAL_PLAYLIST') {
        const playlist = store.getState().entities.personalPlaylists[playlistId];
        if (!playlist) {
          return;
        }
        index = playbackIndex;
        origin = { type: QUEUE_TYPE_PERSONAL_PLAYLIST, id: playlistId };
      } else if (['PLAYLIST', 'PLAYLIST_RADIO'].includes(playbackStartedFrom)) {
        const playlist = store.getState().entities.playlists[playlistId];
        if (!playlist) {
          return;
        }
        index = playbackIndex;
        origin = { type: QUEUE_TYPE_PLAYLIST, id: playlist.slug };
      } else if (playbackStartedFrom === 'COMPOSER_RADIO') {
        origin = {
          type: QUEUE_TYPE_RADIO,
          id: `composer--${trackMetadata.playlistId}`,
        };
      } else if (playbackStartedFrom === 'ARTIST_RADIO') {
        origin = {
          type: QUEUE_TYPE_RADIO,
          id: `artist--${trackMetadata.playlistId}`,
        };
      } else if (playbackStartedFrom === 'EPOCH_RADIO') {
        origin = {
          type: QUEUE_TYPE_RADIO,
          id: `epoch--${trackMetadata.playlistId}`,
        };
      } else if (playbackStartedFrom === 'GENRE_RADIO') {
        origin = {
          type: QUEUE_TYPE_RADIO,
          id: `genre--${trackMetadata.playlistId}`,
        };
      } else if (playbackStartedFrom === 'INSTRUMENT_RADIO') {
        origin = {
          type: QUEUE_TYPE_RADIO,
          id: `instrument--${trackMetadata.playlistId}`,
        };
      } else if (playbackStartedFrom === 'MIX') {
        const mix = selectMix(store.getState(), trackMetadata.playlistId);
        if (!mix) {
          return;
        }
        index = playbackIndex;
        origin = {
          type: QUEUE_TYPE_MIX,
          id: trackMetadata.playlistId,
        };
      }

      // We need to set the queue origin in order to be able to tell that a
      // radio/album/playlist/etc is playing. Only the track id would not be
      // enough since it could appear in multiple places.
      store.dispatch(setQueue(origin, []));
      // Instead of setting the queue to whatever we selected, it's easier to
      // just set the one item that android is sending, because we will get the
      // next item anyway as soon as the track is finished. However, we need to
      // set the index in order for albums/playlists/recordings to correctly
      // display the playing item.
      store.dispatch(setCurrentQueueItem(createItem(trackId, index)));
      // Finally we set the queue to be loaded.
      store.dispatch(queueIsLoaded(origin));
    })
  );

  listeners.push(
    PlayerPlugin.addListener('androidSendsCurrentPlayedAd', () => {
      const loadingOrigin = selectPlayerLoadingQueueOrigin(store.getState());
      if (!loadingOrigin) {
        return;
      }

      store.dispatch(setShuffledQueue(loadingOrigin, []));
      store.dispatch(queueIsLoaded(loadingOrigin));
      store.dispatch(play(PLAYBACK_SOURCE_CAPACITOR));
    })
  );

  function shouldPerformGoBackOnWeb() {
    const lastStackEntry = internalHistoryStack[internalHistoryStack.length - 2];
    return (
      window.GlobalMemoryHistory.canGo(-1) &&
      internalHistoryStack.length > 1 &&
      lastStackEntry !== '/blank'
    );
  }

  listeners.push(
    StatePlugin.addListener('webAppShouldRefetchCollection', () => {
      store.dispatch(loadCollectionIds());
      store.dispatch(reloadPersonalPlaylists());
    })
  );

  // Back presses handling:
  // If we are on a tab-MAIN-page (discover, browse, gch,..) we send the back
  // press back (Android will navigate to Discover)
  // If we are on any other page, we go back in history if we can.
  // However, one special case: when the item we would go back to is /blank we
  // send the back press back
  listeners.push(
    EventChannelPlugin.addListener('androidSendsBackPressed', () => {
      const currentPath = store.getState().routing.locationBeforeTransitions.pathname;

      if (TAB_MAIN_PAGE_PATHS.includes(currentPath)) {
        EventChannelPlugin.webAppSendsBackPressNotHandled({ currentPath });
        return;
      }

      if (shouldPerformGoBackOnWeb()) {
        internalHistoryStack.pop();
        store.dispatch(goBack());
      } else {
        EventChannelPlugin.webAppSendsBackPressNotHandled({ currentPath });
      }
    })
  );

  listeners.push(
    StatePlugin.addListener('androidSendsInvalidateData', () => {
      store.dispatch(loadCollectionIds());
    })
  );

  listeners.push(
    StatePlugin.addListener('androidSendsConnectionState', ({ isConnected }) => {
      if (!isConnected) {
        store.dispatch(addNotification(OFFLINE));
        store.dispatch(disconnected());
      } else {
        store.dispatch(connected());
      }
    })
  );

  // Register custom navigation
  window.GlobalMemoryHistory.addListener('unhandledPush', ({ target }) => {
    const pathname = target;

    if (pathname === '/subscribe') {
      NavigationPlugin.androidShouldOpenSubscriptionPage();
      return;
    }

    if (pathname === '/account') {
      NavigationPlugin.androidShouldOpenUpgradeExistingSubscriptionPage();
      return;
    }

    if (pathname === '/collection/tracks') {
      NavigationPlugin.androidShouldOpenCollection({ subpage: 'tracks' });
      return;
    }

    if (pathname === '/collection/albums') {
      NavigationPlugin.androidShouldOpenCollection({ subpage: 'albums' });
      return;
    }

    if (['/collection/playlists', '/collection/playlists/personal'].includes(pathname)) {
      NavigationPlugin.androidShouldOpenCollection({ subpage: 'playlists' });
      return;
    }

    if (pathname === '/recently-played') {
      NavigationPlugin.androidShouldOpenCollection();
      return;
    }
  });

  window.addEventListener('beforeunload', () => {
    // Necessary in dev mode, otherwise listeners will persist over reloads and
    // be called multiple times
    if (process.env.ENV === 'development') {
      listeners.forEach(listener => listener.remove());
    }
  });

  return next => async action => {
    const { type } = action;

    if (type === 'SHOW_MODAL') {
      if (action.modalType === 'SHARE_MODAL') {
        MiscActionsPlugin.androidShouldShareUrl({
          // TODO do we need a content type here?
          url: action.data.sharedUrl,
        });
        return;
      } else if (action.modalType === 'SUBSCRIBE_MODAL') {
        NavigationPlugin.androidShouldOpenSubscriptionPage();
        return;
      }
    }

    if (type === 'HIDE_MODAL') {
      const state = store.getState();
      const location = state.routing.locationBeforeTransitions;
      if (
        selectOpenModalTypeIs(state, 'ADD_TO_PLAYLIST_MODAL') &&
        selectPersonalPlaylistToAddState(state).origin === PERSONAL_PLAYLIST_MODAL_ORIGIN_MAXIPLAYER
      ) {
        NavigationPlugin.androidShouldOpenMaxiPlayer();
      }
      // Edge case: if the add-to-playlist-modal was opened from a native
      // android view, say the collection, the modal will be opened on top of
      // the /blank page. When the modal is closed, we would only see a blank
      // page. The best thing to do is let android decide what to do, as if we
      // pressed back.
      if (location.pathname === '/blank') {
        EventChannelPlugin.webAppSendsBackPressNotHandled({
          currentPath: location.pathname,
        });
      }
    }

    if (type === 'SET_PAGE_VISIBILITY' && action.visible) {
      TrackingPlugin.androidShouldTrackLatestScreenAgain();
    }

    if (type === 'ADD_ARTIST_TO_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(ADD_TO_COLLECTION_ARTIST));
    }

    if (type === 'ADD_PLAYLIST_TO_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(ADD_TO_COLLECTION_PLAYLIST));
    }

    if (type === 'ADD_ALBUM_TO_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(ADD_TO_COLLECTION_ALBUM));
    }

    if (type === 'ADD_RECORDING_TO_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(ADD_TO_COLLECTION_RECORDING));
    }

    if (type === 'ADD_TRACK_TO_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(ADD_TO_COLLECTION_TRACK));
    }

    if (type === 'REMOVE_ARTIST_FROM_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(REMOVE_FROM_COLLECTION_ARTIST));
    }

    if (type === 'REMOVE_PLAYLIST_FROM_COLLECTION' && action.phase === 'SUCCESS') {
      removeDownloadIfDownloaded(action, store);
      store.dispatch(addNotification(REMOVE_FROM_COLLECTION_PLAYLIST));
    }

    if (type === 'REMOVE_ALBUM_FROM_COLLECTION' && action.phase === 'SUCCESS') {
      removeDownloadIfDownloaded(action, store);
      store.dispatch(addNotification(REMOVE_FROM_COLLECTION_ALBUM));
    }

    if (type === 'REMOVE_RECORDING_FROM_COLLECTION' && action.phase === 'SUCCESS') {
      removeDownloadIfDownloaded(action, store);
      store.dispatch(addNotification(REMOVE_FROM_COLLECTION_RECORDING));
    }

    if (type === 'REMOVE_TRACK_FROM_COLLECTION' && action.phase === 'SUCCESS') {
      store.dispatch(addNotification(REMOVE_FROM_COLLECTION_TRACK));
    }

    if (type === 'SET_START_ROUTE') {
      internalHistoryStack.push(action.route);
    }

    if (type === '@@router/LOCATION_CHANGE') {
      NavigationPlugin.webAppSendsCurrentLocation({
        currentLocation: action.payload.pathname,
      });
      if (action.payload.action === 'PUSH') {
        internalHistoryStack.push(action.payload.pathname);
      }
    }

    if (type === 'PLAYER_PAUSE') {
      if (action.source !== PLAYBACK_SOURCE_CAPACITOR) {
        // ^ to prevent a loop
        PlayerPlugin.androidShouldStopPlayback({ reason: 'PAUSED' });
      }
    }

    if (type === 'CAPACITOR_SET_QUEUE_AND_PLAY') {
      const origin = action.queueOrigin;
      if (queueTypeToCapacitorEntityType[origin.type]) {
        const payload = {
          entityType: queueTypeToCapacitorEntityType[origin.type],
          entityIdOrSlug: origin.id.toString(),
        };
        if (payload.entityType === 'radio') {
          payload.entityType = action.radioOrigin;
          payload.entityName = action.entityName;
        }
        PlayerPlugin.androidShouldPlay(payload);
      }
    }

    if (type === 'SHOW_UPSELL_VIEW') {
      MiscActionsPlugin.androidShouldShowUpsellView({
        message: action.messageId,
      });
    }

    if (type === 'PLAYER_RESUME' || type === 'PLAYER_PLAY') {
      if (action.source !== PLAYBACK_SOURCE_CAPACITOR) {
        const origin = selectPlayerQueueOrigin(store.getState());
        if (queueTypeToCapacitorEntityType[origin.type]) {
          const entityType = queueTypeToCapacitorEntityType[origin.type];
          const payload = {
            entityType,
            entityIdOrSlug: origin.id.toString(),
          };
          // For free accounts, we only allow playing radio
          if (entityType === 'album' && !selectUserIsPatron(store.getState())) {
            payload.entityType = 'albumRadio';
          } else if (entityType === 'playlist' && !selectUserIsPatron(store.getState())) {
            payload.entityType = 'playlistRadio';
          } else if (action.queueItem && 'index' in action.queueItem) {
            // This is only allowed for albums and playlists, we need to ignore
            // it for free accounts
            payload.startIndex = action.queueItem.index;
          }
          if (type === 'PLAYER_RESUME') {
            PlayerPlugin.androidShouldResume(payload);
          } else {
            PlayerPlugin.androidShouldPlay(payload);
          }
        }
      }
    }

    if (type === 'PLAYER_QUEUE_SET') {
      const isPatron = selectUserIsPatron(store.getState());
      if (!isPatron) {
        const entityType = queueTypeToCapacitorEntityType[action.origin.type];
        if (entityType === 'album' || entityType === 'playlist') {
          // If the user is not premium (= they can only play radio), we need
          // to make sure not to set the tracks into the queue so the first
          // track isn't displayed as playing while we wait for the radio to
          // actually start. We still want to set the queue origin, so the play
          // button shows as playing.
          next({
            ...action,
            tracks: [],
            originalTracks: [],
          });
          return;
        }
      }
    }

    if (type === 'CREATE_PERSONAL_PLAYLIST' && action.phase === 'SUCCESS') {
      StatePlugin.webAppSendsPlaylistCreated({
        entityType: 'personal_playlist',
        entityIdOrSlug: action.response.normalized.result.toString(),
      });
    }

    if (type === 'DELETE_PERSONAL_PLAYLIST' && action.phase === 'SUCCESS') {
      StatePlugin.webAppSendsPlaylistRemoved({
        entityType: 'personal_playlist',
        entityIdOrSlug: action.params.id.toString(),
      });
    }

    if (type === 'MODIFY_PERSONAL_PLAYLIST' && action.phase === 'SUCCESS') {
      StatePlugin.webAppSendsPlaylistChanged({
        entityType: 'personal_playlist',
        entityIdOrSlug: action.response.normalized.result.toString(),
      });
    }

    const SUPPORTED_COLLECTION_ADD_ACTIONS = [
      'ADD_TRACK_TO_COLLECTION',
      'ADD_RECORDING_TO_COLLECTION',
      'ADD_ALBUM_TO_COLLECTION',
      'ADD_PLAYLIST_TO_COLLECTION',
    ];

    if (SUPPORTED_COLLECTION_ADD_ACTIONS.includes(type) && action.phase === 'SUCCESS') {
      StatePlugin.webAppSendsEntityAddedToCollection({
        entityType: action.collectionType,
        entityIdOrSlug: action.ids.length > 0 ? action.ids[0].toString() : undefined,
        contextEntityType: action.capacitorContextEntityType,
        contextEntityId: action.capacitorContextEntityId
          ? action.capacitorContextEntityId.toString()
          : undefined,
      });
    }

    const SUPPORTED_COLLECTION_REMOVE_ACTIONS = [
      'REMOVE_TRACK_FROM_COLLECTION',
      'REMOVE_RECORDING_FROM_COLLECTION',
      'REMOVE_ALBUM_FROM_COLLECTION',
      'REMOVE_PLAYLIST_FROM_COLLECTION',
    ];

    if (SUPPORTED_COLLECTION_REMOVE_ACTIONS.includes(type) && action.phase === 'SUCCESS') {
      StatePlugin.webAppSendsEntityRemovedFromCollection({
        entityType: action.collectionType,
        entityIdOrSlug: action.ids.length > 0 ? action.ids[0].toString() : undefined,
        contextEntityType: action.capacitorContextEntityType,
        contextEntityId: action.capacitorContextEntityId
          ? action.capacitorContextEntityId.toString()
          : undefined,
      });
    }

    next(action);
  };
};
