// @flow

// loaders
import { loadPlaylist } from '../actions/playlist';
import { loadMix } from '../actions/mix';
import { loadAlbum } from '../actions/album';
import { loadRecording } from '../actions/recording';
import { loadPersonalPlaylist } from '../actions/personalPlaylist';

// selectors
import {
  selectPlaylist,
  selectPlaylistIsLoading,
  selectPlaylistIsLoaded,
} from '../selectors/playlist';
import { selectMix, selectMixIsLoading, selectMixIsLoaded } from '../selectors/mix';
import { selectAlbum, selectAlbumIsLoading, selectAlbumIsLoaded } from '../selectors/album';
import { selectRecordingTrackIds } from '../selectors/recording';
import {
  selectPersonalPlaylist,
  selectPersonalPlaylistIsLoaded,
  selectPersonalPlaylistIsLoading,
} from '../selectors/personalPlaylist';

// constants
import {
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_MIX,
  ENTITY_TYPE_WORK,
  ENTITY_TYPE_PERSONAL_PLAYLIST,
  ENTITY_TYPE_RECORDING,
  ENTITY_TYPE_LIVESTREAM_EVENT,
  ENTITY_TYPE_MOOD,
} from '../constants';

// types
import type { PlaylistAction } from '../actions/playlist';
import type { MixAction } from '../actions/mix';
import type { AlbumAction } from '../actions/album';
import type { RecordingAction } from '../actions/recording';
import type { PersonalPlaylistAction } from '../actions/personalPlaylist';

export function getEntityUrl(type: string, item: { slug: string }): string {
  switch (type) {
    case ENTITY_TYPE_ALBUM:
      return `/albums/${item.slug}`;
    case ENTITY_TYPE_MIX:
      return `/mixes/${item.slug}`;
    case ENTITY_TYPE_PLAYLIST:
      return `/playlists/${item.slug}`;
    case ENTITY_TYPE_PERSONAL_PLAYLIST:
      return `/playlists/personal/${item.slug}`;
    case ENTITY_TYPE_LIVESTREAM_EVENT:
      return `/live/event/${item.slug}`;
    case 'work':
      return '/';
    default:
      return '/';
  }
}

export type EntityAction =
  | PlaylistAction
  | MixAction
  | AlbumAction
  | RecordingAction
  | PersonalPlaylistAction
  | null;

export function loadEntity(type: string, item: Object): EntityAction {
  if (type === ENTITY_TYPE_PLAYLIST || type === ENTITY_TYPE_MOOD) {
    return loadPlaylist(item.slug);
  }

  if (type === ENTITY_TYPE_MIX) {
    return loadMix(item.slug);
  }

  if (type === ENTITY_TYPE_ALBUM) {
    return loadAlbum(item.slug);
  }

  if (type === ENTITY_TYPE_WORK || type === ENTITY_TYPE_RECORDING) {
    return loadRecording(item.id);
  }

  if (type === ENTITY_TYPE_PERSONAL_PLAYLIST) {
    return loadPersonalPlaylist(item.id);
  }

  return null;
}

export function selectEntityIsLoading(state: Object, type: string, item: Object): boolean {
  if (type === ENTITY_TYPE_PLAYLIST) {
    return selectPlaylistIsLoading(state, item.slug);
  }

  if (type === ENTITY_TYPE_MIX) {
    return selectMixIsLoading(state, item.slug);
  }

  if (type === ENTITY_TYPE_ALBUM) {
    return selectAlbumIsLoading(state, item.slug);
  }

  if (type === ENTITY_TYPE_PERSONAL_PLAYLIST) {
    return selectPersonalPlaylistIsLoading(state, item.id);
  }

  return false;
}

export function selectEntityIsLoaded(state: Object, type: string, item: Object): boolean {
  if (type === ENTITY_TYPE_PLAYLIST || type === ENTITY_TYPE_MOOD) {
    return selectPlaylistIsLoaded(state, item.slug);
  }

  if (type === ENTITY_TYPE_MIX) {
    return selectMixIsLoaded(state, item.slug);
  }

  if (type === ENTITY_TYPE_ALBUM) {
    return selectAlbumIsLoaded(state, item.slug);
  }

  if (type === ENTITY_TYPE_PERSONAL_PLAYLIST) {
    return selectPersonalPlaylistIsLoaded(state, item.id);
  }

  return false;
}

export function selectEntityTrackIds(state: Object, type: string, item: Object): Array<number> {
  if (type === ENTITY_TYPE_PLAYLIST || type === ENTITY_TYPE_MOOD) {
    const playlist = selectPlaylist(state, item.slug);
    return playlist.trackIds;
  }

  if (type === ENTITY_TYPE_MIX) {
    const mix = selectMix(state, item.slug);
    return mix.trackIds;
  }

  if (type === ENTITY_TYPE_ALBUM) {
    const album = selectAlbum(state, item.slug);
    return album.trackIds;
  }

  if (type === ENTITY_TYPE_WORK || type === ENTITY_TYPE_RECORDING) {
    return selectRecordingTrackIds(state, item.id);
  }

  if (type === ENTITY_TYPE_PERSONAL_PLAYLIST) {
    const personalPlaylist = selectPersonalPlaylist(state, item.id);
    return personalPlaylist.trackIds;
  }

  return [];
}
