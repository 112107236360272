// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Price from '../common/Price';

import styles from './PlanPrice.css';

type OwnProps = {
  totalPrice?: number,
  relativeDiscount?: number,
  shouldShowPriceRemark?: boolean,
};

type Props = OwnProps;

const PlanPriceRemark = ({ totalPrice, relativeDiscount, shouldShowPriceRemark }: Props) => {
  if (!shouldShowPriceRemark) {
    return null;
  }

  if (totalPrice) {
    return (
      <React.Fragment>
        {relativeDiscount ? (
          <span data-test="plan-option.yearly-price.reduced">
            <FormattedMessage
              id="subscribe-modal.plans-options.yearly-plan-reduced"
              defaultMessage="({totalPrice} {reducedPrice} billed yearly)"
              values={{
                totalPrice: <Price price={totalPrice} className={styles.strikethrough} />,
                reducedPrice: <Price price={totalPrice} relativeDiscount={relativeDiscount} />,
              }}
            />
          </span>
        ) : (
          <span data-test="plan-option.yearly-price.default">
            <FormattedMessage
              id="subscribe-modal.plans-options.yearly-plan"
              defaultMessage="({totalPrice} billed yearly)"
              values={{
                totalPrice: <Price price={totalPrice} />,
              }}
            />
          </span>
        )}
      </React.Fragment>
    );
  }

  return (
    <FormattedMessage
      id="subscribe-modal.plans-options.billed-monthly"
      defaultMessage="(billed monthly)"
    />
  );
};

export default PlanPriceRemark;
