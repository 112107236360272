// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PlanOptionUpgrade from './PlanOptionUpgrade';

import styles from './PlansOverviewUpgrade.css';

import {
  PLAN_ID_PPC_1M,
  PLAN_ID_PPC_12M,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PRICE_EURO,
} from '../../constants';
import PlanDetails from './PlanDetails';
import { objectivesMessages } from '../messages/PlansOverviewMessages';
import Price from '../common/Price';

type OwnProps = {
  proceedToUpgrade: Function,
};

type Props = OwnProps;

const PlansOverviewUpgrade = ({ proceedToUpgrade }: Props) => {
  const ppc12m = SUBSCRIPTION_PLANS[PLAN_ID_PPC_12M];
  const ppc1m = SUBSCRIPTION_PLANS[PLAN_ID_PPC_1M];

  return (
    <div className={styles.wrapper}>
      <PlanDetails
        objectives={[
          {
            key: 'premiumFeatures',
            value: <FormattedMessage {...objectivesMessages.premiumFeatures} />,
          },
          {
            key: 'concertAccess',
            value: <FormattedMessage {...objectivesMessages.concertAccess} />,
          },
          {
            key: 'programsAccess',
            value: <FormattedMessage {...objectivesMessages.programsAccess} />,
          },
          {
            key: 'newsletter',
            value: <FormattedMessage {...objectivesMessages.newsletter} />,
          },
          {
            key: 'tvOS',
            value: <FormattedMessage {...objectivesMessages.tvOS} />,
          },
          {
            key: 'priceDifference',
            value: (
              <FormattedMessage
                {...objectivesMessages.priceDifference}
                values={{
                  priceDiff: <Price price={ppc12m.monthlyPrice - SUBSCRIPTION_PRICE_EURO} />,
                }}
              />
            ),
          },
        ]}
      />
      <div className={styles.btns}>
        <PlanOptionUpgrade
          id={PLAN_ID_PPC_12M}
          monthlyPrice={ppc12m.monthlyPrice}
          totalPrice={ppc12m.totalPrice}
          onSelect={proceedToUpgrade}
          badgeText="-44%"
          shouldShowPriceRemark
          className="c-btn--is-purple"
        />
        <PlanOptionUpgrade
          id={PLAN_ID_PPC_1M}
          monthlyPrice={ppc1m.monthlyPrice}
          onSelect={proceedToUpgrade}
          shouldShowPriceRemark
          className="c-btn--is-grey"
        />
      </div>
    </div>
  );
};

export default PlansOverviewUpgrade;
